import  *  as Yup from 'yup';

//  =========== Validation for the signup page Screens  ===============//

export const SignupSchema = Yup.object({
    // first_name : Yup.string().min(2).max(15).required('please enter your  first name'),
    // last_name : Yup.string().min(2).max(15).required('please enter your  last name'),
    // phone_number : Yup.number().required('please enter your phone number'),
    // email: Yup.string().email().required('please enter your email'),
    // username : Yup.string().min(2).max(20).required('please enter your username'),
    // password : Yup.string().min(6).required('please enter your password')

    // phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile No. must be 10 digits').required('please enter your phone number'),
    // otp: Yup.string().matches(/^[0-9]{6}$/, 'OTP  must be 4 digits').required('please enter OTP'),
});


// =============  Validation for the Login Screens =============//

export const loginSchema = Yup.object({
    phone: Yup.string()
        .matches(/^\+[0-9]{12}$/, 'Please enter 10 digit mobile no. with country code starting with +')
        .required('please enter your phone number'),
    // otps: Yup.string().matches(/^[0-9]{4}$/, 'OTP must be 4 digits').required('please enter OTP'),
});

//  ============  Validation for the ForgetPassword Screens =====================//

export const forgetPasswordSchema= Yup.object({
    email: Yup.string().email().required('email is required'),
    password: Yup.string().min(6).required('please enter password'),
    confirm_password: Yup.string().oneOf([Yup.ref("password"), null],"Password must match").required("confirm password required")
});