import React from 'react'
import Footer from '../../Include/Footer'
import Header from '../../Include/Header'
import Navbar from '../../Include/Navbar'
import CardTwo from "./CardTwo.json"


const Card2 = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Header />
            <Navbar />
            <div className='font-semibold flex flex-col py-10 gap-3'>
                {/* <div className='mt-10 mx-24'>
                    <h1 className='text-xl font-bold'>Dive into Divine Serenity: Exploring the Essence of Puja with Dev Kirpa
                    </h1>
                </div>
                <div className='flex flex-col gap-3 mx-24'>
                    <p>In a world filled with distractions and constant motion, finding moments of peace and
                        spiritual connection is essential for our well-being. At Dev Kirpa, we understand the
                        importance of nurturing our souls and fostering a deeper connection with the divine. That’s
                        why we’re passionate about bringing the transformative power of puja – the sacred act of
                        worship – directly to you, wherever you may be. Join us on a journey of spiritual discovery as
                        we explore the essence of puja and the unique offerings of Dev Kirpa.</p>

                   

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>Rediscover Tradition: Puja for Every Occasion</h1>
                        <p>In today’s fast-paced world, it’s easy to lose touch with our cultural and spiritual heritage.
                            But at Dev Kirpa, we’re dedicated to preserving and honoring the ancient tradition of puja.
                            Whether you’re celebrating a joyous occasion like a wedding or housewarming, seeking
                            guidance and blessings through a special puja, or simply cultivating daily devotion with
                            Nithya Puja, we’re here to help you reconnect with the divine in meaningful ways.
                        </p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>Personalized Blessings: The Power of Special Puja</h1>
                        <p>At Dev Kirpa, we understand that each individual’s spiritual journey is unique. That’s why
                            we offer Special Puja services tailored to your specific needs and intentions. Whether
                            you’re facing challenges in your personal or professional life, seeking healing and
                            protection, or simply yearning for inner peace, our experienced priests will perform sacred
                            rituals and prayers on your behalf, invoking the blessings of the divine to manifest your
                            heartfelt desires.</p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>Daily Devotion: Nithya Puja for Spiritual Fulfillment</h1>
                        <p>In the midst of life’s demands and distractions, it’s important to carve out moments of
                            stillness and reflection. Our Nithya Puja service provides a daily oasis of spiritual
                            nourishment, allowing you to cultivate devotion and gratitude in your everyday life. Our
                            dedicated priests will perform daily rituals and prayers on your behalf, fostering a deeper
                            connection with the divine and infusing your days with serenity and purpose.</p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>Honoring Sacred Traditions: Gopuja for Reverence and Gratitude</h1>
                        <p>In Hindu culture, the cow is revered as a symbol of divine grace and abundance. Our
                            Gopuja service offers a unique opportunity to honor and reverence the sacred cow through
                            elaborate rituals and prayers. By expressing gratitude for the cow’s nourishment,
                            sustenance, and spiritual significance, you can connect with the divine presence in all
                            living beings and contribute to the preservation of this ancient tradition.</p>
                    </div>

                    <p>At Dev Kirpa, we believe that spirituality should be accessible to all, regardless of
                        background or beliefs. With our range of puja services, you can dive deep into the essence
                        of divine serenity and experience the transformative power of puja in your own life. Join us
                        on this sacred journey, and let the blessings of the divine flow into every aspect of your
                        being</p>

                </div> */}

                <div className='flex flex-col px-24 gap-3 w-full max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    {
                        CardTwo?.points?.map((point, i) => {
                            return (
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-2xl'>{lang === "hi" ? point?.title?.en : point?.title?.hi}</h1>
                                    <p className='flex flex-col gap-2'>{lang === "hi" ? point?.description?.en?.map((i) => <p>{i}</p>) : point?.description?.hi?.map((i) => <p>{i}</p>)}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Card2