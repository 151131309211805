import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Include/Header";
import Navbar from "../Include/Navbar";
import Footer from "../Include/Footer";
import PujaImg from "../Images/Rectangle 1089.png";
import whatsappImg from "../SVG/Whatsapp.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Loader from '../Images/Dev Kirpa Loader.gif'
import BookingConfirm from "./BookingConfirm.json"
import Loader from "../../Utils/Loader";
import { errorMessage } from "../../Utils/notificationManger";
import checkoutJson from "../BookingDetails/Check.json"
import Metadata from "../../Meta/Metadata";

function BookingConfirmed() {
  const [bookingStatus, setBookingStatus] = useState();
  const [memberlist, setMemberlist] = useState()
  const user_id = localStorage.getItem("user_id");
  const { bookingId } = useParams();

  console.log(bookingId);
  console.log(user_id);
  const lang = JSON.parse(localStorage.getItem("lang"))
  const location = useLocation()

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleShare = () => {
    // You can customize the message as needed
    const shareMessage = `Your Booking Pooja  is  ${lang === "hi" ? bookingStatus?.puja_service_name?.en : bookingStatus?.puja_service_name?.hi}, with  ${lang === "hi" ? bookingStatus.pooja_plan?.en : bookingStatus.pooja_plan?.hi} plan on ${bookingStatus.booking_date}, Total Amount is ${bookingStatus.total_bill} Rs `;
    // console.log(pujaDetails);
    // You can also customize the URL to be shared
    const shareUrl = window.location.href;

    // Open WhatsApp share dialog
    window.open(`https://wa.me/?text=${encodeURIComponent(shareMessage + ` Click On:${shareUrl} to View Details`)}`);
  };

  const GetPujaStatus = async () => {
    try {
      setLoader(true)
      const response = await axios.get(`https://devkirpa.com/admin/api/booking-details?user_id=${user_id}&booking_id=${bookingId}`)
      if (response?.status === 200) {
        const data = await response?.data?.booking_details
        setBookingStatus(data)
      } else {
        throw response
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error?.response?.data);
      // setResponse(error?.response?.data)
    }
    // .get("https://devkirpa.com/admin/api/booking-details?user_id=238&booking_id=69")
    // .then((res) => {
    //   if (res?.status === 200) {
    //     setBookingStatus(res.data.booking_details);
    //   } else {
    //     console.log(res);
    //     setResponse(res)
    //   }

    // });
  };

  const getCartMembers = async () => {
    // const payload = {
    //     cart_id: location?.state?.booking_id
    // }
    try {
      const response = await axios.get(`https://devkirpa.com/admin/api/members?orderitem_id=${bookingId}`, { headers: { "Content-type": "application/json" } })
      const data = await response?.data
      if (response?.status === 200) {
        setMemberlist(data?.["member-list"])
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    if (bookingId) {
      GetPujaStatus();
      getCartMembers()

    }
  }, [bookingId]);

  // console.log("status", bookingStatus);

  return (
    <section className="font-poppins">
      <Metadata
        title={`DEVKIRPA | Booking Confirmation `}
        description="Booking Confirmation Page"
        url={`https://devkirpa.com/Booking/${bookingId}}`}
      />
      <Header />
      <Navbar />
      {
        loader ? <Loader /> : <div className="flex justify-center">
          {
            !bookingStatus && <div className="h-96 w-full flex justify-center items-center text-red-500 font-bold text-4xl animate-pulse">{lang === "hi" ? BookingConfirm?.notFound?.en : BookingConfirm?.notFound?.hi}</div>
          }
          {bookingStatus && (
            <div className="w-full px-32 py-2 max-sm:px-5">
              <div key={bookingStatus.id} className="py-2 w-full  flex flex-col gap-4">
                <div className="pt-2">
                  <div>
                    <p className="text-sm">
                      {lang === "hi" ? BookingConfirm?.booking_date?.en : BookingConfirm?.booking_date?.hi} : <span>{bookingStatus.booking_date}</span>
                    </p>
                  </div>
                  <div className="pt-3 pb-4">
                    <p className="font-semibold text-3xl max-sm:text-lg">
                      {lang === "hi" ? BookingConfirm?.Booking_confirm_title?.en : BookingConfirm?.Booking_confirm_title?.hi}
                      <span className={`${bookingStatus?.Booking_Status === 'Pending' ? "text-orange-600" : "text-green-500"}`}>
                        {" "} {lang === "hi" ? bookingStatus?.Booking_Status?.en : bookingStatus?.Booking_Status?.hi}
                      </span>
                    </p>
                  </div>
                  <div className="BookingSearbtn_res max-sm:text-xs">
                    <p>
                      {lang === "hi" ? BookingConfirm?.booking_confirm_desc?.en : BookingConfirm?.booking_confirm_desc?.hi}
                      <span className="font-bold pl-1">
                        {bookingStatus.email} & {bookingStatus.phone}
                      </span>
                    </p>
                    <div className="flex items-center gap-1" onClick={handleShare}
                      style={{ cursor: "pointer" }}>
                      <div>
                        <img src={whatsappImg} alt="" />
                      </div>
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M6.66339 8.16429C6.53091 8.30646 6.45879 8.49451 6.46221 8.68881C6.46564 8.88311 6.54435 9.06849 6.68177 9.20591C6.81918 9.34332 7.00457 9.42203 7.19887 9.42546C7.39317 9.42889 7.58121 9.35677 7.72339 9.22429L11.4434 5.50429V17.1943C11.4434 17.3932 11.5224 17.584 11.6631 17.7246C11.8037 17.8653 11.9945 17.9443 12.1934 17.9443C12.3923 17.9443 12.5831 17.8653 12.7237 17.7246C12.8644 17.584 12.9434 17.3932 12.9434 17.1943V5.50429L16.6634 9.22429C16.732 9.29797 16.8149 9.35708 16.9068 9.39807C16.9988 9.43906 17.0982 9.4611 17.1989 9.46288C17.2996 9.46465 17.3996 9.44613 17.493 9.40841C17.5864 9.37069 17.6712 9.31454 17.7424 9.24332C17.8136 9.17211 17.8698 9.08727 17.9075 8.99388C17.9452 8.9005 17.9638 8.80047 17.962 8.69976C17.9602 8.59906 17.9382 8.49975 17.8972 8.40775C17.8562 8.31575 17.7971 8.23295 17.7234 8.16429L12.7234 3.16429C12.5828 3.02384 12.3921 2.94495 12.1934 2.94495C11.9946 2.94495 11.804 3.02384 11.6634 3.16429L6.66339 8.16429Z"
                            fill="#F15A24"
                            stroke="#F15A24"
                            stroke-width="0.5"
                          />
                          <path
                            d="M6.69336 22.9443C6.26656 22.9443 5.84395 22.8603 5.44964 22.6969C5.05533 22.5336 4.69705 22.2942 4.39526 21.9924C4.09347 21.6906 3.85408 21.3324 3.69075 20.9381C3.52742 20.5437 3.44336 20.1211 3.44336 19.6943V12.6943C3.44336 12.4954 3.52238 12.3047 3.66303 12.164C3.80368 12.0234 3.99445 11.9443 4.19336 11.9443C4.39227 11.9443 4.58304 12.0234 4.72369 12.164C4.86434 12.3047 4.94336 12.4954 4.94336 12.6943V19.6943C4.94336 20.6643 5.72336 21.4443 6.69336 21.4443H17.6934C18.6634 21.4443 19.4434 20.6643 19.4434 19.6943V12.6943C19.4434 12.4954 19.5224 12.3047 19.663 12.164C19.8037 12.0234 19.9944 11.9443 20.1934 11.9443C20.3923 11.9443 20.583 12.0234 20.7237 12.164C20.8643 12.3047 20.9434 12.4954 20.9434 12.6943V19.6943C20.9434 21.4943 19.4834 22.9443 17.6934 22.9443H6.69336Z"
                            fill="#F15A24"
                            stroke="#F15A24"
                            stroke-width="0.5"
                          />
                        </svg>
                        <span className="text-orange-600 px-1">share</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-orange-600 text-4xl font-bold py-3 mt-3 max-sm:text-sm">
                    <p>
                      {lang === "hi" ? BookingConfirm?.puja_date?.en : BookingConfirm?.puja_date?.hi} : <span>{bookingStatus.puja_date}</span>
                    </p>
                  </div>
                </div>





              </div>
              <div className="">
                <div className="border-t-2 py-5 flex-row-reverse  gap-3 p-2 max-md:flex-col flex justify-center items-center max-sm:flex-col">
                  <div className="flex justify-center w-[50%] max-sm:w-[100%] max-md:w-full max-sm:hidden">
                    <div className="w-50 h-40 max-sm:w-full ">
                      <img
                        src={location?.state?.poojaImage || PujaImg}
                        alt=""
                        className="rounded-lg object-cover object-fit w-full h-full"
                      />
                    </div>
                  </div>
                  <div className="w-[50%] max-sm:w-full max-sm:mt-10 max-md:w-full max-sm:text-xs">
                    <div className="w-full">
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%] max-sm:text-sm">
                          {lang === "hi" ? BookingConfirm?.puja_service_name?.en : BookingConfirm?.puja_service_name?.hi}
                        </p>
                        <p className="font-normal w-[60%] max-sm:text-sm">
                          {lang === "hi" ? bookingStatus?.puja_service_name?.en : bookingStatus?.puja_service_name?.hi}
                        </p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]"> {lang === "hi" ? BookingConfirm?.puja_plan?.en : BookingConfirm?.puja_plan?.hi}</p>
                        <p className="font-normal w-[60%]">
                          {lang === "hi" ? bookingStatus.pooja_plan?.en : bookingStatus.pooja_plan?.hi}
                        </p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]"> {lang === "hi" ? BookingConfirm?.booking_date?.en : BookingConfirm?.booking_date?.hi}</p>
                        <p className="font-normal w-[60%]">
                          {bookingStatus.booking_date}
                        </p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]">
                          {lang === "hi" ? BookingConfirm?.booking_ref?.en : BookingConfirm?.booking_ref?.hi}
                        </p>
                        <p className="font-normal w-[60%]">DEV-00{bookingStatus.id}</p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]">
                          {lang === "hi" ? BookingConfirm?.service_provide?.en : BookingConfirm?.service_provide?.hi}
                        </p>
                        <p className="font-normal w-[60%]">
                          {lang === "hi" ? bookingStatus.service_provider : bookingStatus.service_provider}
                        </p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]">   {lang === "hi" ? BookingConfirm?.consignee?.en : BookingConfirm?.consignee?.hi}</p>
                        <p className="font-normal w-[60%]">{lang === 'hi' ? bookingStatus.consignee?.en : bookingStatus?.consignee?.hi}</p>
                      </div>
                      <div className="flex ">
                        <p className="w-[40%] pb-2 font-bold max-sm:w-[55%]">{lang === "hi" ? BookingConfirm?.total_bill?.en : BookingConfirm?.total_bill?.hi}</p>
                        <p className="font-normal w-[60%]">
                          <span>₹{bookingStatus.total_bill}</span> (PAID)
                        </p>
                      </div>
                    </div>
                    <div className='w-full'>
                      {memberlist?.map((member, i) => {
                        return (
                          <div className='w-full'>
                            <div className='py-2 font-semibold text-xl'>{lang === "hi" ? checkoutJson?.person?.en : checkoutJson?.person?.hi} - {i + 1}</div>
                            <div className='flex w-full justify-between items-center'>
                              <div className="w-[40%] font-semibold">{lang === "hi" ? checkoutJson?.fullName?.en : checkoutJson?.fullName?.hi}:</div>
                              <div className="w-[60%]">{lang === "hi" ? member?.name?.en : member?.name?.hi}</div>
                            </div>
                            <div className='flex w-full justify-between items-center'>
                              <div className="w-[40%] font-semibold">{lang === "hi" ? checkoutJson?.gotraName?.en : checkoutJson?.gotraName?.hi}:</div>
                              <div className="w-[60%]">{lang === "hi" ? member?.gotra?.en : member?.gotra?.hi}</div>
                            </div>
                          </div>
                        )
                      })}

                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

        </div>
      }
      <Footer />
    </section>
  );
  // if (bookingStatus) {

  // } else {
  //   return (
  //     <div className="loader">
  //       <div>
  //         <img src={Loader} alt="" />
  //       </div>
  //     </div>
  //   );
  // }
}

export default BookingConfirmed;
