import React, { useState, useEffect } from "react";
import LikePujas from "./LikePujas";
import clockSvg from "../SVG/clock.svg";
import GroupImg from "../SVG/Group.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Include/Header";
import Navbar from "../Include/Navbar";
import Footer from "../Include/Footer";
// import Loader from "../Images/Dev Kirpa Loader.gif";
import Loader from "../../Utils/Loader";
import { errorMessage } from "../../Utils/notificationManger";
import pujaJson from "./Puja.json"
import DateSvg from "../SVG/Date.svg"
import Metadata from "../../Meta/Metadata";








function Allpujas() {
  // getting the pooja type
  const [likePujas, setLikePujas] = useState();
  const [pujaTypeList, setPujaTypeList] = useState([]);
  const [pujaInfo, setPujaInfo] = useState([]);
  const [pujaCategory, setPujaCategory] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [pujaType, setPujaType] = useState();
  const [selectedPoojaCategory, setSelectedPoojaCategory] = useState("");
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(50000);
  const [showMoreResults, setShowMoreResults] = useState(false);
  const [allPujaClicked, setAllPujaClicked] = useState(false);
  const [loader, setLoader] = useState(false)
  const [cta, setCta] = useState("")
  const [showMore, setShowMore] = useState("")
  const [showLess, setShowLess] = useState("")
  const [allPuja, setAllPuja] = useState("")
  const [devkripa, setDevkripa] = useState("")
  const [search, setSearchValue] = useState("")


  const lang = JSON.parse(localStorage.getItem("lang"))
  const constLang = JSON.parse(localStorage.getItem("constLang"))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showMore, showLess])

  const GetlikePujas = async () => {
    await axios
      .get("https://devkirpa.com/admin/api/pooja-like-list")
      .then((res) => {
        if (res?.status === 200) {
          setLikePujas(res?.data?.list);
        } else {
          setLikePujas([])
        }
      });
  };

  useEffect(() => {
    GetlikePujas();
  }, []);

  const handleShowMoreResults = () => {
    setShowMoreResults(!showMoreResults);
  };

  const handleMinChange = (event) => {
    const value = parseInt(event.target.value);
    if (value <= maxValue) {
      setMinValue(value);
    }
  };

  const handleMaxChange = (event) => {
    const value = parseInt(event.target.value);
    if (value >= minValue) {
      setMaxValue(value);
    }
  };

  const handleDurationChange = (e) => {
    setSelectedDuration(e.target.value);
  };

  const ListStyles = {
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box",
  };

  // console.log(search);

  const GetPujaInfo = async () => {
    setLoading(true)
    let apiUrl = "https://devkirpa.com/admin/api/pooja-all-listing";

    // let filteredUrl = !search === "" ? `${apiUrl}?name = ${search}` : apiUrl

    // Check if any filters are applied
    let filtersApplied = false;
    let filterParams = "";

    if (selectedPoojaCategory) {
      filterParams += "&category_id=" + selectedPoojaCategory;
      filtersApplied = true;
    }

    if (search) {
      filterParams += "?name=" + search;
      filtersApplied = true;
    }

    if (pujaType) {
      filterParams += "&type_id=" + pujaType;
      filtersApplied = true;
    }

    if (selectedDuration) {
      filterParams += "&duration=" + selectedDuration;
      filtersApplied = true;
    }

    if (minValue !== null && maxValue !== null) {
      filterParams += "&price=" + minValue + "-" + maxValue;
      filtersApplied = true;
    }

    // If filters are applied, append them to the URL
    if (filtersApplied) {
      apiUrl += "?" + filterParams.substring(1);
    }



    await axios
      .get(apiUrl)
      .then((response) => {
        console.log(response);
        if (
          response?.status === 200 &&
          response.data &&
          response.data.list &&
          response.data.list.length > 0
        ) {
          setPujaInfo(response?.data?.list);
          console.log("pujainfo", pujaInfo);
          setMessage(null);
        } else {
          setPujaInfo([]);
          setMessage("No Pooja records found.");
        }
      })
      .catch((error) => {
        setPujaInfo([]);
        setMessage("No Puja Record Found !");
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false)
  };

  const getPujaType = async () => {
    await axios
      .get("https://devkirpa.com/admin/api/type-list")
      .then((res) => {
        setPujaTypeList(res?.data?.list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetPujaCategory = async () => {
    await axios
      .get("https://devkirpa.com/admin/api/category-list")
      .then((res) => {
        setPujaCategory(res.data.list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPujaType();
    GetPujaCategory();
  }, [])

  useEffect(() => {

    GetPujaInfo();
  }, [selectedPoojaCategory, pujaType, selectedDuration, minValue, maxValue, search]);

  const handleAllPujaClick = () => {
    setSelectedPoojaCategory(""); // Reset selected puja category
    setPujaType(undefined); // Reset selected puja type
    setSelectedDuration(""); // Reset selected duration
    setMinValue(1); // Reset min value
    setMaxValue(50000); // Reset max value
    setShowMoreResults(false); // Reset show more results
    setAllPujaClicked(true);
    GetPujaInfo(); // Fetch all pujas
  };

  const handleTitleClick = () => {
    setAllPujaClicked(false); // Set all puja clicked state to false
  };

  const getTranslatedCta = async (text) => {
    if (lang !== constLang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setCta(response?.data?.matches);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }
  const getTranslatedShowMore = async (text) => {
    if (lang !== constLang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setShowMore(response?.data?.matches);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }
  const getTranslatedShowLess = async (text) => {
    if (lang !== constLang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setShowLess(response?.data?.matches);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }
  const getTranslatedPuja = async (text) => {
    if (lang !== constLang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setAllPuja(response?.data?.responseData?.translatedText);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }
  const getTranslatedDevkripa = async (text) => {
    console.log(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`);
    if (lang !== constLang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setDevkripa(response?.data?.matches);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }

  console.log(allPuja);


  useEffect(() => {
    // getTranslatedDevkripa("DevKirpa")
    // getTranslatedPuja("All Puja")
    // getTranslatedCta("Book Now")
    // getTranslatedShowMore("Show More Result")
    // getTranslatedShowLess("Show More Result")
  }, [lang])

  return (
    <>
      <Metadata
        title="DEV KIRPA - All Puja"
        description="Discover all types of pujas with DEV KIRPA. Our expert priests conduct rituals for every occasion and need."
        url="https://devkirpa.com/AllPujas"
      />
      <section>
        <Header />
        <Navbar setSearchValue={setSearchValue} />
        <div className="w-[87%] m-auto mt-2 p-2 font-poppins max-sm:text-xs max-sm:m-0 max-sm:p-0 max-md:m-0 max-md:p-0 max-sm:w-full max-md:w-full max-lg:px-10 max-lg:w-[95%]" >
          <div className="flex flex-col pt-3 gap-3">
            <h6>{lang === "hi" ? pujaJson?.heading?.en : pujaJson?.heading?.hi}</h6>
            <h1 className="text-2xl font-bold">{lang === "hi" ? pujaJson?.subheading?.en : pujaJson?.subheading?.hi}</h1>
          </div>

          {/* top  filters section is used here  */}

          <section>
            <div className="py-3 ">
              <ul className="flex w-full justify-center gap-2">
                <li
                  onClick={handleAllPujaClick}
                  className={`py-2 px-3 font-normal cursor-pointer border-2 rounded-3xl ${allPujaClicked
                    ? "bg-orange-100 border-orange-500"
                    : "bg-transparent"
                    }`}
                >
                  <button>{lang === "hi" ? pujaJson?.subheading?.en : pujaJson?.subheading?.hi}</button>
                </li>

                {pujaTypeList.map((items) => (
                  <li
                    onClick={() => {
                      setPujaType(items.id);
                      handleTitleClick();
                    }}
                    key={items.id}
                    className={`py-2 px-3 font-normal cursor-pointer border-2 rounded-3xl ${pujaType === items.id
                      ? "bg-orange-100 border-orange-500"
                      : "bg-transparent"
                      }`}
                  >
                    {lang === "hi" ? items?.title?.en : items?.title?.hi}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          {/* puja  category list is used here   */}
          <div className="justify-between max-sm:w-full gap-3 h-full max-md:flex-col flex max-sm:mr-10 max-sm:flex-col max-sm:justify-center max-sm:items-center">
            <div className="w-[25%] max-sm:w-full max-md:w-full">
              <section className="w-full h-full">
                <div className="bg-[#FFF8F1] max-sm:w-full h-auto shadow-xl rounded p-2 font-semibold" data-aos="fade-down">
                  <div className="p-2">
                    <h3 className="font-bold py-2 ">{lang === "hi" ? pujaJson?.sideBar?.heading?.en : pujaJson?.sideBar?.heading?.hi}</h3>

                    {pujaCategory ? (
                      <>
                        <ul className="w-full font-normal" style={isOpen ? null : ListStyles}>
                          {pujaCategory.map((items) => (
                            <li
                              key={items.id}
                              onClick={() => setSelectedPoojaCategory(items.id)}
                              className="cursor-pointer w-full "
                            >
                              {lang === "hi" ? items?.title?.en : items?.title?.hi}
                            </li>
                          ))}
                        </ul>
                        <button
                          className="text-orange-600 font-bold mt-2 w-full text-start"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          {isOpen ? (
                            <div className="w-full">
                              <div>{lang === "hi" ? pujaJson?.buttons?.button_four?.en : pujaJson?.buttons?.button_four?.hi}  <i className="fa-solid fa-angle-up ml-3"></i> </div>
                            </div>
                          ) : (
                            <>

                              <div>{lang === "hi" ? pujaJson?.buttons?.button_three?.en : pujaJson?.buttons?.button_three?.hi}  <i className="fa-solid fa-angle-down ml-3"></i> </div>
                            </>
                          )}
                        </button>
                      </>
                    ) : (
                      <div className="loader">
                        <div>
                          <img src={Loader} alt="" />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Puja duration is used here  */}

                  {/* <div className="p-2">
                    <h3 className="font-bold py-2">{lang === "hi" ? pujaJson?.sideBar?.subheading?.en : pujaJson?.sideBar?.subheading?.hi}</h3>
                    <div className="flex flex-col font-normal">
                      <label>
                        <input
                          type="radio"
                          name="Duration"
                          value="0 min - 1 hour"
                          checked={selectedDuration === "0 min - 1 hour"}
                          onChange={handleDurationChange}
                        />{" "}
                        {lang === "hi" ? pujaJson?.time?.["0min-1hour"]?.en : pujaJson?.time?.["0min-1hour"]?.hi}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="Duration"
                          value="1 hour - 4 hours"
                          checked={selectedDuration === "1 hour - 4 hours"}
                          onChange={handleDurationChange}
                        />{" "}
                        {lang === "hi" ? pujaJson?.time?.["1hour-4hours"]?.en : pujaJson?.time?.["1hour-4hours"]?.hi}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="Duration"
                          value="4 hours - 1 day"
                          checked={selectedDuration === "4 hours - 1 day"}
                          onChange={handleDurationChange}
                        />{" "}
                        {lang === "hi" ? pujaJson?.time?.["4hour-1day"]?.en : pujaJson?.time?.["4hour-1day"]?.hi}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="Duration"
                          value="1 day - 3 days"
                          checked={selectedDuration === "1 day - 3 days"}
                          onChange={handleDurationChange}
                        />{" "}
                        {lang === "hi" ? pujaJson?.time?.["1day-3days"]?.en : pujaJson?.time?.["1day-3days"]?.hi}
                      </label>
                    </div>
                  </div> */}

                  <div className="p-2">
                    <h3 className="font-bold py-2">{lang === "hi" ? pujaJson?.sideBar?.price?.en : pujaJson?.sideBar?.price?.hi}</h3>
                    <div className="range_inputt font-normal">
                      <p>
                        ₹<span>{minValue}</span> - ₹<span> {maxValue}</span>+
                      </p>
                      <div className="slider">
                        <div className="progres"></div>
                      </div>
                      <div className="range-input">
                        <input
                          type="range"
                          id="minRangeInput"
                          name="minRangeInput"
                          min="1"
                          max="50000"
                          step="1"
                          value={minValue}
                          onChange={handleMinChange}
                        />

                        <input
                          type="range"
                          id="maxRangeInput"
                          name="maxRangeInput"
                          min="1"
                          max="50000"
                          step="1"
                          value={maxValue}
                          onChange={handleMaxChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* All puja list is used here  */}
            <div className="w-[75%] max-md:w-[100%] max-sm:w-full">
              {loading ? (
                <div className="loader">
                  <Loader />
                </div>
              ) : pujaInfo.length === 0 ? (
                <div className="loader">
                  <div className="flex items-center text-red-600 text-2xl font-bold w-auto h-auto p-0 m-0">
                    {lang === "hi" ? pujaJson?.no_pooja_found?.en : pujaJson?.no_pooja_found?.hi}
                  </div>
                </div>
              ) : (
                <section className="relative">
                  <div className="flex flex-col gap-[40px] px-3 max-lg:text-sm">
                    {pujaInfo &&
                      pujaInfo
                        .slice(0, showMoreResults ? pujaInfo.length : 2)
                        .map((items, index) => (
                          <div
                            key={index}
                            className="shadow p-2 rounded-lg flex gap-2 max-sm:block"
                            data-aos="fade-right"
                          >
                            <div className="w-[33%] max-sm:w-[100%]">
                              <div style={{ width: "100%", height: "240px" }}>
                                <img
                                  src={items.image}
                                  alt=""
                                  className="rounded-lg object-cover w-full h-full"
                                />
                              </div>
                            </div>
                            <div className="w-[67%] max-sm:w-[100%] px-2 pt-3">
                              <div className="flex flex-col gap-2">
                                <div>
                                  <p className="font-bold text-xl font-akshar capitalize line-clamp-1">
                                    {lang === "hi" ? items?.name?.en : items?.name?.hi}
                                  </p>
                                </div>
                                <div className="flex gap-2 items-center">
                                  <div className="w-10 h-10">
                                    <img
                                      src="/Logo.jpg"
                                      alt=""
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                  <div>
                                    <h1 className="text-orange-600 text-sm font-bold -mt-1">
                                      {lang === "hi" ? pujaJson?.["logo-title"]?.en : pujaJson?.["logo-title"]?.hi}
                                    </h1>
                                  </div>
                                </div>
                                <div className="flex justify-start items-center gap-2">
                                  <img src={GroupImg} alt="" />
                                  <h1 className="font-semibold capitalize max-sm:text-xs">
                                    {lang === "hi" ? items?.type?.en : items?.type?.hi}
                                  </h1>
                                  <img src={DateSvg} alt="" />
                                  <p className="font-semibold capitalize max-sm:text-xs">
                                    {items.duration}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className="line-clamp-3 text-sm w-[78%] mt-2 max-lg:line-clamp-2">
                                  {lang === "hi" ? items?.description?.en : items?.description?.hi}
                                </p>
                              </div>
                              <div className="py-2 flex justify-between max-sm:m-0 max-sm:p-0 max-sm:flex-col">
                                <div className="flex items-center gap-2 ">
                                  <del
                                    className="flex items-center text-normal text-red-600 "
                                    style={{ fontWeight: "500" }}
                                  >
                                    <span>&#8377;</span>
                                    <span>{items.actual_price}</span>
                                  </del>
                                  <p
                                    className="flex items-center text-green-600 text-xl"
                                    style={{ fontWeight: "700" }}
                                  >
                                    <span>&#8377;</span>
                                    <span>{items.discount_price}</span>
                                  </p>
                                </div>
                                <Link to={"PujaDetails/" + items.slug}>
                                  <button className="bg-[#F15A24] font-bold max-lg:text-sm max-lg:px-6  py-2 px-12 text-light  font-akshar max-sm:w-full">
                                    {lang === "hi" ? pujaJson?.buttons?.button_one?.en : pujaJson?.buttons?.button_one?.hi}
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>

                  {pujaInfo.length > 2 && (
                    <div className="text-orange-600 font-bold cursor-pointer flex justify-center items-center my-5">
                      <button
                        onClick={handleShowMoreResults}
                        className="border-orange-600 cursor-pointer rounded border-2 py-2 px-4 max-sm:left-[30px]"
                      >
                        {showMoreResults
                          ? (lang === "hi" ? pujaJson?.buttons?.button_four?.en : pujaJson?.buttons?.button_four?.hi)
                          : (lang === "hi" ? pujaJson?.buttons?.button_three?.en : pujaJson?.buttons?.button_three?.hi)}
                      </button>
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
          {likePujas?.length > 0 && <LikePujas />}
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Allpujas;
