// import React, { useEffect, useState } from "react";
// import { WhatsAppShareButton } from "react-share";
import PujaPlans from "./PujaPlans";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GroupIcon from "../../../SVG/Group.svg";
import whatsapp_svg from "../../../SVG/Whatsapp.svg";
import Loader from "../../../../Utils/Loader";
import pujaJson from "../../Puja.json"
import { useState } from "react";
import DateImg from "../../../Images/Date.png";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";
// import { FaRegClock } from "react-icons/fa";

function PujaInnerSlider(prop) {
  const pujaDetails = prop.pujaDetails;
  const [selectedIndex, setSelectedIndex] = useState(0)
  const lang = JSON.parse(localStorage.getItem("lang"))

  // Function to handle share button click
  const handleShare = () => {
    // You can customize the message as needed
    const shareMessage = `Check out this special puja: Name: ${lang === "hi" ? prop?.pujaDetails?.name?.en : prop?.pujaDetails?.name?.hi}`;
    // console.log(pujaDetails);
    // You can also customize the URL to be shared
    const shareUrl = window.location.href;

    // Open WhatsApp share dialog
    window.open(`https://wa.me/?text=${encodeURIComponent(shareMessage + " " + shareUrl)}`);
  };


  return (
    <section className="font-poppins max-sm:text-xs max-md-text-sm">
      <div key={pujaDetails?.id}>
        <div className="w-[90%]" style={{ margin: "auto" }}>
          <div className="flex flex-col pt-3">
            <h6 className="capitalize font-normal">{lang === "hi" ? pujaJson?.heading?.en : pujaJson?.heading?.hi} / {lang === "hi" ? pujaDetails?.name?.en : pujaDetails?.name?.hi}</h6>
            <h1 className="text-3xl font-semibold pb-3 pt-4 max-sm:text-sm capitalize">{lang === "hi" ? pujaDetails?.name?.en : pujaDetails?.name?.hi}</h1>
            <div className="flex justify-between items-center w-[64%] max-lg:w-full max-lg:text-sm max-sm:flex-wrap max-sm:gap-2">
              <div className="flex gap-3 text-sm">
                <div className="flex gap-2 items-center">
                  <div className="w-4 h-6">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66bc416af0876b82bdf381484c40f3137e3317d2c4d1fe9e32da3d312e10c54?apiKey=8478572201cf4b3f8647adfe69018175&"
                      alt=""
                      className="w-full h-full object-fit object-start"
                    />
                  </div>
                  <div>
                    <h1 className="text-orange-600 text-xs font-semibold">
                    {lang === "hi" ? pujaJson?.["logo-title"]?.en : pujaJson?.["logo-title"]?.hi}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-start items-center gap-2">
                  {/* <img src={GroupIcon} alt="" /> */}
                  {/* <h1 className="font-semibold pr-6 capitalize">{lang === "hi" ? pujaDetails?.type : pujaDetails?.type}</h1> */}
                  {/* <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="vuesax/linear/clock">
                      <g id="clock">
                        <path
                          id="Vector"
                          d="M19.1647 10.0001C19.1647 14.6001 15.4314 18.3334 10.8314 18.3334C6.23138 18.3334 2.49805 14.6001 2.49805 10.0001C2.49805 5.40008 6.23138 1.66675 10.8314 1.66675C15.4314 1.66675 19.1647 5.40008 19.1647 10.0001Z"
                          stroke="#F15A24"
                          strokewidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M13.9234 12.6501L11.3401 11.1084C10.8901 10.8418 10.5234 10.2001 10.5234 9.67509V6.25842"
                          stroke="#F15A24"
                          strokewidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg> */}

                </div>
                <div>
                  <p className="font-semibold flex justify-center items-center gap-2">
                    {/* <FaRegClock className="text-xl text-orange-600" /> */}
                    {/* <span>{lang === "hi" ? pujaDetails?.duration : pujaDetails?.duration}</span> */}
                  </p>
                </div>
                <div>
                  <div className="flex gap-2 justify-center items-center">
                    <img src={DateImg} alt="date" />
                    <h3 className="text-sm font-semibold">{lang === "hi" ? pujaJson?.puja_date?.en : pujaJson?.puja_date?.hi}</h3>
                    <p>{pujaDetails?.duration}</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-1 mr-6">
                <div>
                  <img
                    src={whatsapp_svg}
                    alt=""
                    onClick={handleShare}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="flex items-center">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M6.66339 8.16429C6.53091 8.30646 6.45879 8.49451 6.46221 8.68881C6.46564 8.88311 6.54435 9.06849 6.68177 9.20591C6.81918 9.34332 7.00457 9.42203 7.19887 9.42546C7.39317 9.42889 7.58121 9.35677 7.72339 9.22429L11.4434 5.50429V17.1943C11.4434 17.3932 11.5224 17.584 11.6631 17.7246C11.8037 17.8653 11.9945 17.9443 12.1934 17.9443C12.3923 17.9443 12.5831 17.8653 12.7237 17.7246C12.8644 17.584 12.9434 17.3932 12.9434 17.1943V5.50429L16.6634 9.22429C16.732 9.29797 16.8149 9.35708 16.9068 9.39807C16.9988 9.43906 17.0982 9.4611 17.1989 9.46288C17.2996 9.46465 17.3996 9.44613 17.493 9.40841C17.5864 9.37069 17.6712 9.31454 17.7424 9.24332C17.8136 9.17211 17.8698 9.08727 17.9075 8.99388C17.9452 8.9005 17.9638 8.80047 17.962 8.69976C17.9602 8.59906 17.9382 8.49975 17.8972 8.40775C17.8562 8.31575 17.7971 8.23295 17.7234 8.16429L12.7234 3.16429C12.5828 3.02384 12.3921 2.94495 12.1934 2.94495C11.9946 2.94495 11.804 3.02384 11.6634 3.16429L6.66339 8.16429Z"
                      fill="#F15A24"
                      stroke="#F15A24"
                      strokWidth="0.5"
                    />
                    <path
                      d="M6.69336 22.9443C6.26656 22.9443 5.84395 22.8603 5.44964 22.6969C5.05533 22.5336 4.69705 22.2942 4.39526 21.9924C4.09347 21.6906 3.85408 21.3324 3.69075 20.9381C3.52742 20.5437 3.44336 20.1211 3.44336 19.6943V12.6943C3.44336 12.4954 3.52238 12.3047 3.66303 12.164C3.80368 12.0234 3.99445 11.9443 4.19336 11.9443C4.39227 11.9443 4.58304 12.0234 4.72369 12.164C4.86434 12.3047 4.94336 12.4954 4.94336 12.6943V19.6943C4.94336 20.6643 5.72336 21.4443 6.69336 21.4443H17.6934C18.6634 21.4443 19.4434 20.6643 19.4434 19.6943V12.6943C19.4434 12.4954 19.5224 12.3047 19.663 12.164C19.8037 12.0234 19.9944 11.9443 20.1934 11.9443C20.3923 11.9443 20.583 12.0234 20.7237 12.164C20.8643 12.3047 20.9434 12.4954 20.9434 12.6943V19.6943C20.9434 21.4943 19.4834 22.9443 17.6934 22.9443H6.69336Z"
                      fill="#F15A24"
                      stroke="#F15A24"
                      strokewidth="0.5"
                    />
                  </svg> */}
                  <span
                    className="text-orange-600 px-1"
                    onClick={handleShare}
                    style={{ cursor: "pointer" }}
                  >
                    share
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4 pb-5 pt-3 max-md:flex-col max-sm:flex-col max-lg:flex-col">
            <div className="PujaSlider w-[65%]  max-md:w-[100%] max-sm:w-[100%] flex flex-col gap-3 max-lg:w-full">
              <div className="relative" data-aos="fade-down">
                <div className="PujaSlider_inner px-2 max-sm:h-52">
                  <img
                    className="object-cover object-center max-sm:h-52"
                    src={pujaDetails?.images[selectedIndex]?.img_name}
                    alt=""
                    width={"100%"}

                  ></img>
                </div>

                <div className="flex justify-between items-center w-full px-5 absolute top-72 max-sm:top-44 max-lg:top-56 max-sm:px-1 text-white">
                  <button className="text-5xl font-semibold shadow-lg" onClick={() => {
                    if (selectedIndex !== 0) {
                      return setSelectedIndex(selectedIndex - 1)
                    }
                  }} disabled={selectedIndex === 0}><IoIosArrowDropleft /></button>
                  <button className="text-5xl font-semibold shadow-lg" onClick={() => {
                    if (selectedIndex < pujaDetails?.images?.length) {
                      return setSelectedIndex(selectedIndex + 1)
                    }
                  }} disabled={selectedIndex >= pujaDetails?.images?.length - 1}><IoIosArrowDropright /></button>
                </div>
              </div>

              <div className="flex gap-3 justify-start items-center p-2 max-sm:hidden">
                {pujaDetails?.images?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-nowrap"
                      data-aos="flip-right"
                    >
                      <img
                        src={item?.img_name}
                        alt=""
                        className={`w-72 h-32 object-cover ${selectedIndex === index ? "border-2 border-orange-500 p-1 transition-all" : "border-0"}`}
                        style={{ borderRadius: "15px" }}
                        onClick={() => setSelectedIndex(index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-[35%] pujaPlans max-lg:w-full">
              {!pujaDetails ? <Loader /> : <PujaPlans pujaDetails={pujaDetails} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PujaInnerSlider;
