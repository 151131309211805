import React from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Occasion from "./Occassion.json"
import Metadata from '../../../Meta/Metadata'

const OccasionPuja = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Occasional Puja`}
                description="Celebrate every occasion with DEV KIRPA's occasion pujas. Enhance your celebrations with sacred rituals."
                url={`https://devkirpa.com/occasionpuja}`}
            />
            <Header />
            <Navbar />
            <div className='flex justify-center items-center flex-col w-full p-3 font-semibold'>
                <div className='w-[70%] p-2 flex flex-col gap-5'>
                    <div className='text-2xl'>
                        <h1>{lang === "hi" ? Occasion?.title?.en : Occasion?.title?.hi}</h1>
                    </div>
                    <div>
                        <p>{lang === "hi" ? Occasion?.description?.en : Occasion?.description?.hi}</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OccasionPuja