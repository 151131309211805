import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from "../../Images/Dev Kirpa Loader.gif";
import pujaJson from "../Puja.json"

function SimilarPujas() {
  const [pujaDetails, setPujaDetails] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const { pooja_id } = useParams();
  const navigate = useNavigate()


  const lang = JSON.parse(localStorage.getItem("lang"))

  const GetPujaDetails = async () => {
    await axios
      .get(
        "https://devkirpa.com/admin/api/pooja-details-web?pooja_id=" + pooja_id
      )
      .then((res) => {
        setPujaDetails(res.data.related_pooja);
      });
  };

  useEffect(() => {
    GetPujaDetails(pooja_id);

  }, [pooja_id]);

  // useEffect(()=>{
  //   window.location.reload()
  // },[])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(4);
      }
    };

    // Call the function once to set initial slidesToShow
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="flex w-[100%] justify-center items-center flex-col font-poppins">
      <div className="flex w-full max-w-[90%] flex-col max-md:max-w-full max-md:my-10">
        <h1 className="py-3 font-semibold flex justify-start items-center text-2xl   max-md:max-w-full max-md:text-3xl max-sm:px-5">
          {lang === "hi" ? pujaJson?.puja_slugs?.heading_five?.en : pujaJson?.puja_slugs?.heading_five?.hi}
        </h1>
      </div>
      <div className="pb-5 px-16 w-[100%] likePujaSlider">
        {pujaDetails.length > 0 ? (
          <Slider {...settings}>
            {pujaDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className="font-semibold w-[100%] shadow rounded-lg"
                  data-aos="flip-left"
                >
                  <div className="slider_inner">
                    <div
                      className="flex justify-center items-center"
                      style={{ height: "170px" }}
                    >
                      <img
                        className="object-cover object-center w-[100%] h-52 rounded-t-lg"
                        src={item.image}
                        alt={item.title}
                      />
                    </div>
                    <div
                      className="flex justify-start flex-col gap-2 mt-3 pb-3"
                      style={{ padding: "13px" }}
                    >
                      <h1 className={`text-md line-height-2 line-clamp-1 font-mono uppercase text-black ${lang === "en" && "pt-2"}`}>
                        {lang === "hi" ? item.name.en : item.name.hi}
                      </h1>
                      <p className="text-sm font-normal line-clamp-2 description">{lang === "hi" ? item.description.en : item.description.hi}</p>
                      <div className="flex items-center gap-2 w-[100%]">
                        <del
                          className="text-normal text-red-600"
                          style={{ fontWeight: "500" }}
                        >
                          ₹{item.actual_price}
                        </del>
                        <span
                          className="text-green-600 text-xl"
                          style={{ fontWeight: "700" }}
                        >
                          ₹{item.discount_price}
                        </span>
                      </div>
                      {/* <Link to={"/pujaDetails/" + item.id}> */}
                      <button className="w-36 bg-orange-500 p-2 text-center text-white rounded"
                        onClick={() => navigate("/AllPujas/PujaDetails/" + item.id)}
                      >
                        {lang === "hi" ? pujaJson?.buttons?.button_one?.en : pujaJson?.buttons?.button_one?.hi}

                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : pujaDetails.length === 0 ? (
          <div className="flex justify-center items-center text-orange-600 text-2xl font-semibold w-auto animate-pulse" data-aos="fade-up">
            {lang === "hi" ? pujaJson?.puja_slugs?.message?.en : pujaJson?.puja_slugs?.message?.hi}
          </div>
        ) : (
          <div className="loader">
            <div>
              <img src={Loader} alt="" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default SimilarPujas;
