import React, { useState } from "react";
import Minus from "../../SVG/minus-square.svg";
import Plus from "../../SVG/add-square.svg";
import pujaJson from "../Puja.json"

function FAQ  (props) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  
  const Questions=props?.pujaDetails?.faq;
  const lang = JSON.parse(localStorage.getItem("lang"))

  

  const handleOpen = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="FAQ  w-[100%] py-10 gap-5 font-poppins">
      <div className="w-[90%] m-auto">
        <div className="flex pb-4 justify-start w-[100%] font-bold text-xl">
          <h1>{lang === "hi" ? pujaJson?.puja_slugs?.heading_four?.en :pujaJson?.puja_slugs?.heading_four?.hi}</h1>
        </div>
        <div className="w-[100%] flex flex-col  gap-4">
          {Questions?.map((ques, index) => (
            
            <div  key={index} className={`border-2 cursor-pointer rounded-lg w-full  ${expandedIndex === index ? "border-orange-500" : "border-[#757575]"}`} >
              <div className="p-3 rounded-lg w-[100%]">
                <div
                  className="w-[100%] flex flex-row  items-start justify-between text-md font-semibold gap-10"
                  onClick={() => handleOpen(index)}
                >
                  <p className="font-semibold">{lang === "hi" ? ques.faq_title : ques.faq_title_hi}</p>
                  <div className="cursor-pointer w-6">
                    {expandedIndex === index ? (
                      <img src={Minus} alt="minus"/>
                    ) : (
                      <img src={Plus} alt="plus"/>
                    )}
                  </div>
                </div>
                <div>
                  {expandedIndex === index && (
                    <p className="float-left transition-all py-2 delay-150 ease-in-out">
                      {lang === "hi" ? ques.faq_desc : ques.faq_desc_hi}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <LikePujas/> */}
    </section>
  );
};

export default FAQ;
