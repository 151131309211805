import React from 'react'
import Header from '../Include/Header'
import Navbar from '../Include/Navbar'
import Footer from '../Include/Footer'
import { useLocation } from 'react-router-dom'
import videoJson from "./video.json"
import Metadata from '../../Meta/Metadata'

const VedioViews = () => {
    const { state } = useLocation()
    const lang = JSON.parse(localStorage.getItem("lang"))
    console.log(state);

    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Booking Video `}
                description="Booking Video Page"
                url={`https://devkirpa.com/videos}`}
            />
            <Header />
            <Navbar />
            <div className='py-3 px-36'>
                <h1 className='p-2'>{lang === "hi" ? videoJson?.title?.en : videoJson?.title?.hi}</h1>
            </div>
            <div>
                {
                    (state?.videos === null || state?.length === 0) && <div className='w-full h-96 animate-pulse text-2xl font-semibold flex justify-center items-center text-red-500'>{lang === "hi" ? videoJson?.notFound?.en : videoJson?.notFound?.hi}</div>
                }
            </div>
            <div className='w-full grid grid-cols-3 py-2 px-36'>
                {
                    state?.videos?.length > 0 && state?.videos?.map((video) => {
                        return (
                            <div className='w-full h-full p-2 flex flex-col gap-3 shadow-lg rounded-md'>
                                <video src={video?.video_url} className='w-full' controls controlList="no download" ></video>
                                <h1 className='ml-2 capitalize'>{video?.video_title}</h1>
                            </div>
                        )
                    })
                }
            </div>
            <Footer />
        </div>
    )
}

export default VedioViews