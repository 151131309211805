import React, { useEffect } from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./TermsAndConditions.css"
import termsJson from "./TermsAndCondition.json"
import Metadata from '../../../Meta/Metadata'

const TermsAndConditions = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Terms & Conditions `}
                description="Devkirpa Terms & Conditions Page"
                url={`https://devkirpa.com/termsandconditions}`}
            />
            <Header />
            <Navbar />
            <div className='flex flex-col gap-3 mt-10 font-semibold mx-52'>
                {
                    termsJson?.data?.map((item, i) => {
                        return (
                            <div>
                                <h1>{lang === "hi" ? item?.title?.en : item?.title?.hi}</h1>
                                {lang === "hi" && Array.isArray(item?.description?.en) && (
                                    <p>
                                        {item.description.en.map((des, index) => (
                                            <p key={index}>{des}</p>
                                        ))}
                                    </p>
                                )}
                                {lang !== "hi" && Array.isArray(item?.description?.hi) && (
                                    <p>
                                        {item.description.hi.map((des, index) => (
                                            <p key={index}>{des}</p>
                                        ))}
                                    </p>
                                )}

                                {/* {
                                    lang === "hi" ? item?.description?.en?.map((list, i) => (
                                        <p key={i} className='list-disc'>{list}</p>
                                    )) : item?.description?.hi?.map((list, i) => (
                                        <p key={i} className='list-disc'>{list}</p>
                                    ))
                                } */}
                            </div>
                        )
                    })
                }
                {/* <div className='flex flex-col gap-3'>
                    <h1>END USER LICENSE AGREEMENT</h1>
                    <h2>Introduction</h2>
                </div>
                <div className='flex flex-col gap-3'>
                    <p>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION
                        TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS
                        GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR
                        DIGITAL SIGNATURES.</p>

                    <p>THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1)
                        OF THE INFORMATION TECHNOLOGY (INTERMEDIARY GUIDELINES AND DIGITAL MEDIA
                        ETHICS CODE) RULES, 2021 THAT REQUIRES PUBLISHING THE RULES AND
                        REGULATIONS, PRIVACY POLICY AND USER AGREEMENT FOR ACCESS OR USAGE OF THE
                        APP/ Web.</p>

                    <p>This mobile application called ‘Devkirpa         ’ (“App”) is owned by DevkripaPrivate Limited, a
                        company incorporated under the provisions of the Companies Act, 2013 and having its
                        registered address at House 64, Block-A, Pocket-3, Sector-08, Rohini, New Delhi – 110085
                        (hereinafter, referred to as “Devkirpa         ”, which expression shall, unless it be repugnant to
                        the context thereof, be deemed to include its successors, affiliates, and permitted
                        assigns)</p>

                    <p>Please read this end user license agreement (“EULA”) carefully, as it contains the terms
                        and conditions governing your use of the App/ Web and any content such as text, data,
                        information, software, graphics, audio, video or photographs that Devkripamay make
                        available through the App/ Web and any services that Devkripamay provide through the
                        App. In order to use the App, you must first agree to this EULA. By accessing, browsing or
                        otherwise using the App, you are agreeing to this EULA and concluding a legally binding
                        contract with Devkirpa         . Please do not access or use the App/ Web if you do not agree
                        with any of the terms provided herein or are unable to be bound by them. As a condition of
                        your access to and use of the App/ Web, you hereby agree that you will comply with all
                        Applicable Laws (defined hereinafter) and regulations when using the App/ Web.
                    </p>

                    <p>Devkripareserves the right, at its sole discretion, to change, modify, add or remove
                        portions of this EULA, at any time and will notify you at least once in a year, or whenever
                        there is a change in this EULA (as the case may be), by email or by posting a conspicuous
                        notice on the App/ Web. Your continued use of the App/ Web following the posting of
                        changes shall be deemed to mean that you accept and agree to the revisions made to this
                        EULA. As long as you comply with this EULA, Devkripagrants you a personal, revocable,
                        non-exclusive, non-transferable, non-sub-licensable, limited license to access and use the
                        App.</p>

                    <p>The general terms of service contained in Section A herein govern your use of any of our
                        services on the App/ Web and the information accessible on or through this App/ Web, on
                        top of any other terms that apply to a specific service you are using (“General Terms”).
                    </p>

                    <p>The specific terms of serv’ce contained in Section B herein govern your use of the astrology
                        services provided on the App/ Web and the information accessible on or through such
                        services (“Specific Terms of Astrology Services”)</p>

                    <p>The specific terms of service contained in Section C herein govern your use of the puja
                        services and other allied services provided on the App/ Web and the information
                        accessible on or through such services (“Specific Terms of Puja Services”).</p>

                    <p>You should read this EULA and access and read all further linked information, if any,
                        referred to in this EULA, as such information contains further terms and conditions that
                        apply to you as a user of the app. Such linked information including but not limited to
                        Devkirpa         ’s privacy policy (“Privacy Policy”) is hereby incorporated by reference into this
                        EULA.</p>

                    <p>If you are unwilling or unable to be legally bound by this EULA, please do not use the App.
                        You cannot accept this EULA if: (a) you are not lawfully entitled to use the App; or (b) if you
                        are not of legal age to form a binding agreement with Devkirpa         .</p>

                    <h1>SECTION A</h1>

                    <h1>GENERAL TERMS OF SERVICE</h1>

                    <h1>DEFINITIONS</h1>

                    <p>In this EULA, unless the context or meaning otherwise requires: (a) all capitalised words
                        and expressions defined by inclusion in quotation and/or parenthesis anywhere in this
                        EULA, have the same meanings as ascribed to such words and expressions; and (b)
                        following words and expressions shall bear the meanings ascribed to them below:
                    </p>

                    <p>“Applicable Law” shall mean and include all applicable Indian statutes, enactments, acts
                        of the state legislature or parliament, laws, ordinances, rules, bye-laws, regulations,
                        notifications, guidelines, directions, directives and orders of any governmental authority,
                        statutory authority, and/or board, as may be applicable and in each case, any
                        implementing regulation or interpretation issued thereunder including any successor law</p>

                    <p>Astrologershall mean a natural person who is qualified to perform services for
                        Devkirpa         ’s Users via. The App/ Web and is registered on the App/ Web to provide
                        services.</p>

                    <p>“Astrology Services” shall have the meaning ascribed to it in Section B of this EULA.</p>

                    <p>“Person” means any natural person, limited or unlimited liability company, corporation,
                        partnership (whether limited or unlimited), proprietorship, Hindu undivided family, trust,
                        union, association, government or any agency or political subdivision thereof or any other
                        entity that may be treated as a person under Applicable Law.</p>

                    <p>“Personal Information”shall mean and include any past, present, or future health, finance,
                        family or any other information of a User which may be provided by the User to the
                        Astrologer pursuant to the provision of the Astrology Services. Personal Information shall
                        mean and include any information that may be used to identify an individual, including, but
                        not limited to, the first and last names, date, time and place of birth, age, gender, birth star,
                        gotra, nakshatra, sankalpa, which may be provided by the User to Devkripawhich shall
                        be shared with the Priest pursuant to the provision of the Puja Services.</p>

                    <p>“Priest” shall mean a natural person who is appointed by the respective temple to perform
                        services and in furtherance of the same, performs services for Devkirpa         ’s Users.</p>

                    <p>“Puja Services” shall have the meaning ascribed to it in Section C of this EULA.</p>

                    <p>“User”shall mean a person who desirous of availing the Astrology Services or Puja Services
                        uses the App/ Web and/or registers on the App/ Web (as the case may be) in accordance
                        with the terms contained herein.</p>

                    <p>“User Account” shall mean the online account exclusive to a User and is used by the User
                        to avail Astrology Services or Puja Services through the App/ Web and is created in
                        accordance with this EULA.</p>

                    <h1>ELIGIBILITY</h1>

                    <p>Use of the App/ Web is available only to natural persons who can enter into legally binding
                        contracts under the Indian Contract Act, 1872. Persons who are incompetent to contract
                        within the meaning of the Indian Contract Act, 1872 including minors, un-discharged
                        insolvents and persons of unsound mind are not eligible to use the App/ Web. Any person
                        under the age of 18 shall not register on the App/ Web and shall not transact on or use the
                        App/ Web. If a minor wishes to use or transact on the App/ Web, such use or transaction
                        may be made by the minor’s legal guardian or parents on the App/ Web. Any minor
                        accessing, using or transacting on the App/ Web in violation of this EULA shall be doing so
                        at their own risk and Devkripashall not be responsible or liable for any activities or
                        interactions of such minor on the App/ Web. Devkripareserves the right to terminate any
                        person’s membership and/or refuse to provide such person with access to the App/ Web if
                        it is brought to the notice of Devkripaor if Devkripadiscovers for itself that such
                        person is not eligible to use the App/ Web.
                    </p>

                    <h1>ACCEPTANCE OF TERMS</h1>

                    <p>In order to use the App/ Web, you must first agree to this EULA. You shall be deemed to
                        have accepted this EULA by (a) clicking to accept this EULA, when prompted on the App/
                        Web; (b) logging onto the App/ Web by using the Login Credentials or such other
                        credentials as may be communicated to you by Devkripafrom time to time; or (c)
                        actually using the App/ Web, in which case, you understand and agree that Devkripawill
                        treat your use of the App/ Web as acceptance of the EULA from that point onwards.</p>

                    <h1>OPENING A USER ACCOUNT</h1>

                    <p>The App/ Web allows only limited and restricted access to the Astrology Services and Puja
                        Services for unregistered Users.</p>

                    <p>In order to use the App, you will have to create the User Account by signing up on the App
                        which can be done by providing/using the following: (a) your cell phone number with
                        password; or (b) email address with password; or (c) such other login credentials that are
                        adopted by Devkripafrom time to time and duly intimated to you, for setting up a User
                        Account on the App. Upon entering the required details, you may either receive a One Time
                        Password (OTP) through email or Short Message Service (SMS) messages for the purpose of
                        verification of your User Account or your User Account may be verified by such other auto
                        verification process adopted by Devkripafrom time to time and duly intimated to you,
                        and upon completion of verification, the User Account is created and is ready for use.</p>

                    <b>While signing-up on the App, you shall not:</b>

                    <p>Create a User Account for anyone other than yourself unless such person’s prior
                        permission has been obtained;</p>

                    <p>Use a User Account that is the name of another person with the intent to impersonate that
                        person; and</p>

                    <p>Create more than one User Account on the App.
                    </p>

                    <p>Devkripacannot and will not be liable for any loss or damage arising from your failure to
                        comply with this provision.</p>

                    <b>USERNAME AND PASSWORD
                    </b>

                    <p>Once registered, in order to log into the User Account on the App, you will be required to
                        provide the following: (a) the cell phone number with password; or (b) the email address
                        with password; or (c) such other login credentials that are adopted by Devkripafrom
                        time to time and duly intimated to you for logging into the User Account on the App, used at
                        the time of signing-up (“Login Credentials”).</p>

                    <p>You shall be solely responsible for maintaining confidentiality of the Login Credentials, and
                        you hereby accept responsibility for all activities on the User Account authenticated
                        through the Login Credentials, whether authorized or not. You agree to keep Login
                        Credentials confidential and secure, and you shall not give or make Login Credentials
                        available, directly or indirectly, to any unauthorized individual. You acknowledge and agree
                        that if you allow, whether through action or inaction, a person to gain access to the Login
                        Credentials, with or without permission, you are authorizing that person to use the App
                        through the User Account, and you shall be responsible for all actions that result from such
                        access, even if you did not want the actions performed, and even if they are unauthorized
                        or fraudulent. Devkripashall not be held liable in any manner whatsoever for lack of any
                        such authorization whilst creating or using the User Account.</p>

                    <p>Devkripacannot and will not be liable for any loss or damage arising from or arising out
                        of your use of the App, theft of the Login Credentials, use of the Login Credentials or
                        release of the Login Credentials to a third party or your authorization to allow another
                        person to access and use the App using the User Account.</p>

                    <p>In case of any misappropriation or unauthorised access of the User Account, you agree to
                        communicate the same to Devkripaimmediately. You shall further ensure that you log in
                        and exit/logout from the User Account at the end of each session or at the time indicated
                        by Devkirpa         . Devkripashall not be liable for any loss or damage arising from your
                        failure to comply with this provision. You may be held liable for losses incurred by
                        Devkripaor User/visitor of the App due to authorised or unauthorised use of the User
                        Account, as a result of your failure in keeping the Login Credentials confidential</p>

                    <p>You agree that the information provided by you to Devkirpa         , at all times (including during
                        registration), will be true, accurate, up-to-date, and complete. You further agree that if you
                        provide any information that is untrue, inaccurate, not up-to-date or incomplete or
                        becomes untrue, inaccurate or incomplete or if Devkripahas reasonable grounds to
                        suspect that such information is untrue, inaccurate, not up-to-date, incomplete or not in
                        accordance with this EULA, Devkripashall have the right to indefinitely suspend or
                        terminate or block access to the User Account on the App and refuse to provide you access
                        to the App. You are advised to use due caution when providing any information to
                        Devkripaaccordingly.
                    </p>

                    <b>AGREEMENT TO RECEIVE COMMUNICATION
                    </b>

                    <p>You hereby by way of accepting this EULA consent to the receipt of communication from
                        Devkripaby way of in-app messages, Short Message Service (SMS) messages,
                        WhatsApp, e-mails, promotional and marketing calls and newsletters. These messages,
                        emails and calls could relate to your registration or the transactions that you carry out
                        through the App and promotions that are undertaken by Devkirpa         .</p>

                    <h1>LINKS TO THIRD PARTY WEBSITES</h1>

                    <p>The App/ Web may contain links and interactive functionality interacting with the websites
                        of third parties. Devkripais not responsible for and has no liability for the functionality,
                        actions, inactions, privacy settings, privacy policies, terms, or content of any such
                        websites. Before enabling any sharing functions to communicate with any such website or
                        otherwise visiting any such website, Devkripastrongly recommends that you review and
                        understand the terms and conditions, privacy policies, settings, and information-sharing
                        functions of each such third-party website.
                    </p>

                    <h1>USE OF THE APP/ WEB</h1>

                    <p>You agree, undertake and covenant that, during the use of the App/ Web, you shall not host,
                        display, upload, modify, publish, transmit, store, update or share any information that:
                    </p>

                    <p>Belongs to another Person and to which you do not have any right.</p>

                    <p>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
                        paedophilic, libellous, invasive of another person’s privacy, insulting or harassing on the
                        basis of gender, hateful or racially or ethnically objectionable, relating or encouraging
                        money laundering or gambling, or an online game that causes harm, or promoting enmity
                        between different groups on the grounds of religion or caste with the intent to incite
                        violence, or otherwise inconsistent or contrary to the Applicable Laws in force in any
                        manner whatsoever.
                    </p>

                    <p>Is misleading in any way.</p>

                    <p>Is harmful to minors.</p>

                    <p>Involves the transmission of junk mail,chain letters, or unsolicited mass mailing
                        orspamming</p>

                    <p>Infringes upon or violates any third party’s rights including, but not limited to, any patent,
                        trademark, copyright or other proprietary rights or intellectual property rights, rights of
                        privacy (including without limitation unauthorized disclosure of a person’s name, email
                        address, physical address or phone number).
                    </p>

                    <p>Provides instructional information about illegal activities such as violating someone’s
                        privacy, or providing or creating computer viruses.</p>

                    <p>Tries to gain unauthorized access or exceeds the scope of authorized access to the App/
                        Web or to profiles, blogs, communities, User Account information or information of other
                        Users/visitors of the App/ Web, bulletins, or other areas of the App/Web or solicits
                        passwords or personally identifying information for commercial or unlawful purposes from
                        other Users/visitors of the App/ Web.</p>

                    <p>Engages in commercial activities without Devkirpa         ’s prior written consent such as
                        engages in contests, sweepstakes, barter, advertising etc</p>

                    <p>Interferes with another User/visitor’s use of the App.</p>

                    <p>Impersonates another Person.</p>

                    <p>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
                        with foreign states, or public order, or causes incitement to the commission of any
                        cognizable offence or prevents investigation of any offence or is insulting any foreign
                        states.</p>

                    <p>Refers to any website or URL that, in Devkirpa         ’s sole discretion, contains material that is
                        inappropriate for the App or any other website, contains content that would be prohibited
                        or violates the letter or spirit of this EULA.</p>

                    <p>Deceives or misleads the addressee/ users about the origin of the messages or knowingly
                        and intentionally communicates any information which is patently false or misleading or
                        grossly offensive or menacing in nature but may reasonably be perceived as a fact.</p>

                    <p>Is patently false and untrue, and is written or published in any form, with the intent to
                        mislead or harass a person, entity or agency for financial gain or to cause any injury to any
                        person.
                    </p>

                    <p>Contains software viruses or any other computer codes, files or programs designed to
                        interrupt, destroy or limit the functionality of any computer resource; or contains any trojan
                        horses, worms or other computer programming routines that may damage, detrimentally
                        interfere with, diminish value of, covertly intercept or steal any system, data or Personal
                        Information of another User/visitor.</p>

                    <p>Is in the nature of an online game that is not verified as a permissible online game.</p>

                    <p>Is in the nature of advertisement or surrogate advertisement or promotion of an online
                        game that is not a permissible online game, or of any online gaming intermediary offering
                        such an online game.
                    </p>

                    <p>Violates any Applicable Law for the time being in force.</p>

                    <p>You hereby accept full responsibility for any consequences that may arise from your use of
                        the App, and expressly agree and acknowledge than in no event and under no
                        circumstances shall Devkripabe held liable to you for any liabilities or damages
                        resulting from or arising out of your use of the App. You shall not use anyone else’s account
                        at any time.</p>

                    <p>You agree and acknowledge that you shall not use the App/ Web for any illegal or
                        unauthorized purpose/activities. You agree to comply with all Applicable Laws pertaining to
                        your use of the App. You further agree and acknowledge that you shall use the services
                        provided on the only for your personal use and not for business purposes.</p>

                    <p>Devkripashall have the right, but not the obligation, to monitor access to or use of the
                        App/ Web to ensure your compliance with this EULA or Applicable Laws, at its sole
                        discretion.</p>

                    <h1>USER’S POSTS AND REVIEWS</h1>

                    <p>The App/ Web may allow you to post certain content, data or information belonging to you,
                        such as reviewing and allowing you to share your experience and views about a particular
                        Service provided on the App, and rate the Service, post your comments and reviews in
                        relation to such services on the /or of an Astrologer on specific pages of the App, as well as
                        submit/ post any suggestions, comments, questions or other information to Devkirpa
                        using the App/ Web (collectively referred to User Content). Provided however that, before
                        being published on the App, such User Content shall be reviewed by Devkripato ensure
                        compliance with this EULA and Applicable Laws.</p>

                    <p>You, being the originator of the User Content, are responsible for the User Content that you
                        upload, post, publish, transmit or otherwise make available on the App. You represent and
                        covenant that you have obtained all relevant consents and approvals in order to post any
                        User Content and shall not post any content relating to or owned by a third party for the
                        promotion or sale of products/ services of such third party. You further represent that all
                        such User Content will be in accordance with Applicable Laws. You acknowledge that
                        Devkripadoes not endorse any User Content on the App/ Web and is not responsible or
                        liable for any User Content. Devkripareserves the right to remove any third-party
                        marketing material and disable access to the User Content on the App</p>

                    <p>You hereby grant Devkripaa perpetual, non-revocable, worldwide, royalty-free and sublicensable right and license to use, copy, distribute, display, publish, transmit, make
                        available, reproduce, modify, adapt the User Content and create derivate works of the User
                        Content. You represent and warrant that you own or otherwise control all of the rights to the
                        User Content that you post or that you otherwise provide on or through the App; and that,
                        as at the date that the User Content is posted or submitted on the App: (a) the User
                        Content is accurate; (b) use of the User Content you supply does not breach this EULA; and
                        (c) that such User Content is lawful.
                    </p>

                    <p>You further represent and warrant that the User Content shall not contain any offensive,
                        libellous, derogatory, hateful or racially or ethnically objectionable language. Further, the
                        User Content shall not contain anything that is obscene, pornographic, constitutes an
                        “indecent representation of women” as provided in The Indecent Representation of Women
                        (Prohibition) Act, 1986.</p>

                    <h1>CONTENT AND INTELLECTUAL PROPERTY RIGHTS</h1>

                    <p>Except for User Content, all of the content and services and products provided on the App,
                        including text, software, scripts, code, designs, graphics, photos and other content and the
                        copyrights, trademarks, service marks, logos, trade names, and other intellectual and
                        proprietary rights associated therewith (“IP”) is owned by Devkripaor others (including
                        without limitation, the third party service providers or Astrologers or temples, as
                        applicable) that Devkripalicenses such content from, and is protected by copyright,
                        trademark, patent and other intellectual property laws.
                    </p>

                    <p>You hereby acknowledge that the IP constitutes original works and has been developed,
                        compiled, prepared, revised, selected, and arranged by Devkripaand others through the
                        application of methods and standards of judgment developed and applied through the
                        expenditure of substantial time, effort, and money and constitutes valuable intellectual
                        property of Devkripaand such others. You thereby agree to protect the proprietary rights
                        of Devkripaduring and after the term of this EULA. You may not selectively download
                        portions of the App/ Web without retaining the copyright notices. You may download
                        material from the App/ Web only for your own personal use and for no commercial
                        purposes whatsoever</p>

                    <p>You shall use the App/ Web strictly in accordance with this EULA, and shall not, directly or
                        indirectly, (a) decompile, disassemble, reverse engineer, or attempt to derive the source
                        code of, or in any manner decrypt, the App; (b) make any modification, adaptation or
                        improvement, enhancement, translation or derivative work from the App; (c) violate any
                        Applicable Laws, rules or regulations in connection with your access or use of the App; (d)
                        remove or obscure any proprietary notice (including any notices of copyright or trademark)
                        forming a part of the App; € use the App/ Web for any commercial or revenue generation
                        endeavours, or other purposes for which it is not designed or intended; (f) distribute or
                        transmit the App/ Web or other services; (g) use the App/ Web for any purpose not in
                        accordance with this EULA; (h) use the App/ Web for creating a service or software that is
                        directly or indirectly, competitive with the App/ Web or any services offered by Devkirpa
                        whether via. The App/ Web or other websites/mobile applications associated with
                        Devkirpa         ; or (i) derive any confidential information, processes, data or algorithms from
                        the App.</p>

                    <p>Any infringement shall lead to appropriate legal proceedings against you at an appropriate
                        forum for seeking all available remedies under Applicable Laws of the country.
                    </p>

                    <h1>RIGHTS & RESERVATIONS</h1>

                    <b>Devkripareserves the right to:
                    </b>

                    <p>Make all decisions in relation to inter alia your registration with Devkirpa         ;
                    </p>

                    <p>Request additional documents and additional information from you for the purpose of your
                        registration as a User with the App; and
                    </p>

                    <p>Deny access to the App/ Web or any portion thereof without notice for the following
                        reasons (i) any unauthorized access or use by you; (ii) attempt(s) to assign or transfer any
                        rights granted to you under this EULA; and/or (iii) violation of any of the provisions of this
                        EULA or any Applicable Laws</p>

                    <h1>TERMINATION OF ACCESS TO THE APP</h1>

                    <p>Your User Account can be terminated at any time by:</p>

                    <p>You, by ceasing to use the App.
                    </p>

                    <p>Devkirpa         , in its sole discretion for any reason or no reason, including your violation of
                        this EULA or lack of use of App. You acknowledge that the termination of access to the App/
                        Web may be affected without any prior notice, and Devkripamay immediately
                        deactivate or delete the User Account and all related information and/or bar any further
                        access to the User Account or the services provided on the App, subject to Applicable Law.
                    </p>

                    <p>Any balance amount remaining in the User Wallet automatically expires on the termination
                        of the User Account for any reason.</p>

                    <p>The disclaimer of warranties, the limitation of liability, and the governing law provisions
                        shall survive any termination of this EULA</p>

                    <h1>SCHEMES AND REWARDS
                    </h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.
                    </p>

                    <h1>REPRESENTATIONS</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>LIMITATION OF LIABILITY</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>DISCLAIMER OF WARRANTIES</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>INDEMNIFICATION</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>PRIVACY POLICY</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>ASSIGNMENT</h1>

                    <p>From time to time, Devkripamay offer and rewards to you for inviting new users to the
                        App/ Web and for completing other activities as per the terms and conditions indicated by
                        Devkripato you.</p>

                    <h1>GOVERNING LAW AND DISPUTE RESOLUTION
                    </h1>

                    <p>This EULA shall be governed by and interpreted and construed in accordance with the laws
                        of India. The place of jurisdiction shall exclusively be in New Delhi, India. In the event of any
                        dispute arising out of this EULA the same shall be settled by a binding arbitration
                        conducted by a sole arbitrator, appointed jointly by both parties and governed by the
                        Arbitration and Conciliation Act, 1996. The seat and venue of arbitration shall be New
                        Delhi, India.</p>

                    <h1>SEVERABILITY AND WAIVER</h1>

                    <p>These EULA, the Privacy Policy and other referenced material herein or on the App, are the
                        entire agreement between you and Devkripawith respect to the services offered on the
                        App, and supersede all prior or contemporaneous communications and proposals
                        (whether oral, written or electronic) between you and Devkripawith respect thereto and
                        govern your future relationship with Devkirpa         . If any provision of this EULA is found to be
                        unenforceable or invalid, that provision will be limited or eliminated to the minimum extent
                        necessary so that this EULA will otherwise remain in full force and effect and enforceable.
                        The failure of either party to exercise in any respect any right provided for herein shall not be
                        deemed a waiver of any further rights hereunder.</p>

                    <h1>GRIEVANCE REDRESSAL MECHANISM</h1>

                    <p>In accordance with the Information Technology Act, 2000 and Rules made thereunder, the
                        name and contact details of the Grievance Officer are provided below:</p>

                    <h1>SECTION B</h1>

                    <h1>SPECIFIC TERMS OF ASTROLOGY SERVICES</h1>

                    <h1>INTRODUCTION
                    </h1>

                    <p>You should read the General Terms which govern your general use of the App. Such General
                        Terms including but not limited to the Privacy Policy are hereby incorporated by reference
                        into this Specific Terms of Astrology Services.</p>

                    <p>The General Terms read with the Specific Terms of Astrology Services and any other
                        agreement that you may enter into with Devkripashall define your relationship and
                        mutual expectations while you use these Astrology Services on the App. In order to use the
                        Astrology Services on the App, it shall be your responsibility to comply with both the
                        General Terms, the Specific Terms of Astrology Services and any other agreement that you
                        may enter into with Devkirpa         .
                    </p>

                    <p>Unless otherwise specified, all capitalised terms used herein shall have the meanings
                        ascribed to them in the General Terms</p>

                    <h1>SERVICES
                    </h1>

                    <p>You, as a User, shall be entitled to use the App/ Web as a platform for (a) enabling discovery
                        of Astrologers on the App; (b) facilitating interactions between the User and the selected
                        Astrologers by booking personalized interactive time bound sessions with the Astrologers
                        (collectively, “Sessions”); and (c) providing such other services as Devkripamay deem
                        fit from time to time (“Astrology Services”).</p>

                    <p>Discovery of Astrologers</p>

                    <p>You acknowledge and understand that Devkripadoes not endorse any Astrologer listed
                        on the App/ Web or make any representations or warranties with respect to quality of the
                        services provided by the Astrologers via. The App, and that the relevance algorithm for
                        Astrologers on the App/ Web through which you, as a User, may book an appointment, is a
                        fully automated system that lists the Astrologers. The details of the Astrologer's profile and
                        information regarding the Astrologer’s practice is provided by the respective Astrologer and
                        displayed on an ‘as is’ basis on the App. These listings of Astrologers do not represent any
                        fixed objective ranking or endorsement by Devkirpa         . Devkripawill not be liable for any
                        change in the relevance of the Astrologers on search results, which may take place from
                        time to time. You further acknowledge that the listing of Astrologers will be based on
                        automated computation of the various factors including inputs made by the other Users
                        including their comments and feedback. Such factors may change from time to time, in
                        order to improve the listing algorithm. Devkripain no event will be held responsible for
                        the accuracy and the relevance of the listing order of the Astrologers on the App.</p>

                    <p>Sessions with the Astrologers</p>

                    <p>Once an Astrologer is selected by you and a Session is booked via. The App, Devkirpa
                        shall provide you access to consultations with Astrologers, in a manner it may deem fit.
                        Should you choose to proceed with a transaction on the App/ Web with an Astrologer,
                        except as expressly provided in this EULA, Devkripawill not be a party to such
                        interaction and take no liability arising from such interaction.</p>

                    <p>During a Session, you shall treat the Astrologer as you would have otherwise treated the
                        Astrologer during a physical one-on-one session. You shall not transact with the
                        Astrologer(s) directly at any point in time, except through the App/ Web or such other
                        manner indicated by Devkirpa         . Provided however that Devkripashall not be
                        responsible for any interactions between yourself and the Astrologers outside the App.
                    </p>

                    <p>During any Session, (i) you shall not share your personal, religious and/or moral views with
                        the Astrologer; and (ii) you shall comply with this EULA and Applicable Laws and shall take
                        utmost care in terms of the Astrology Services being availed.
                    </p>

                    <p>You hereby undertake and warrant to contact the Astrologer only through the App/ Web and
                        you shall not share or request from the Astrologer any personal contact details including
                        phone number and/or email address or commit any act for contacting the Astrologer
                        directly in any manner.</p>

                    <p>You hereby expressly grant permission to Devkripafor recording such Sessions which
                        shall be used for internal evaluation and audit purposes.</p>

                    <p>You agree and understand Devkripais not in the business of providing any advisory
                        and/or consultation services and merely provides the platform for the same and the
                        Astrologers shall remain solely liable for all and/or any advice, communication or
                        consultation provided by the Astrologers to you and Devkripashall, in no way, be made
                        responsible or liable for any defect, deficiency, inadequacy resulting from such advice,
                        communication or consultation provided to you by any Astrologer. The responsibility for
                        redressal of any complaints that you may have will always rest solely with the Astrologer.
                        You further acknowledge and accept that Devkripashall not be liable for any claims for
                        any losses or damages to you and/or the Astrologers and/or any other third party (as the
                        case may be) in relation to the provision of advisory and/or consultation services provided
                        by the Astrologers to you through the App.</p>

                    <p>You further agree and understand that (i) the advisory and/or consultation services
                        provided by the Astrologers are solely for entertainment purposes and are not meant as a
                        substitute for professional medical advice or treatment for specific medical conditions; (ii)
                        the Astrologers do not have the expertise to provide you with any medical and/or financial
                        advice; and (iii) the App/ Web shall not be used to provide any advice in relation to medical
                        emergencies, or for any life-threatening decisions. Nothing on the App/ Web provided as
                        part of this Service is intended to suggest a course of treatment for a particular User and
                        the content is not intended to be a substitute for professional medical advice, diagnosis, or
                        treatment. The User should never disregard professional medical advice or treatment or
                        delay seeking professional medical advice or treatment because of reliance placed on any
                        content on the App/ Web or any advice provided by the Astrologers.</p>

                    <p>Devkripaassumes no responsibility for accuracy and completeness of any information
                        submitted by the Astrologer and made available to you on the App/ Web or otherwise
                        during a Session. Devkripashall also not be liable for any errors in the information
                        included in any communication between you and the Astrologer exchanged via. The App.
                    </p>

                    <p>Devkripamay, on a best efforts’ basis, mediate or resolve any issue that arises in relation
                        to the provision of services to a User, in a manner it deems fit. Notwithstanding the
                        foregoing, it is expressly clarified that the Astrologer shall at all times be responsible or
                        liable for such advisory and/or consultation services provided to the Users through the App.</p>

                    <p>You are aware of the risks and accordingly accept the ensuing risks involved in procuring
                        such services from the Astrologers.</p>

                    <p>You acknowledge, understand and agree that:</p>

                    <p>Devkirpa         ’s role in providing you access to the App/ Web is for the purpose of enabling
                        you to avail services provided to you by the Astrologers and access other Services provided
                        by Devkripaon the App;</p>

                    <p>Devkripamerely provides an infrastructure that inter alia enables you to connect with
                        the Astrologers, including without limitation, a technology platform that facilitates
                        provision of advisory services by Astrologers designed to enable you to communicate with
                        such Astrologers;</p>

                    <p>While Devkripamakes reasonable enquiries to confirm the veracity of any
                        representations made by the Astrologers on the App, Devkripashall not be responsible
                        for any misrepresentation or fraud in this regard;</p>

                    <p>The provision of Astrology Services on the App/ Web may be interrupted, including for
                        maintenance, repairs, upgrades, or network or equipment failures. Devkripastrives to
                        keep the App/ Web up and running; however, all online services suffer occasional
                        disruptions and outages, and Devkripaisn’t liable for any disruption or loss you may
                        suffer as a result; and</p>

                    <p>Devkripamay discontinue some or all of the Astrology Services provided on the App, as
                        it may deem fit, including certain features and the support for certain devices and
                        platforms, at any time</p>

                    <p>You agree that Devkripashall be entitled to revoke, cancel and expire your access to
                        App/ Web if it comes to our notice that you are in violation of any of the terms of this EULA.</p>

                    <h1>PAYMENT FACILITY</h1>

                    <p>For the purpose of facilitating the transaction between the User and the Astrologers,
                        Devkripahas created a virtual wallet (“User Wallet”). The User will be required to
                        maintain a minimum balance in the User Wallet of an amount equivalent to the rate
                        payable to the selected Astrologer for at least 3 (Three) minutes for the Session before
                        booking a Session with such Astrologer. If the balance amount in the User Wallet is
                        insufficient to continue a Session, the User will be given the option to add the requisite
                        amount in order to continue the Session. Payments will be made to the Astrologers using
                        the User Wallet against the services provided by the relevant Astrologer to each User.</p>

                    <p>Devkripamay provide Users with certain recharge benefits and coupons from time to
                        time. Details of such benefits and coupons shall be notified to the Users through the App/
                        Web or through such other means as Devkripamay deem fit.</p>

                    <p>The rate of the Sessions undertaken by each Astrologer shall be informed to the User by
                        Devkripain a manner it may deem fit. The users hereby authorize Devkripato autodebit the required amount for a Session from their respective User Wallet.
                    </p>

                    <p>The App/ Web permits the User to upload money in the User Wallet via. Various modes,
                        including online payments through debit/credit cards and internet banking or any other
                        methods adopted by Devkripafrom time to time.</p>

                    <p>You understand that while availing any of the payment method/s available on the App,
                        Devkripawill not be responsible for or assume any liability, whatsoever in respect of any
                        loss or damage arising directly or indirectly to the User due to:</p>

                    <p>Lack of authorization for any transaction(s);
                    </p>

                    <p>Exceeding the pre-set limit mutually agreed by the User and between bank/s;</p>

                    <p>Any payment issues arising out of the transaction; or</p>

                    <p>Rejection of transaction for any other reason(s) whatsoever.
                    </p>

                    <p>You acknowledge that Devkripawill not be liable for any damages, interests or claims,
                        losses resulting from or suffered on your User Account of not processing a transaction/
                        transaction amount or any delay in processing a transaction/ transaction amount which is
                        beyond the control of Devkirpa         .
                    </p>

                    <h1>REFUND POLICY AND CANCELLATION POLICY</h1>

                    <p>Once a Session is booked and/or completed (as the case may be), no refund requests shall
                        be accepted with respect to deductions made from your User Wallet for the Session.
                        Provided however that you may make a refund request if you are unable to attend a Session
                        owing to any reasons solely attributable to Devkripaby writing to Devkripaat
                        <a href="http://" target="_blank" rel="noopener noreferrer">customer.care@vama.App/</a> Web from your registered e-mail address or contacting the
                        customer care at +91 7459819906. Devkripareserves the right to honour such refund
                        requests in a manner it deems fit and it is clarified that such refund requests shall be
                        accepted by Devkripaonly in exceptional cases. You agree not to challenge such
                        decision in any manner whatsoever.
                    </p>

                    <h1>DISCLAIMER OF WARRANTIES</h1>

                    <p>YOU AGREE AND UNDERSTAND THAT ANY ADVICE AND/OR CONSULTATION PROVIDED BY
                        THE ASTROLOGER THROUGHOUT THE APP/ WEB IS SOLEY FOR EXTERTAINMENT
                        PURPOSES AND IS NOT MEANT AS A SUBSTITUTE FOR SEEKING QUALIFIED
                        PROFESSIONAL HELP. DevkripaAND/OR THE ASTROLOGERS SHALL NOT BE LIABLE
                        FOR ANY LOSS, DAMAGE, INJURY CAUSED TO YOU RESULTING FROM YOUR ACTS OR
                        OMISSIONS IN FURTHERANCE TO THE ADVICE AND/OR CONSULTATION WITH THE
                        ASTROLOGERS AND/OR CONTENT MADE AVAILABLE ON THE APP.</p>

                    <h1>SECTION C</h1>

                    <h1>SPECIFIC TERMS OF PUJA SERVICES</h1>

                    <h1>INTRODUCTION
                    </h1>

                    <p>You should read the General Terms which governs your general use of the App/ Web. Such
                        General Terms including but not limited to Privacy Policy are hereby incorporated by
                        reference into these Specific Terms of Puja Services.</p>

                    <p>The General Terms read with the Specific Terms of Puja Services and any other agreement
                        that you may enter into with Devkripashall define your relationship and mutual
                        expectations while you use these Puja Services on the App/ Web. In order to use the Puja
                        Services on the App/ Web, it shall be your responsibility to comply with both the General
                        Terms, the Specific Terms of Puja Services and any other agreement that you may enter into
                        with Devkirpa         .
                    </p>

                    <p>Unless otherwise specified, all capitalized terms used herein shall have the meanings
                        ascribed to them in the General Terms.</p>

                    <h1>SERVICES</h1>

                    <p>You, as a User, shall be entitled to use the App/ Web as a platform for (a) booking and
                        experiencing virtual puja for various temples; (b) donating any amount as you deem fit to
                        the Priest; (c) offering food to the deity of the temple (bhog); (d) providing meal for cows
                        (gau seva); € booking delivery of puja prasad and any other item which forms part of the
                        puja ritual and as specified by Devkripaon the App/ Web from time to time; (f) meals for
                        those in need (anna daan) and (g) availing such other services as Devkripamay deem fit
                        from time to time (“Puja Services”).</p>

                    <p>The Puja Services can be availed in the following ways: (a) one-to-one puja wherein you will
                        book a slot at your preferred temple and the Priest shall exclusively undertake a puja on
                        your behalf on live camera; and (b) group puja wherein Devkripawill facilitate a group
                        puja on a special occasion at a specified temple under which a group puja will be
                        performed by the Priest for you along with other users of Devkirpa         .</p>

                    <p>Should you choose to proceed with a transaction on the App/ Web with a temple, except as
                        expressly provided in this EULA, Devkripawill not be a party to such interaction and take
                        no liability arising from such interaction.</p>

                    <p>During a puja, you shall treat the Priest as you would have otherwise treated the Priest
                        during a physical one-on-one session. You shall not transact with the Priest(s) directly at
                        any point in time, except through the App/ Web or such other manner indicated by
                        Devkirpa         . Provided however that Devkripashall not be responsible for any
                        interactions between yourself and the Priest outside the App/ Web.</p>

                    <p>You hereby expressly grant permission to Devkripafor storing the recording of such
                        pujas which shall be used for internal evaluation and audit purposes by Devkirpa        .</p>

                    <p>You agree and understand that Devkripais not in the business of carrying on the
                        treatment of any specific disease, disorder or ailment, nor does Devkripapromise to
                        medically improve or alleviate any specific physical/medical condition or address any
                        specific physical condition, or the lack thereof by facilitating the conducting of such Puja
                        Services. You acknowledge that you are not using the Puja Services for the cure of any
                        specific disease, disorder, ailment, or physical/mental condition. You agree and
                        understand that Devkripamerely provides the platform for facilitating the Puja Services
                        and the temple and/or Priest shall remain solely liable for all Puja Services provided to you
                        and Devkripashall, in no way, be made responsible or liable for any defect, deficiency,
                        inadequacy resulting from such Puja Services provided to you by the Priest. The
                        responsibility for redressal of any complaints that you may have will always rest solely with
                        the concerned temple authority or religious institution. You further acknowledge and
                        accept that Devkripashall not be liable for any claims for any losses or damages to you
                        and/or the temple authority or religious institution and/or Priest and/or any other third party
                        (as the case may be) in relation to the provision of Puja Services provided to you through
                        the App.</p>

                    <p>You acknowledge and understand that neither Devkripaendorses any temple or
                        religious institution listed on the App/ Web nor makes any representations or warranties
                        with respect to quality of the Puja Services being provided by the Priest.</p>

                    <p>Devkripaassumes no responsibility for accuracy and completeness of any Puja Services
                        made available to you on the App/ Web and shall also not be liable for any errors in the
                        information provided on the App.
                    </p>

                    <p>The Puja Services are subject to approval from the respective temple authorities, and it is
                        expressly clarified that Devkripashall not be responsible is the Puja Services are not
                        conducted due to reasons attributable to the temple authority or religious institution
                        and/or the Priest</p>

                    <p>You acknowledge, understand and agree that</p>

                    <p>Devkirpa        ’s role in providing you access to the App/ Web is for the purpose of enabling
                        you to avail Puja Services facilitated through the app/ Web and provided by the Priests and
                        access such other services provided by Devkripaon the App;
                    </p>

                    <p>Devkripamerely provides an infrastructure that inter alia enables you to connect with
                        the temples and religious institutions, including without limitation, a technology platform
                        that facilitates provision of puja and allied services by the Priests;
                    </p>

                    <p>Devkripareserves the right to accept or reject any request for a Puja Service placed on
                        the App/ Web in part or in full;
                    </p>

                    <p>In the event the price of a Puja Service is amended by a temple, Select Astro reserves the
                        right to provide the Puja Service to you at an updated price. In the event you wish to not
                        avail the Puja Services owing to the aforementioned revisions, you have the right to cancel
                        the same in accordance with the terms of this EULA.</p>

                    <p>All Puja Services facilitated through the App/ Web do not involve any kind of animal
                        sacrifice;</p>

                    <p>Certain temples and religious institutions prohibit photography, videography or live
                        streaming inside the premises of the temple or religious institution for sanctity, security
                        and/or other reasons or such photography, videography or live streaming may not be
                        possible due to network issues at the premises of the temple or religious institution and/or
                        areas surrounding the premises of the temple or religious institution and in such an
                        instance, the videos, photos or live streaming shall not be provided by Devkripato you.
                        Devkripawill not be liable for any losses, costs, expense or damages in such an
                        instance. You expressly waive any right to make any claims of any nature whatsoever on
                        account of such an event;</p>

                    <p>Devkripais not an agent of any temple or religious institution and does not perform the
                        Puja Services through the Priests of the temple or religious institutions. Additionally,
                        Devkripais not a temple or religious institution and does not favour or support any
                        particular temple or religious institution. The Puja Services provided on the App/ Web are in
                        no manner discriminatory towards other religions or religious practices and shall not,
                        under any circumstances whatsoever be deemed to be a disrespect of other religions or as
                        favouring any religion over other religions or religious practices;</p>

                    <p>Devkripadoes not support any particular religion or religious movement or religious
                        philosophy and does not receive any payment from any temple or religious institution.
                        Devkripadoes not promote or support, directly or indirectly, superstition, communal
                        disharmony, fundamentalism, extremism, racism, disrespect to any religion or
                        discrimination towards any cast, creed, colour or section;</p>

                    <p>Devkripais not the official website and/or representative of any temple or religious
                        institution. The total payment made by you to Devkripaincludes temple’s official
                        charges, puja samagri items cost, Priests’ dakshina, prasad shipping and online temple
                        service fees (whichever is applicable and has been paid for);
                    </p>

                    <p>Puja Services provided on the App/ Web are not magical remedies and are not a substitute
                        for any professional medical advice or treatment for specific medical conditions and/or
                        financial advice;</p>

                    <p>You are availing the Puja Services on the App/ Web out of your free will and discretion and
                        not under any undue influence. You understand that religion is a matter of faith and Puja
                        Services provided on the App/ Web do not guarantee any indicated effects or outcomes;
                    </p>

                    <p>The description mentioned with the Puja Services are only approximate and there may be a
                        slight variation in the pictures depicted and the respective Puja Services being conducted
                        in real time;
                    </p>

                    <p>The delivery of puja prasad and any other item depends on the delivery partner as well as
                        on the pin code of the place of the scheduled delivery. Devkripashall not be liable for
                        any act or omission of the delivery partner or for any delay in delivery caused due to any
                        reason;</p>

                    <p>The provision of Services on the App/ Web may be interrupted, including for maintenance,
                        repairs, upgrades, or network or equipment failures. Devkripastrives to keep the app/
                        Web up and running; however, all online services suffer occasional disruptions and
                        outages, and Devkripaisn’t liable for any disruption or loss you may suffer as a result;
                    </p>

                    <p>Devkripamay discontinue some or all of the Services provided on the App, as it may
                        deem fit, including certain features and the support for certain devices and platforms, at
                        any time</p>

                    <p>You agree that Devkripashall be entitled to revoke, cancel and expire your access to
                        App/ Web if it comes to our notice that you are in violation of any of the terms of this EULA.
                    </p>

                    <h1>PAYMENT FACILITY</h1>

                    <p>The App/ Web permits payment via various modes, including virtual payment wallets,
                        online payments through debit/credit cards and internet banking or any other method
                        adopted by Devkripafrom time to time.</p>

                    <p>While availing any of the payment method/s available on the App, Devkripawill not be
                        responsible for or assume any liability, whatsoever in respect of any loss or damage arising
                        directly or indirectly to you due to:</p>

                    <p>Lack of authorization for any transaction(s);</p>

                    <p>Exceeding the pre-set limit mutually agreed by you and between bank/s;
                    </p>

                    <p>Any payment issues arising out of the transaction; or</p>

                    <p>Rejection of transaction for any other reason(s) whatsoever</p>

                    <p>All payments made for Puja Services on the App/ Web by you shall be compulsorily in
                        Indian Rupees. It is expressly clarified the Devkripawill not facilitate transaction with
                        respect to any other form of currency with respect to the Puja Services availed on the App.</p>

                    <p>You acknowledge that Devkripawill not be liable for any damages, interests, claims or
                        losses resulting from or suffered on your User Account arising out of non-processing of a
                        transaction/transaction amount or any delay in processing of a transaction/transaction
                        amount which is beyond the control of Devkirpa        </p>

                    <p>Security: please do not share your card details, login details, etc. with our employees or
                        officers or any third parties to prevent/avoid any fraudulent use of your credit/ debit and
                        internet banking accounts. Any card information provided on the website/app is completely
                        secured. We take reasonable measures to ensure that every profile’s identity and payment
                        details are highly protected.</p>

                    <h1>REFUND POLICY AND CANCELLATION POLICY</h1>

                    <p>Once a Puja Service is booked and/or completed (as the case may be), no refund requests
                        shall be accepted. Provided however that you may make a request for modification 36
                        (Thirty Six) hours before the scheduled time for the Puja Services. It is being expressly
                        clarified that no refund request shall be entertained by Devkripaif for unforeseeable
                        reasons the one-to-one puja or group puja is cancelled by the temple or the religious
                        institution. In the event the Puja Service is re-scheduled to an alternative date by the
                        temple or the religious institution, Devkripaupon prior notification to you may perform
                        the Puja Service on such alternative date and such auspicious time as deemed fit by the
                        Priest. For any refund request, you may write to Devkripaat [insert e-mail] from your
                        registered e-mail address or contacting the customer care at [insert e-mail]. Devkirpa
                        reserves the right to honour such refund requests in a manner it deems fit and it is clarified
                        that such refund requests shall be accepted by Devkripaonly in exceptional cases. You
                        agree not to challenge such decision of Devkripain any manner whatsoever.</p>

                    <h1>DISCLAIMER OF WARRANTIES</h1>

                    <p>YOU AGREE AND UNDERSTAND THAT PUJA SERVICES CAN BE CANCELLED BY
                        DevkripaAND/OR THE TEMPLE OR THE RELIGIOUS INSTITUTION WITH OR WITHOUT
                        PROVIDING ANY REASON AND/OR THE DATE AND TIME OF ANY OF THE PUJA SERVICES
                        CAN BE CHANGED TO ANOTHER DATE AND TIME DUE TO OCCURRENCE OF ANY EVENT
                        THAT IS BEYOND THE REASONABLE CONTROL OF DevkripaAND SHALL INCLUDE,
                        WITHOUT LIMITATION, HAPPENING OF AN UNFORESEEABLE EVENT AT THE PREMISE OF
                        THE TEMPLE OR THE RELIGIOUS INSTITUTION, NON-AVAILABILITY OF THE PRIEST, OVERLY
                        CROWDED PREMISE OF THE TEMPLE OR THE RELIGIOUS INSTITUTION, VISITATION OF
                        THE PREMISE OF THE TEMPLE OR THE RELIGIOUS INSTITUTION BY A SIGNIFICANT
                        PERSON, SABOTAGE, FIRE, FLOOD, EXPLOSION, ACTS OF GOD, CIVIL COMMOTION,
                        STRIKES, LOCKOUTS, RIOTS, INSURRECTION, WAR, ACTS OF GOVERNMENT, FOREIGN OR
                        GOVERNMENTAL ORDER INCLUDING ORDERS OF LOCK-DOWN, QUARANTINE, AN
                        OUTBREAK OF AN EPIDEMIC, PANDEMIC OR OTHER COMMUNICABLE DISEASES, CIVIL
                        DISTURBANCES AND ANY OTHER SIMILAR EVENTS NOT WITHIN THE CONTROL OF
                        DevkripaAND WHICH DevkripaIS NOT ABLE TO OVERCOME (SUCH
                        RESCHEDULED TIME BEING CONSIDERED AUSPICIOUS BY THE PRIEST) AND IN SUCH AN
                        EVENT, DevkripaSHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY
                        CAUSED TO YOU RESULTING FROM SUCH ACTS OR OMISSIONS</p>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}

export default TermsAndConditions