import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const user_id = JSON.parse(localStorage.getItem("user_id"))
const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    // Check localStorage for authentication state
    const storedAuth = JSON.parse(localStorage.getItem('auth'));
    if (storedAuth && storedAuth.isAuthenticated) {
      dispatch({ type: 'LOGIN', payload: storedAuth.user });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(state));
  }, [state]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};


const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useAuth();

  useEffect(() => {
    if (!state.isAuthenticated) {
      navigate("/login");
    }
  }, [state.isAuthenticated, navigate]);
  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(state));
  }, [state]);

  return state.isAuthenticated ? children : null;
};

export { AuthProvider, useAuth, AuthGuard };
