import React, { useEffect, useState } from 'react';
import Header from '../Include/Header';
import Footer from '../Include/Footer';
import Loader from "../../Utils/Loader";
import Navbar from '../Include/Navbar';
import { errorMessage, successMessage } from '../../Utils/notificationManger';
import axios from 'axios';
import charityJson from "./Charity.json"

const Dhan = () => {
    const [finalAmount, setFinalAmount] = useState(0);
    const [dhanList, setDhanList] = useState([]);
    const [loader, setLoader] = useState(false);
    const user = JSON.parse(localStorage.getItem("user_id"));
    const [charityId, setCharityId] = useState([]);
    const [addedCharities, setAddedCharities] = useState([]);

    const lang = JSON.parse(localStorage.getItem("lang"));

    const addPriceToFinalAmount = (amount, id) => {
        setFinalAmount(finalAmount + amount);
        setCharityId(prevIds => [...prevIds, id]);
        setAddedCharities(prev => [...prev, id]);
    };

    const removeCharity = (id) => {
        setFinalAmount(finalAmount - dhanList.find(item => item.id === id)?.price);
        setCharityId(prevIds => prevIds.filter(itemId => itemId !== id));
        setAddedCharities(prev => prev.filter(itemId => itemId !== id));
    };

    const fetchDhanas = async () => {
        try {
            setLoader(true);
            const response = await axios.get("https://devkirpa.com/admin/api/charities");
            const data = await response?.data;
            setDhanList(data?.list);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            errorMessage(error?.response?.data?.message);
        }
    };

    const addToCart = async () => {
        const formData = new FormData();
        formData.append("user_id", user);

        charityId?.forEach(id => {
            return formData.append("charity_id[]", id);
        });
        try {
            const response = await axios.post("https://devkirpa.com/admin/api/add-to-cart", formData);
            const data = await response?.data;
            successMessage(data?.message);
        } catch (error) {
            errorMessage(error?.response?.data?.error);
        }
    };

    useEffect(() => {
        fetchDhanas();
    }, []);

    return (
        <div>
            <Header />
            <Navbar />
            <div className='flex w-full p-4 gap-5 font-poppins'>
                {
                    loader ? <Loader /> :
                        <div className='w-full font-semibold flex justify-center items-center flex-col'>
                            <div className='flex flex-col p-2 w-[88%] justify-center items-start'>
                                <div className='my-2'>
                                    <h1 className='text-2xl ml-1 font-bold'>{lang === "hi" ? charityJson?.Charities?.en :  charityJson?.Charities?.hi}</h1>
                                </div>
                                <div className='grid grid-cols-1 gap-2 mt-5'>
                                    {
                                        dhanList?.map((dhan) => (
                                            <div className='flex justify-around items-center gap-3 shadow-md p-3 max-sm:p-0 border-2 rounded-lg max-sm:flex-col' key={dhan?.id} data-aos="fade-right">
                                                <div className='w-[10%] flex justify-center items-center p-2 max-sm:w-full'>
                                                    <img src={dhan?.image} alt="api" className='w-20 h-20 rounded-full object-cover' />
                                                </div>
                                                <div className='flex gap-5 w-[80%] max-sm:flex-col justify-around p-2 items-center max-sm:w-full'>
                                                    <div className='flex font-normal flex-col gap-3 w-[90%]'>
                                                        <h1 className='text-2xl capitalize font-akshar'>{lang === "hi" ? dhan?.name?.en : dhan?.name?.hi}</h1>
                                                        <p>{lang === "hi" ? dhan?.description?.en : dhan?.description?.hi}</p>
                                                    </div>
                                                    <p className='w-[10%] max-sm:w-full font-normal px-2'>&#x20b9; {dhan?.price}</p>
                                                </div>
                                                <div className='w-[10%] text-center p-2 max-sm:w-full mr-10'>
                                                    {
                                                        addedCharities.includes(dhan.id) ? (
                                                            <button className='w-32 p-2 max-sm:ml-5 max-md:ml-5  rounded bg-red-500 text-white max-sm:w-full' onClick={() => removeCharity(dhan.id)}>{lang === "hi" ? charityJson?.remove?.en :  charityJson?.remove?.hi}</button>
                                                        ) : (
                                                            <button className='w-32 max-sm:ml-5 max-md:ml-5 p-2 rounded bg-orange-500 text-white max-sm:w-full' onClick={() => addPriceToFinalAmount(parseInt(dhan?.price), dhan?.id)}>{lang === "hi" ? charityJson?.add?.en :  charityJson?.add?.hi}</button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='w-[88%] my-3'>
                                <h1 className='p-2'>{lang === "hi" ? charityJson?.Total_price?.en :  charityJson?.Total_price?.hi}: {finalAmount}</h1>
                                <button className='float-end p-2 rounded bg-orange-500 text-white hover:bg-orange-500' onClick={addToCart}>{lang === "hi" ? charityJson?.addtocart?.en :  charityJson?.addtocart?.hi}</button>
                            </div>
                        </div>
                }
            </div>
            <div className='mt-3'>
                <Footer />
            </div>
        </div>
    );
};

export default Dhan;
