export const convertDateToNumberFormat = (dateString) => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    // Remove the day of the week part (e.g., ", Friday")
    const [datePart] = dateString.split(', ');
  
    // Split the date part into components
    const [day, monthName, year] = datePart.split(' ');
  
    // Get the month number from the month name
    const month = monthNames.indexOf(monthName) + 1;
  
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  export const convertTimeTo24HourFormat = (timeString) => {

    console.log(timeString);
    // Extract time and period (AM/PM)
    const [time, period] =  timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);
    
    let newHours = hours;
  
    if (period === 'PM' && hours !== 12) {
      newHours = hours + 12; // Convert PM hours to 24-hour format
    } else if (period === 'AM' && hours === 12) {
      newHours = 0; // Convert 12 AM to 00 hours
    }
  
    // Format hours and minutes with leading zeros
    const formattedHours = String(newHours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:00`;
  };
  
  