import React from 'react'
import { Helmet } from 'react-helmet'

const Metadata = ({ title, description, url }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="/Logo.jpg" />
            <meta property="og:url" content={url} />
        </Helmet>
    )
}

export default Metadata