import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { loginSchema } from "../Auth/Schemas/index";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginImg from "../Images/Rectangle 1089.png";
import { useAuth } from "../Context/AuthContext";
import { errorMessage, successMessage } from "../../Utils/notificationManger";

function SignUp() {

  //  --------------{  Api for Sending data through Appointment form   }------------------//

  // const [values, setValues] = useState({

  //   phone: "",

  // });

  // const handleSubmitForm = async (values) => {
  //   try {
  //     await axios.post(
  //       "https://devkirpa.com/admin/api/register",
  //       values
  //     );
  //     console.log("Form submitted successfully:", values);


  //   } catch (error) {
  //     // console.error("Error submitting form:", error);
  //     console.log('Request:', values);
  //   }
  // };

  // const handleChange = (e) => {
  //   setValues({
  //     ...values,
  //     [e.target.name]: e.target.value.slice(0,10),
  //   });
  // };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   handleSubmitForm(values);
  // };

  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        phone: "91",
        otp: "",
        token: null,
      },

      validationSchema: loginSchema,

      onSubmit: async (values, { setFieldValue }) => {
        if (values.token) {
          // Verification logic
          try {
            const response = await axios.post(
              "https://devkirpa.com/admin/api/web-verify-otp",
              {
                token: values.token,
                phone: values.phone,
                otp: values.otp,
              }
            );

            const data = await response?.data

            if (data?.Profile === "Not Completed") {

              localStorage.setItem("user_id", data?.user_id)
              navigate("/complete-profile")
              successMessage("Registeration Completed Complete Your Profile to Login")

            } else {
              navigate("/")
            }




            // if (response.data.token) {
            //   dispatch({ type: "LOGIN", payload: response.data });
            // } else {
            //   console.error("Login API response doesn't contain a valid token");
            // }
            // console.log("Successfully Logged In:", response.data);
            // navigate("/");
          } catch (error) {
            console.error("Error in verifying OTP:", error);
            errorMessage(error?.response?.data?.error)
          }
        } else {
          // Send OTP logic
          try {
            const response = await axios.post(
              "https://devkirpa.com/admin/api/login",
              {
                phone: values.phone,
              }
            );

            console.log("OTP sent Successfully:", response.data);

            // Update token in formik state
            setFieldValue("token", response.data.token);
          } catch (error) {
            console.error("Error in sending OTP:", error);
          }
        }
      },
    });

  return (
    <section>
      {/* <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="flex flex-row modal-content">
            <div className="w-[36%]">
              <img src={LoginImg} alt="" />
            </div>
            <div className="w-[64%]">
              <div className="flex justify-end pr-2 pt-3">
                <h5>English (UK)</h5>
              </div>
              <div className="flex-col flex justify-center items-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66bc416af0876b82bdf381484c40f3137e3317d2c4d1fe9e32da3d312e10c54?apiKey=8478572201cf4b3f8647adfe69018175&"
                  className="aspect-[0.77] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full"
                  alt=""
                />
              </div>
              <div class="modal-header">
                <div
                  id="exampleModalToggleLabel"
                  className="modal-title  flex flex-col justify-center items-center py-3 w-[100%] max-md:max-w-full max-md:mt-10"
                >
                  <h1 className="flex justify-center items-center text-orange-600 text-2xl  font-bold uppercase max-md:max-w-full">
                    Sign In
                  </h1>
                </div>
              </div>
              <div class="modal-body">
                <div className="w-[100%]  flex justify-center items-center">
                  <form  onSubmit={onSubmit} className="pl-5 grid justify-start items-center  gap-4  max-sm:w-[100%] max-sm:grid-cols-1">
                    <div className="flex flex-col  gap-2">
                      <label className="  text-black mx-1 font-semibold ">
                        Mobile No.:
                      </label>
                      <input
                        className=" no-spinner border border-[#910033] focus:outline-[#910033] border-2 rounded-lg p-1 max-md:w-[75%] max-sm:w-[100%]"
                        type="number"
                        onChange={handleChange}
                        style={{ width: "300px" }}
                        name="phone"
                        id="phn"
                        value={values.phone}
                      />
                    </div>
                    <div className="relative flex flex-col  w-[100%] gap-2">
                      <label className=" text-black mx-1 font-semibold ">
                        Veryfication Code:
                      </label>
                      <input
                        className="no-spinner border border-[#910033] focus:outline-[#910033] border-2 rounded-lg p-1 max-md:w-[75%] max-sm:w-[100%]"
                        type="password"
                        maxlength="10"
                        name="password"
                        id="password"
                      />
                      <i className="absolute text-xs right-4 top-11 fa-solid fa-eye-slash"></i>
                    </div>

                    <div className="flex justify-center items-center w-[100%] gap-3">
                      <button
                        className="bg-orange-600 justify-center items-center rounded-lg px-6 py-1 text-white font-bold mb-6 hover:scale-95 duration-300 hover:shadow-xl max-md:w-[75%] max-sm:w-[75%]"
                        type="submit"
                        style={{ width: "300px" }}
                        // onClick={onSubmit}
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="flex flex-row modal-content">
          <div className="w-[48%] max-sm:hidden max-md:hidden max-lg:hidden">
            <img src={LoginImg} alt="" />
          </div>
          <div className="w-[58%] max-sm:w-full max-md:w-full max-lg:w-full max-sm:mt-32">
            <div className="flex justify-end pr-2 pt-3 max-sm:justify-center max-md:justify-center max-lg:justify-center">
              <h5>English (UK)</h5>
            </div>
            <div className="flex-col flex justify-center items-center">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66bc416af0876b82bdf381484c40f3137e3317d2c4d1fe9e32da3d312e10c54?apiKey=8478572201cf4b3f8647adfe69018175&"
                className="aspect-[0.77] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full"
                alt=""
              />
            </div>
            <div className="modal-header">
              <div
                id="exampleModalToggleLabel"
                className="modal-title  flex flex-col justify-center items-center py-3 w-[100%] max-md:max-w-full max-md:mt-10"
              >
                <h1 className="flex justify-center items-center text-orange-600 text-2xl  font-bold uppercase max-md:max-w-full">
                  Signin
                </h1>
              </div>
            </div>
            <div className="modal-body">
              <div className="w-[100%]  flex justify-center items-center">
                <form
                  onSubmit={handleSubmit}
                  className="pl-5 grid justify-start items-center gap-4 max-sm:w-[100%] max-sm:grid-cols-1 max-sm:p-0 max-md:p-0 max-lg:p-0"
                >
                  <div className="relative flex flex-col gap-2 ">
                    <label
                      htmlFor="number"
                      className="text-black mx-1 font-semibold"
                    >
                      Mobile No.:
                    </label>
                    <input
                      className="no-spinner border-[#910033] focus:outline-[#910033] border-2 rounded-lg p-1 max-md:w-[75%] max-sm:w-[100%]"
                      type="number"
                      style={{ width: "300px" }}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.phone && errors.phone && (
                      <div className="text-red-700">{errors.phone}</div>
                    )}
                    {values.token ? (
                      <button
                        type="submit"
                        className="absolute border-2 border-orange-600 top-8 right-0 rounded-lg px-4 py-1"
                      >
                        Verify OTP
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="absolute border-2 border-orange-600 top-8 right-0 rounded-lg px-4 py-1"
                      >
                        Send Code
                      </button>
                    )}
                  </div>

                  {values.token ? (
                    <>
                      <div className="relative flex flex-col w-[100%] gap-2">
                        <label className="text-black mx-1 font-semibold">
                          Verification Code:
                        </label>
                        <input
                          className="no-spinner border border-[#910033] focus:outline-[#910033] border-2 rounded-lg p-1 max-md:w-[75%] max-sm:w-[100%]"
                          type="number"
                          value={values.otp}
                          name="otp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.otp && errors.otp && (
                          <div className="text-red-700">{errors.otp}</div>
                        )}
                        <i className="absolute text-xs right-4 top-11 fa-solid fa-eye-slash"></i>
                      </div>

                      <div className=" flex justify-center items-center w-[100%] gap-3">
                        <button
                          className="disabled:opacity-50 disabled:pointer-events-none bg-orange-600 justify-center items-center rounded-lg px-6 py-1 text-white font-bold mb-6 hover:scale-95 duration-300 hover:shadow-xl max-md:w-[75%] max-sm:w-[75%]"
                          type="submit"
                          style={{ width: "300px" }}
                        >
                          Signin
                        </button>
                      </div>
                    </>
                  ) : null}


                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SignUp