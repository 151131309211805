import React, { useEffect, useState } from "react";
// import FAQ from "../FAQ/FAQ's";
import Footer from "../Include/Footer";
import Ourblog from "../OurBlog/Ourblog";
import Appointment from "../Appointment/Appointment.jsx";
import OurClientsHapp from "../OurClinetsHappy/OurClientsHappy";
import WhyChoose from "../WhyChoose/WhyChoose";
// import RemediesDoshas from "../Remedies/Remedies_Doshas";
import Services from "../Services/Services";
// import About from "../About/About";
import Navbar from "../Include/Navbar";
import Header from "../Include/Header";
import Banner from "../Banner/Banner";
import BannerImg from "../Images/Rectangle 30.png";
import AboutUS from "../About/AboutUS";
import PujaCard from "../AllPuja/AllPujs.jsx";
import Metadata from "../../Meta/Metadata.jsx";
import axios from "axios";
import { errorMessage } from "../../Utils/notificationManger.jsx";

function Navigation() {
  const [remediesPuja, setRemediesPuja] = useState([]);
  //   const [mainTitle, setMainTitle] = useState("")

  const GetRemediesData = async () => {
    try {
      const res = await axios.get(
        "https://devkirpa.com/admin/api/pooja-all-listing"
      );
      if (Array.isArray(res.data.list)) {
        setRemediesPuja(res.data.list);
        // setSlidesToShow(res?.data?.list?.length)
      } else {
        console.log("service data is not an array:", res.data.list);
      }

      setRemediesPuja(res.data.list);
    } catch (error) {
      console.log("Error:", error);
      setRemediesPuja([])
      errorMessage(error?.response?.data?.error)
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
    GetRemediesData()
  }, [])
  return (
    <div className="font-semibold font-poppins w-full">
      <Metadata
        title="DEV KIRPA - Online Puja | Aarti | Bhajan | Chalisa"
        description="Experience the divine with DEV KIRPA's online puja, aarti, bhajan, and chalisa services. Connect spiritually from home.
"
        url="https://devkirpa.com"
      />
      <div className="relative w-full">
        <div className="w-full">
          <img src={BannerImg} alt="" className="opacity-50 h-[30%] max-lg:h-full max-sm:hidden" />
        </div>

        <div
          className="absolute top-0 opacity-80 w-full bg-[#ffe1e1]"
          style={{
            height: "-webkit-fill-available",
          }}
        >
          <div className="w-full">
            <div className="w-full">
              <Header />
            </div>
            <div style={{ background: "lavenderblush", width: "100%" }}>
              <Navbar />
            </div>
            <div className="absolute w-full" >
              <Banner />
            </div>
            <div className="mt-[50%] max-sm:mt-[580px] max-lg:mt-[650px] max-md:mt-[450px]">
              <AboutUS />
            </div>
            {remediesPuja?.length > 0 && <div className="w-full" id="w-full">
              <PujaCard />

            </div>}
            <div className="w-full">
              <WhyChoose />
            </div>
            <div className="w-full" id="">
              <OurClientsHapp />
            </div>
            <div className="w-full" id="">
              <Appointment />
            </div>
            <div className="w-full">
              <Ourblog />
            </div>
            <div className="mt-5">
              <Footer />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Navigation;
