import React, { useEffect, useState } from 'react'
import Header from '../Include/Header'
import Navbar from '../Include/Navbar'
import Footer from '../Include/Footer'
import { errorMessage, successMessage } from '../../Utils/notificationManger'
import axios from 'axios'
// import clockSvg from "../SVG/clock.svg";
import DateSvg from "../SVG/Date.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import { MdDelete } from "react-icons/md";
import Loader from '../../Utils/Loader'
import orderJson from "./Order.json"
import checkoutJson from "../BookingDetails/Check.json"
import Metadata from '../../Meta/Metadata'

const OrderConfirmation = () => {
    const [pooja, setPooja] = useState([])
    // const [price, setPrice] = useState("")
    const lang = JSON.parse(localStorage.getItem("lang"))
    const user_id = JSON.parse(localStorage.getItem("user_id"))
    // const [productId, setProductId] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const [totalBillAmount, setTotalBillAmount] = useState(0)
    const location = useLocation()
    const [memberlist, setMemberlist] = useState()

    console.log(location?.state, "---state from pooja page booking id");

    const fetchPooja = async () => {
        try {
            setLoader(true)
            const response = await axios.get(`https://devkirpa.com/admin/api/cart-item?user_id=${user_id}&type=book_now`)
            const data = await response?.data
            if (response?.status == 200) {
                setPooja(data?.list)
            } else {
                setPooja([])
            }

            // setPrice(data?.list[0]?.packagePrice)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            errorMessage(error?.response?.data?.message)
            setPooja([])
        }
    }

    const handleDelete = async (id) => {


        try {
            setLoader(true)
            const response = await axios.get(`https://devkirpa.com/admin/api/remove-cart-item?cart_id=${id}`)
            const data = await response?.data
            successMessage(data?.message)
            fetchPooja()
            setLoader(false)
        } catch (error) {
            setLoader(false)
            errorMessage(error?.response?.data?.message)
        }
    }


    const handleDeleteCharity = async (cart_id, charity_id) => {
        try {
            const response = await axios.get(`https://devkirpa.com/admin/api/remove-cart-charity-item?cart_id=${cart_id}&charity_id=${charity_id}`)
            const data = await response?.data
            successMessage(data?.message)
            fetchPooja()
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        }
    }

    console.log(location?.state?.booking_id);

    const getCartMembers = async () => {
        // const payload = {
        //     cart_id: location?.state?.booking_id
        // }
        try {
            const response = await axios.get(`https://devkirpa.com/admin/api/members?cart_id=${location?.state?.booking_id}`, { headers: { "Content-type": "application/json" } })
            const data = await response?.data
            if (response?.status === 200) {
                setMemberlist(data?.["member-list"])
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        }
    }

    const handleRedirect = async (e) => {
        e.preventDefault()

        // console.log(pooja[0]?.product_id);
        // console.log(pooja);
        if (pooja.length > 1) {
            errorMessage("only 1 Pooja is accepted")

        } else {
            if (window.confirm("Are You Sure want to make payment confirm")) {
                try {

                    console.log(`https://devkirpa.com/admin/api/cashfree-payment?user_id=${user_id}&${pooja.map(id => `productIds[]=${id?.product_id}`).join('&')}&total_amount=${totalBillAmount?.split(".")[0]}`);

                    // const productIdsArray = pooja.map((productId) => `productId[]=${productId?.product_id}`);

                    const response = await axios.get(`https://devkirpa.com/admin/api/cashfree-payment?user_id=${user_id}&${pooja.map(id => `productIds[]=${id?.product_id}`).join('&')}&total_amount=${totalBillAmount?.split(".")[0]}`)
                    const data = await response?.data

                    const link = data[0]?.redirectionLink

                    // if(link){
                    //     window.open(link, '_blank');
                    //     navigate(`/Booking/${location?.state?.booking_id}`)
                    // }

                    window.open(link, '_blank');
                    // navigate(`/Booking/${location?.state?.booking_id}`)

                    window.location.href = link;
                } catch (error) {
                    errorMessage(error?.response?.data?.message || error?.response?.data?.error)
                }
            } else {
                try {
                    setLoader(true)
                    const response = await axios.get(`https://devkirpa.com/admin/api/remove-cart-item?cart_id=${pooja[0]?.id}`)
                    const data = await response?.data
                    successMessage(data?.message)
                    fetchPooja()
                    navigate("/Allpujas")

                    setLoader(false)
                } catch (error) {
                    setLoader(false)
                    errorMessage(error?.response?.data?.message)
                }
            }

        }





        // let checkoutOptions = {
        //   paymentSessionId: "payment-session-id",
        //   returnUrl: "http://localhost:3000",

        // }
        // cashfree.checkout(checkoutOptions).then(function (result) {
        //   if (result.error) {
        //     alert(result.error.message)
        //   }
        //   if (result.redirect) {
        //     console.log("Redirection")
        //   }
        // });
    }

    function calculateTotalPrice(item) {
        let packagePrice = parseFloat(item.packagePrice);
        if (isNaN(packagePrice)) {
            packagePrice = 0
        }
        const charityTotal = item.charityPrices.reduce((total, price) => total + parseFloat(price), 0);
        const totalPrice = packagePrice + charityTotal;
        return totalPrice.toFixed(2); // Assuming you want to display the total price with 2 decimal places
    };

    const calculateTotalBill = () => {
        let totalBill = 0;
        pooja.forEach((item) => {
            totalBill += parseFloat(calculateTotalPrice(item));
        });
        setTotalBillAmount(totalBill?.toFixed(2))
        // Assuming you want to display the total bill with 2 decimal places
    };



    useEffect(() => {

        fetchPooja()
        getCartMembers()

    }, [])

    useEffect(() => {
        calculateTotalBill()
    }, [pooja])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    console.log(memberlist);


    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Order Confirmation `}
                description="Devkirpa Order confirmation Page"
                url={`https://devkirpa.com/order-confirmation}`}
            />
            <Header />
            <Navbar />
            <div className='w-full'>
                {
                    loader ? <Loader /> : <div className='font-semibold max-sm:text-xs font-poppins my-10 flex justify-center gap-3 items-center flex-col w-full '>
                        <div className='px-20 w-full max-sm:px-5'>
                            <h1>{lang === "hi" ? orderJson?.title?.en : orderJson?.title?.hi}</h1>
                        </div>
                        <div className='w-full'>
                            <div className='px-20 py-2 w-full max-sm:px-5'>
                                {
                                    !pooja?.length && <div className='text-center text-2xl animate-pulse text-red-700 shadow'>{lang === "hi" ? orderJson?.notFound?.en : orderJson?.notFound?.hi}</div>
                                }
                                {
                                    pooja?.map((puja) => {
                                        return (
                                            <div className='font-poppins max-sm:text-xs w-full border-2 px-3 py-3 rounded flex flex-col max-sm:p-0'>
                                                <h1 className='w-full'>{lang === "hi" ? puja?.pujaName?.en : puja?.pujaName?.hi}</h1>
                                                <div className='w-full flex items-center justify-start'>
                                                    <img src="/Logo.jpg" alt="logo" className='w-14 h-14 object-cover' />
                                                    <p className='text-orange-700 font-bold'>{lang === "hi" ? orderJson?.devkirpa?.en : orderJson?.devkirpa?.hi}</p>
                                                </div>
                                                <div className='w-full'>
                                                    <div className='flex justify-between items-center '>
                                                        <div>
                                                            {puja?.charityNames?.map((el, i) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <p className='capitalize mx-2 text-orange-500'>{lang === "hi" ? el?.en : el?.hi}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className='flex gap-5 justify-center  items-center'>
                                                            <div>
                                                                {puja?.charityPrices?.map((el, i) => {
                                                                    return (
                                                                        <div key={i}>
                                                                            <p className='capitalize'> &#x20B9; {el}</p>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div>
                                                                {puja?.charity_ids?.map((el, i) => {
                                                                    return (
                                                                        <div key={i}>
                                                                            <button className='capitalize p-2 bg-red-600 rounded-full text-white' onClick={() => handleDeleteCharity(puja?.id, el)}> <MdDelete /></button>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex w-full justify-between max-sm:p-0 items-center max-sm:flex-col max-sm:gap-5 py-2 max-sm:items-start max-sm:justify-start'>
                                                    <div className='flex justify-start items-center max-sm:justify-between gap-3 max-sm:gap-1 max-sm:flex-col max-sm:items-start'>
                                                        <p className='px-2 py-2 w-64 text-center flex justify-center items-center rounded-full border-2 border-orange-400 bg-[#FFF8F1] max-sm:p-0 '>{lang === "hi" ? puja?.packageName?.en : puja?.packageName?.hi}</p>
                                                        <div className='flex gap-2 max-sm:items-start max-sm:justify-start'>
                                                            <img src={DateSvg} alt="clock" />
                                                            <p className='w-full'>{puja?.pujaDuration}</p>
                                                        </div>
                                                    </div>
                                                    <p className=''>&#x20B9;{puja?.packagePrice}</p>
                                                </div>
                                                <div>
                                                    <div className='w-full'>
                                                        <button className='text-white px-4 py-2 float-end bg-red-600 rounded-full' onClick={() => handleDelete(puja?.id)}>{lang === "hi" ? orderJson?.delete?.en : orderJson?.delete?.hi}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='w-[40%] px-20 max-sm:w-full max-sm:px-5 sm:max-md:px-5 sm:max-md:w-full md:max-lg:w-full md:max-lg:px-5'>
                                {memberlist?.map((member, i) => {
                                    return (
                                        <div className='w-full'>
                                            <div className='py-2 font-semibold'>{lang === "hi" ? checkoutJson?.person?.en : checkoutJson?.person?.hi} - {i + 1}</div>
                                            <div className='flex w-full justify-between items-center'>
                                                <div className="w-[40%] font-semibold">{lang === "hi" ? checkoutJson?.fullName?.en : checkoutJson?.fullName?.hi}:</div>
                                                <div className="w-[60%]">{lang === "hi" ? member?.name?.en : member?.name?.hi}</div>
                                            </div>
                                            <div className='flex w-full justify-between items-center'>
                                                <div className="w-[40%] font-semibold">{lang === "hi" ? checkoutJson?.gotraName?.en : checkoutJson?.gotraName?.hi}:</div>
                                                <div className="w-[60%]">{lang === "hi" ? member?.gotra?.en : member?.gotra?.hi}</div>
                                            </div>
                                        </div>
                                    )
                                })}


                            </div>
                        </div>
                        <div className='flex flex-col w-[90%] justify-center items-end gap-3 p-2'>
                            <div className='flex flex-col p-2 gap-3'>
                                <div className='flex justify-start items-center gap-3'>
                                    <h1>{lang === "hi" ? orderJson?.total_price?.en : orderJson?.total_price?.hi}</h1>
                                    <p>&#x20B9;{totalBillAmount}</p>
                                </div>
                                <button className='px-5 py-2 bg-orange-600 text-white' onClick={handleRedirect}>{lang === "hi" ? orderJson?.['make-payament']?.en : orderJson?.['make-payament']?.hi}</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

export default OrderConfirmation