import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { loginSchema } from "../Auth/Schemas/index";
import { useLocation, useNavigate } from "react-router-dom";
import LoginImg from "../Images/Rectangle 1089.png";
import { useAuth } from "../Context/AuthContext";
import { errorMessage, successMessage } from "../../Utils/notificationManger";
import SignInJson from "./SignIn.json"
import { Password } from "@mui/icons-material";
import ReactFacebookLogin from "react-facebook-login";
import Metadata from "../../Meta/Metadata";

function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const lang = JSON.parse(localStorage.getItem("lang"))
  const location = useLocation()
  const profile_status = JSON.parse(localStorage.getItem("profile_status"))
  const togglepassVisibility = () => {
    setShowPassword(!showPassword)
  }
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  console.log(location);


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        phone: "+91",
        otp: "",
        token: null,
      },


      validationSchema: loginSchema,


      onSubmit: async (values, { setFieldValue }) => {
        // if (values.token) {

        try {
          const response = await axios.post(
            "https://devkirpa.com/admin/api/login",
            {
              phone: values.phone,
            }
          );
          console.log("OTP sent Successfully:", response.data);
          // Update token in formik state
          setFieldValue("token", response.data.token);
        } catch (error) {
          console.error("Error in sending OTP:", error);
          errorMessage(error?.response?.data?.errors?.message)

        }
        // Verification logic
        try {

          const response = await axios.post(
            "https://devkirpa.com/admin/api/web-verify-otp",
            {
              token: values.token,
              phone: values.phone,
              otp: values.otp,
            }
          );

          const data = await response?.data

          // if (data?.Profile === "Not Completed") { 
          //   navigate("/SignUp")
          //   errorMessage("please do register properly")
          // } else {
          //   navigate("/")
          //   successMessage(response?.data?.message)
          //   localStorage.setItem("lang", JSON.stringify("en"))
          // }

          if (response.data.user_id) {
            //user_id stored in local storage
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            // console.log(response.data);

            dispatch({ type: "LOGIN", payload: response.data.user_id });
          } else {
            // console.error("Login API response doesn't contain a valid token");
            errorMessage("Login API response doesn't contain a valid token")
          }
          // console.log("Successfully Logged In:", response.data);
          // successMessage(response?.data?.message)
          // if (response?.data?.user?.all_details_fillup === "0") {
          //   successMessage("Please complete profile first")
          //   return navigate("/profileDetails")
          // }
          if (response?.data?.user?.all_details_fillup === "0") {
            localStorage.setItem("profile_status", JSON.stringify(response?.data?.Profile))
          } else {
            localStorage.setItem("profile_status", JSON.stringify("Completed"))
          }


          if (location?.state !== null && location?.state?.move) {

            const newPayload = {
              user_id: response.data.user_id,
              package_id: location?.state?.payloadSecure?.packageId,
              puja_id: location?.state?.payloadSecure?.puja_id,
              date: location?.state?.payloadSecure?.date
            }

            if (response?.data?.user?.all_details_fillup === "0") {
              successMessage("Please complete profile first")
              return navigate("/profileDetails", { state: { payloadSecure: location?.state?.payloadSecure } })
            }
            // navigate(location?.state?.move)


            let booking_ids = 0

            await axios.post('https://devkirpa.com/admin/api/book-now', newPayload)
              .then((res) => {
                console.log(res.data)
                booking_ids += res?.data?.cartid

                // navigate(`/order-confirmation`, { state: { booking_id: booking_ids, path: location.pathname } })
              }).catch((error) => {
                console.error('Error adding booking:', error);
                errorMessage("An error occurred while booking. Error from backend.")

              })

            await axios.post("https://devkirpa.com/admin/api/booking", newPayload)
              .then((res) => {
                // booking_ids += res?.data?.booking_id
              })

            const navigationPayload = {
              packageId: location?.state?.payloadSecure?.packageId,
              pujaPlans: location?.state?.payloadSecure?.pujaPlans,
              pujaDetails: location?.state?.payloadSecure?.pujaDetails,
              booking_id: booking_ids
            }

            navigate("/Checkout", { state: { payload: navigationPayload } })
          } else if (profile_status == "Not Completed") {
            navigate("/profileDetails");
          } else if (response?.data?.user?.all_details_fillup === "0") {
            return navigate("/profileDetails")
          } else {
            navigate("/");
          }
          localStorage.setItem(
            "auth",
            JSON.stringify({ isAuthenticated: true, user: response.data })
          );
        } catch (error) {
          console.error("Error in verifying OTP:", error);
          errorMessage(error?.response?.data?.message)
        }
      }
      // else {
      //   // Send OTP logic
      //   try {
      //     const response = await axios.post(
      //       "https://devkirpa.com/admin/api/login",
      //       {
      //         phone: values.phone,
      //       }
      //     );
      //     console.log("OTP sent Successfully:", response.data);
      //     // Update token in formik state
      //     setFieldValue("token", response.data.token);
      //   } catch (error) {
      //     console.error("Error in sending OTP:", error);
      //     errorMessage(error?.response?.data?.errors?.message)

      //   }
      // }
      // },
    });

  // const handleLoginFacebook = async () => {
  //   try {
  //     const response = await axios.post("https://devkirpa.com/admin/api/facebook/login", { email: "shivakumarchinthoju@gmail.com", Password: "aruna1207" })
  //     console.log(response);

  //   } catch (error) {
  //     console.log(error?.message)
  //   }
  // }

  // const responseFaceBook = (response) => {
  //   console.log(response)
  // }

  return (
    <div className="w-full flex justify-center items-center">
      <Metadata
        title={`DEVKIRPA | Login `}
        description="Devkirpa Login Page"
        url={`https://devkirpa.com/login}`}
      />
      <div className="w-[50%] flex justify-center items-center max-sm:px-4 max-lg:w-[80%]">
        <div className="flex w-full justify-center items-start border-2 p-2 mt-10 rounded max-sm:mt-32">
          <div className="w-[48%] max-sm:hidden max-md:hidden">
            <img src={LoginImg} alt="" />
          </div>
          <div className="w-[58%] flex flex-col justify-center items-center gap-3 max-sm:w-full max-sm:p-0 max-md:w-full max-md:p-0">
            <div className="flex justify-end pr-2 pt-3 w-full gap-5">
              <h6 className="cursor-pointer" onClick={() => navigate("/")}>{lang === "hi" ? SignInJson?.home?.en : SignInJson?.home?.hi}</h6>
              <h5>{lang === "hi" ? SignInJson?.language?.en : SignInJson?.language?.hi}</h5>

            </div>
            <div className="flex-col flex justify-center items-center ">
              <img
                loading="lazy"
                src="/Logo.jpg"
                className="w-32 h-32 object-cover mix-blend-multiply "
                alt=""
              />
            </div>
            <div className="w-full">
              <div
                id=""
                className="w-full p-2"
              >
                <h1 className="flex justify-center items-center text-orange-600 text-2xl  font-bold uppercase max-md:max-w-full">
                  {lang === "hi" ? SignInJson?.header?.en : SignInJson?.header?.hi}
                </h1>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full">
                <form
                  onSubmit={handleSubmit}
                  className=" w-full flex justify-center items-start flex-col gap-4 max-sm:w-[100%] max-md:w-full"
                >
                  <div className="  flex flex-col justify-center items-start p-2 gap-2 w-full ml-24 max-sm:m-0 max-lg:m-0">
                    <label
                      htmlFor="number"
                      className="text-black mx-1 font-semibold w-full"
                    >
                      {lang === "hi" ? SignInJson?.Mobile_Number?.en : SignInJson?.Mobile_Number?.hi}
                    </label>
                    <input
                      className="w-56  border-orange-600 focus:outline-orange-600 border-2 rounded-lg p-1 max-md:w-full max-lg:w-full  max-sm:w-[100%]"
                      type="text"

                      name="phone"
                      placeholder="Mobile number"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.phone && errors.phone && (
                      <div className="text-red-700 w-[80%]" >{errors.phone}</div>
                    )}
                  </div>

                  <div className="w-full">
                    {/* <div className="relative flex flex-col  max-sm:w-full max-lg:w-full gap-2 px-2 w-60 ml-24 max-sm:m-0 max-lg:m-0">
                      <label className="text-black mx-1 font-semibold">
                        {lang === "hi" ? SignInJson?.verification?.en : SignInJson?.verification?.hi}
                      </label>
                      <input
                        className=" focus:outline-orange-600  border-2 rounded-lg p-1 max-lg:w-[100%]  max-sm:w-[100%]"
                        type={showPassword ? 'text' : 'password'}
                        value={values.otp}
                        name="otp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.otp && errors.otp && (
                        <div className="text-red-700 w-full">{errors.otp}</div>
                      )}
                      <i
                        className="absolute text-xs right-4 top-11 cursor-pointer"
                        onClick={togglepassVisibility}>
                        {showPassword ? <i className=" fa-solid fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                      </i>
                    </div> */}



                    <div className=" flex justify-center items-center w-full gap-3 px-2 mt-10">
                      <button
                        className="disabled:opacity-50 disabled:pointer-events-none bg-orange-600 justify-center items-center rounded-lg px-6 py-1 text-white font-bold mb-6 hover:scale-95 duration-300 hover:shadow-xl max-md:w-[75%] max-sm:w-[75%]"
                        type="submit"

                      >
                        {lang === "hi" ? SignInJson?.buttons?.button_two?.en : SignInJson?.buttons?.button_two?.hi}
                      </button>
                    </div>
                  </div>

                  {/* <div>
                    <button type="button" onClick={handleLoginFacebook}>FaceBook Login</button>
                  </div> */}

                  {/* <div>
                    <ReactFacebookLogin
                      appId="894872792672708"
                      callback={responseFaceBook}
                      fields="email,password,picture"
                      autoLoad={true}
                    />
                  </div> */}
                  {/* 
                  {values.token ? (
                    
                  ) : null} */}


                  {/* <div className="px-2  ml-24 max-sm:ml-0 mt-10">

                    <button
                      type="submit"
                      className="border-2  border-orange-600 top-6 right-0 rounded-lg px-4 py-1"
                    >
                      {lang === "hi" ? SignInJson?.buttons?.button_one?.en : SignInJson?.buttons?.button_one?.hi}
                    </button>

                  </div> */}


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
