// import React, { useEffect, useState } from 'react'
// import { errorMessage } from '../../Utils/notificationManger'
// import axios from 'axios'
import WhyJson from "./WhyChooseWe.json"

const WhyChoose = () => {

  const lang = JSON.parse(localStorage.getItem("lang"))
  

  return (
    <section className="font-akshar flex flex-col justify-center items-center">
      <div className="flex justify-center items-center flex-col w-[87%] max-sm:w-full max-md:w-full" >
        <div className="text-orange-600  text-2xl max-sm:text-xl font-bold uppercase  mt-5 max-md:mt-10" data-aos="zoom-in">
          {lang === "hi" ? WhyJson?.title?.en : WhyJson?.title?.hi}
        </div>
        <div className="text-black max-sm:text-center text-4xl max-sm:text-2xl font-bold uppercase  max-md:max-w-full max-md:text-4xl" data-aos="zoom-in">
          {lang === "hi" ? WhyJson?.subtitle?.en : WhyJson?.subtitle?.hi}
        </div>

      </div>  
      <div className="px-10  w-[87%] max-sm:w-full max-md:w-full">
        <div className="gap-5 flex max-md:flex-col p-2 max-md:gap-0 flex-col w-full max-sm:m-0 max-sm:p-0">
          <div className="flex w-full justify-center gap-20  max-sm:gap-5 items-center max-lg:flex-wrap max-md:w-full max-md:flex-col  max-sm:flex-col">
            <div className="flex justify-center items-center flex-col" data-aos="flip-right">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/de75ef96021cc73f2e30ed436d8c73b431270172def85655cd4be51ae9903915?apiKey=8478572201cf4b3f8647adfe69018175&"
                className="aspect-square object-contain mt-3 object-center w-[150px] overflow-hidden self-center max-w-full"
                alt=""
              />
              <div className="text-orange-600 text-center text-3xl font-bold uppercase self-center whitespace-nowrap mt-8">
                {lang === "hi" ? WhyJson?.['item-title-one']?.en : WhyJson?.['item-title-one']?.hi}
              </div>
              <div className="text-zinc-800 text-center font-normal mt-8 max-sm:mx-0">
                {lang === "hi" ? WhyJson?.['desc-one']?.en : WhyJson?.['desc-one']?.hi}
              </div>
            </div>

            <div className="flex justify-center items-center flex-col max-md:mt-10" data-aos="flip-right">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4cc623a2aa98f6338939d5b52752138627ba0b5d3f8a9ebbc2847463608b31ab?apiKey=8478572201cf4b3f8647adfe69018175&"
                className="aspect-square object-contain  mt-3 object-center w-[150px] overflow-hidden self-center max-w-full"
                alt=""
              />
              <div className="text-orange-600 text-center text-3xl font-bold uppercase self-center whitespace-nowrap mt-8">
                {lang === "hi" ? WhyJson?.['item-title-two']?.en : WhyJson?.['item-title-two']?.hi}
              </div>
              <div className="text-zinc-800 text-center  font-normal  mt-8 max-sm:mx-0">
                {lang === "hi" ? WhyJson?.['desc-two']?.en : WhyJson?.['desc-two']?.hi}
              </div>
            </div>

            <div className="flex justify-center items-center flex-col max-md:mt-10" data-aos="flip-right">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c38e9f17747e75f2cfb4e03334593236cb614bf3d6704b4b64b87005a2debcad?apiKey=8478572201cf4b3f8647adfe69018175&"
                className="aspect-square object-contain  mt-3 object-center w-[150px] overflow-hidden self-center max-w-full"
                alt=""
              />
              <div className="text-orange-600 text-center text-3xl font-bold uppercase self-center whitespace-nowrap mt-8">
                {lang === "hi" ? WhyJson?.['item-title-three']?.en : WhyJson?.['item-title-three']?.hi}
              </div>
              <div className="text-zinc-800 text-center  font-normal mt-8 max-sm:mx-0">
                {lang === "hi" ? WhyJson?.['desc-three']?.en : WhyJson?.['desc-three']?.hi}
              </div>
            </div>
          </div>

          <div className='flex justify-center items-center flex-col gap-4 max-sm:w-full max-sm:mx-0 w-full'>
            <p className='text-center font-normal '>{lang === "hi" ? WhyJson?.['slug-one']?.en : WhyJson?.['slug-one']?.hi}</p>

            <b>{lang === "hi" ? WhyJson?.['slug-two']?.en : WhyJson?.['slug-two']?.hi}</b>
          </div>

        </div>
      </div>
    </section>
  )
}

export default WhyChoose