import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Utils/Loader";
import { useNavigate } from "react-router-dom";
// import { errorMessage } from "../../Utils/notificationManger";
import Blog from "./data.json"

const paraStyles = {
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

const Ourblog = () => {
  const [blog, setBlog] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()


  const lang = JSON.parse(localStorage.getItem("lang"))
  


  //  -------{  API for blog section  }---------//

  const GetBlogData = async () => {
    try {
      setLoader(true)
      const res = await axios.get(
        "https://devkirpa.com/admin/api/blog-listing"
      );
      if (Array.isArray(res.data.list)) {
        setBlog(res.data.list);
        setIsOpen(new Array(res.data.list.length).fill(false));
      } else {
        console.log("Blog data is not an array:", res.data.list);
      }

      setBlog(res.data.list);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log("Api error:", error);
    }
  };

  useEffect(() => {
    GetBlogData();
  }, []);

  // Read more and read less button functionality
  const handleOpen = (index) => {
    setIsOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  if (!blog) {
    return (
      <div className="loader">
        <div>
          <img src={Loader} alt="" />
        </div>
      </div>
    );
  } else {
    return (
      <section className=" flex w-full px-5 pt-10 max-sm:p-0 flex-col justify-center items-center font-akshar  text-black">
        <div className="flex w-full flex-col justify-center items-center max-sm:w-full  max-md:w-full max-md:my-10">
          <div className="text-orange-500 pt-10 text-center text-2xl font-bold uppercase whitespace-nowrap" data-aos="zoom-out">
            {lang === "hi" ? Blog?.title?.en : Blog?.title?.hi}
          </div>
          <div className="flex justify-center items-center text-black text-center text-4xl font-bold uppercase w-[100%] max-md:max-w-full max-md:text-4xl max-md:mb-10 max-sm:text-2xl" data-aos="zoom-in">
            {lang === "hi" ? Blog?.subtitle?.en : Blog?.subtitle?.hi}
          </div>

          <div className="flex w-full justify-center items-center max-sm:p-0 max-md:p-0">
            <div className="grid grid-cols-3 gap-3 w-full justify-center items-center p-2 max-sm:grid-cols-1 max-sm:p-0 max-lg:grid-cols-2">
              <div

                className=" flex flex-col w-full max-sm:p-0 justify-start items-start p-2  gap-2 hover:scale-95 duration-300 cursor-pointer"
                data-aos="flip-right"
                onClick={() => navigate('/ourblog/1')}
              >
                <div className="flex w-full h-96 justify-start items-center ">
                  <img
                    loading="lazy"
                    src="/Image1.jpg"
                    className="object-cover object-center  w-full h-full "
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2 mt-10">
                  <div className=" text-xl font-bold capitalize line-clamp-2">
                    {lang === "hi" ? Blog?.["title-one"]?.en : Blog?.["title-one"]?.hi}
                  </div>
                  <p className={`text-black font-thin `}>
                    <span className="firstLetter line-clamp-2">
                      {lang === "hi" ? Blog?.["desc-one"]?.en : Blog?.["desc-one"]?.hi}
                    </span>
                    <br />
                    {/* {item.description.length >= 120 && (
      <span
        className="font-bold text-orange-600 cursor-pointer"
        onClick={() => handleOpen(index)}
      >
        {isOpen === index ? "read less..." : "read more...."}
      </span>
    )} */}

                  </p>
                </div>
              </div>


              <div

                className=" flex flex-col  justify-start items-start p-2 cursor-pointer gap-2"
                data-aos="flip-right"
                onClick={() => navigate('/ourblog/2')}
              >
                <div className="flex w-full h-96 justify-start items-center">
                  <img
                    loading="lazy"
                    src="/Image2.jpg"
                    className="object-cover object-center  w-full h-full overflow-hidden shrink-0 grow flex-1 max-sm:w-[75%]  "
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2 mt-10">
                  <div className=" text-xl font-bold capitalize line-clamp-2">
                    {lang === "hi" ? Blog?.["title-two"]?.en : Blog?.["title-two"]?.hi}
                  </div>
                  <p className={`text-black font-thin `}>
                    <span className="firstLetter line-clamp-2">
                      {lang === "hi" ? Blog?.["desc-two"]?.en : Blog?.["desc-two"]?.hi}
                    </span>
                    <br />
                    {/* {item.description.length >= 120 && (
      <span
        className="font-bold text-orange-600 cursor-pointer"
        onClick={() => handleOpen(index)}
      >
        {isOpen === index ? "read less..." : "read more...."}
      </span>
    )} */}

                  </p>
                </div>
              </div>

              <div

                className=" flex flex-col  justify-start items-start p-2  gap-2 cursor-pointer"
                data-aos="flip-right"
                onClick={() => navigate('/ourblog/3')}
              >
                <div className="flex w-full h-96 justify-start items-center">
                  <img
                    loading="lazy"
                    src="/Image3.jpg"
                    className="object-cover object-center  w-full h-full overflow-hidden shrink-0 grow flex-1 max-sm:w-[75%]  "
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-2 mt-10">
                  <div className=" text-xl font-bold capitalize line-clamp-2">
                    {lang === "hi" ? Blog?.["title-three"]?.en : Blog?.["title-three"]?.hi}
                  </div>
                  <p className={`text-black font-thin `}>
                    <span className="firstLetter line-clamp-2">
                      {lang === "hi"? Blog?.["desc-three"]?.en : Blog?.["desc-three"]?.hi}

                    </span>
                    <br />
                    {/* {item.description.length >= 120 && (
      <span
        className="font-bold text-orange-600 cursor-pointer"
        onClick={() => handleOpen(index)}
      >
        {isOpen === index ? "read less..." : "read more...."}
      </span>
    )} */}

                  </p>
                </div>
              </div>

            </div>


          </div>
          {/* <div className=" mt-10 max-md:max-w-full max-md:mt-10 max-sm:mt-2 mx-20">
            {
              loader ? <Loader /> : <div className="gap-4 grid grid-cols-4 max-md:items-stretch max-md:gap-0 w-full" >
                {blog.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className=" flex flex-col  justify-start items-start p-2  gap-2"
                      data-aos="flip-right"
                    >
                      <div className="flex w-full h-72 justify-start items-center">
                        <img
                          loading="lazy"
                          srcSet={item.blog_imag}
                          className="object-cover object-center  w-full h-full overflow-hidden shrink-0 grow flex-1 max-sm:w-[75%]  "
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className=" text-xl font-bold capitalize">
                          {item.title}
                      
                        </div>
                        <p className={`text-black font-thin `}>
                          <span style={isOpen === index ? null : paraStyles} className="firstLetter">
                            {item.description}
                          </span>
                          <br />
                          {item.description.length >= 120 && (
                            <span
                              className="font-bold text-orange-600 cursor-pointer"
                              onClick={() => handleOpen(index)}
                            >
                              {isOpen === index ? "read less..." : "read more...."}
                            </span>
                          )}

                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            }
          </div> */}
        </div>
      </section>
    );
  }
};

export default Ourblog;
