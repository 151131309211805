import React, { useState, useEffect } from "react";
import axios from "axios";
// import { errorMessage } from "../../Utils/notificationManger";
import OurClint from "./OurClientHappy.json"

const OurClientsHappy = () => {
  //  -----------{  Api for the services in the Services section   }-----------//

  const [feedBack, setFeedBack] = useState([]);

  const lang = JSON.parse(localStorage.getItem("lang"))
 

  const GetFeedBackData = async () => {
    try {
      const res = await axios.get(
        "https://devkirpa.com/admin/api/testimonial-data"
      );
      if (Array.isArray(res.data.list)) {
        setFeedBack(res.data.list);
      } else {
        console.log("service data is not an array:", res.data.list);
      }

      setFeedBack(res.data.list);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  
  useEffect(() => {
    GetFeedBackData();
  }, []);

  return (
    <section className="  flex w-full my-10 py-10 flex-col justify-center items-center max-md:m-0 max-md:p-0 font-akshar">
      <div
        className="relative back-color flex px-10 py-10 w-[100%] justify-center text-orange-600 text-center text-4xl font-bold uppercase  max-md:text-4xl max-md:mb-10 max-md:m-0 max-md:p-5 max-sm:h-96"
        style={{ height: "350px" }}
      >
        <p className="w-[75%] max-sm:w-full text-8xl max-sm:text-4xl max-lg:text-6xl max-lg:w-[95%]" data-aos="zoom-in">{lang === "hi" ? OurClint?.title?.en : OurClint?.title?.hi}</p>
        <div className="absolute max-sm:hidden" style={{ bottom: "-180px" }} data-aos="fade-down">
          <svg
            width="16"
            height="247"
            viewBox="0 0 16 347"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            position="absolute"
            bottom="20"
          >
            <path
              d="M7.29288 346.557C7.6834 346.947 8.31657 346.947 8.70709 346.557L15.0711 340.193C15.4616 339.802 15.4616 339.169 15.0711 338.779C14.6805 338.388 14.0474 338.388 13.6568 338.779L7.99998 344.435L2.34313 338.779C1.95261 338.388 1.31944 338.388 0.928917 338.779C0.538393 339.169 0.538393 339.802 0.928917 340.193L7.29288 346.557ZM7 0.849609L6.99998 345.85L8.99998 345.85L9 0.849609L7 0.849609Z"
              fill="#F15A24"
            />
          </svg>  
        </div>
      </div>

      <div className="w-[60%] max-md:w-full max-md:flex-col max-sm:-mt-60 max-sm:w-full max-sm:py-2 max-sm:m-0 gap-5 flex px-2 py-10 mt-10 justify-center items-center max-sm:flex-col" data-aos="flip-right">
        {feedBack.map((items, index) => {
          return (
            <div
              key={index}
              className="w-[70%]  my-10 gap-2 flex justify-center items-center flex-col max-md: m-0 max-sm:m-0 max-sm:w-full p-2 "
            >
              <div className="flex justify-center items-center w-[100%]">
                <img
                  loading="lazy"
                  srcSet={items.image}
                  className="w-full h-96 object-cover"
                  alt=""
                />
              </div>
              <div className="justify-center gap-2  flex  flex-col max-md:max-w-full">

                <p className="comment text-black max-md:max-w-full font-normal">
                  {lang === "hi" ? items?.comment?.en : items?.comment?.hi}
                </p>

                <p className="font-semibold text-orange-600 text-2xl capitalize max-md:max-w-full max-md:text-xl">
                  {lang === "hi" ? items?.name?.en : items?.name?.hi}
                </p>

              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurClientsHappy;
