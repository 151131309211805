import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Include/Header";
import Navbar from "../Include/Navbar";
import Footer from "../Include/Footer";
import { errorMessage } from "../../Utils/notificationManger";


const About = () => {
  // ----------------{  API for the about section data  }-------------//

  const [aboutUsData, setAboutUsData] = useState([]);
  const lang = JSON.parse(localStorage.getItem("lang"))

  const GetAboutSecData = async () => {
    try {
      const res = await axios.get(
        "https://devkirpa.com/admin/api/about-us"
      );
      if (Array.isArray(res.data.list)) {
        setAboutUsData(res.data.list);
      } else {
        console.log("aboutusData data is not an array:", res.data.list);
      }

      setAboutUsData(res.data.list);
    } catch (error) {
      errorMessage(error?.response?.data?.message)
      console.log("Api error:", error);
    }
  };

  useEffect(() => {
    GetAboutSecData();
  }, []);

  return (
    <section className="bgColor flex w-[100%] py-5 flex-col ">
      <Header/>
      <Navbar/>
      {aboutUsData.map((items) => {
        return (
          <div
            key={items.id}
            className=" max-w-[100%] py-10 my-10 flex-col  max-md:max-w-full max-md:my-10 aboutImage_res"
          >
            <div
              className="flex w-[90%] justify-center items-start max-md:max-w-full max-md:flex-wrap aboutGap_res"
              
            >
              <img
                loading="lazy"
                srcSet={items.image}
                className="object-cover object-center overflow-hidden max-w-[35%] grow basis-[0%] max-md:max-w-[50%]"
                style={{ height: "250px" }}
                alt="about_image"
              />
              <div className="flex w-[30%] justify-start  flex-col max-md:max-w-full">
                <h1 className="text-red-600 text-sm mb-2 font-bold text-3xl uppercase max-md:max-w-full max-sm:text-l">
                  About
                </h1>
                <p className="text-black text-2xl font-bold uppercase mt-5 max-md:max-w-full max-md:text-4xl max-md:leading-[55px] max-sm:text-sm " >
                  {lang === "hi" ? items.title?.en : items?.title?.hi}
                </p>
                <div className="font-bold text-4xl leading-10 uppercase grow shrink basis-auto max-md:max-w-full aboutTitle_res">
                {lang === "hi" ? items.description?.en : items?.description?.hi}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Footer/>
    </section>
  );
};

export default About;
