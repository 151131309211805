import React, { useEffect, useState } from 'react'
import Header from '../Include/Header'
import Navbar from '../Include/Navbar'
import Footer from '../Include/Footer'
import { errorMessage, successMessage } from '../../Utils/notificationManger'
import axios from 'axios'
import Loader from '../../Utils/Loader'
// import { cashfree } from '../Payment/Utils/Util'
import cartJson from "./CartPage.json"
import { MdDelete } from "react-icons/md";
import { useNavigate } from 'react-router-dom'


const Cart = () => {
  const [cartList, setCartList] = useState([])
  const user = JSON.parse(localStorage.getItem("user_id"))
  const [loading, setLoading] = useState(false)
  const [translations, setTranslations] = useState({});
  const lang = JSON.parse(localStorage.getItem("lang"))
  const [productId, setProductId] = useState([])
  const [totalBillAmount, setTotalBillAmount] = useState("")
  const navigate = useNavigate()
  // `https://api.mymemory.translated.net/get?q=${res.data.data[questionNumber - 1].question}&langpair=${translateFrom}|${translateTo}`,

  // https://api.mymemory.translated.net/get?q=Hello%20World!&langpair=en|te

  const user_profile = JSON.parse(localStorage.getItem("profile_status"))
  const user_id = JSON.parse(localStorage.getItem("user_id"))

  const translateFrom = "en"
  const translateTo = "hi"
  const fetchCartItems = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`https://devkirpa.com/admin/api/cart-item?user_id=${user}&type=add_to_cart`)
      const data = await response?.data
      // console.log(data);
      if(response?.status === 200){
        setCartList(data?.list);
      } else {
        setCartList([])
      }
     

      let arr = []

      data?.list?.map((item, i) => arr.push(item?.product_id))


      setProductId(arr)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorMessage(error?.response?.data?.message)
      setCartList([])
    }
  }

  const deleteCartItem = async (_id) => {
    try {
      setLoading(true)
      const response = await axios.get(`https://devkirpa.com/admin/api/remove-cart-item?cart_id=${_id}`)
      const data = await response?.data
      successMessage(data?.message);
      fetchCartItems()

      setLoading(false)
    } catch (error) {
      setLoading(false)

      errorMessage(error?.response?.data?.message)
    }
  }

  const fetchTranslation = async (text) => {
    if (translateTo === "hi") {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${translateFrom}|${translateTo}`);
        const translatedText = response?.data?.responseData?.translatedText;
        setTranslations((prevTranslations) => ({
          ...prevTranslations,
          [text]: translatedText,
        }));
      } catch (error) {
        console.error('Error fetching translation:', error);
      }
    }
  };

  const handleRedirect = async (e) => {
    e.preventDefault()

    // if(cartList?.length > 1){
    //   errorMessage("Accepts only 1 Pooja")
    //   return
    // }

    if (!user_id) {
      navigate("/login")
    } else if (user_profile === "Not Completed") {
      navigate("/profileDetails")
    } else {
      try {

        // const productIds = cartList.map((item) => item.product_id);
        // console.log(`https://devkirpa.com/admin/api/cashfree-payment?user_id=${user}&${cartList.map(id => `productIds[]=${id?.product_id}`).join('&')}&amount=${totalBillAmount}`);

        // const formData = new FormData()
        // formData.append("user_id", user_id)
        // formData.append("date[]", cartList?.date)
        // formData.append("time[]", cartList?.time)

        // cartList?.forEach(charity => {
        //   formData.append("package_id[]", charity?.package_id)
        // })
        // cartList?.forEach(charity => {
        //   formData.append("puja_id[]", charity?.puja_id)
        // })
        // cartList?.forEach(charity => {
        //   formData.append("date[]", charity?.date)
        // })
        // cartList?.forEach(charity => {
        //   formData.append("time[]", charity?.time)
        // })
        // formData.append("package_id", selectedPlan)
        // formData.append("puja_id", props.pujaDetails.id)
        // formData.append("date", selectedDate)
        // formData.append("time", selectedTime)

        // let booking_ids = 0

        // await axios.post("https://devkirpa.com/admin/api/booking", formData)
        //   .then((res) => {
        //     // successMessage(res?.data?.message)
        //     // successMessage(res?.data?.booking_id)
        //     booking_ids += res?.data?.booking_id
        //   })

        //   console.log(booking_ids);
        // const formData = new FormData() 
        const formData = new FormData()
        formData.append("user_id", user_id)
        formData.append("package_id", cartList[0]?.package_id)
        formData.append("puja_id", cartList[0]?.puja_id)
        formData.append("date", cartList[0]?.date)
        formData.append("time", cartList[0]?.time)
        let booking_ids = 0
        await axios.post("https://devkirpa.com/admin/api/booking", formData)
          .then((res) => {
            // successMessage(res?.data?.message)
            // successMessage(res?.data?.booking_id)
            booking_ids += res?.data?.booking_id
            successMessage(res?.data?.message)
          })

        const response = await axios.get(`https://devkirpa.com/admin/api/cashfree-payment?user_id=${user}&${cartList.map(id => `productIds[]=${id?.product_id}`).join('&')}&amount=${totalBillAmount}`)
        const data = await response?.data

        const link = data[0]?.redirectionLink



        // window.open(link, "_blank")
        window.location.href = link;
        // window.location.href = "/myBooking";
      } catch (error) {
        errorMessage(error?.response?.data?.message || error?.response?.data?.error)
      }
    }





    // let checkoutOptions = {
    //   paymentSessionId: "payment-session-id",
    //   returnUrl: "http://localhost:3000",

    // }
    // cashfree.checkout(checkoutOptions).then(function (result) {
    //   if (result.error) {
    //     alert(result.error.message)
    //   }
    //   if (result.redirect) {
    //     console.log("Redirection")
    //   }
    // });
  }

  function calculateTotalPrice(item) {
    let packagePrice = parseFloat(item.packagePrice);
    if (isNaN(packagePrice)) {
      packagePrice = 0
    }
    const charityTotal = item.charityPrices.reduce((total, price) => total + parseFloat(price), 0);
    const totalPrice = packagePrice + charityTotal;
    return totalPrice.toFixed(2); // Assuming you want to display the total price with 2 decimal places
  };

  const calculateTotalBill = () => {
    let totalBill = 0;
    cartList.forEach((item) => {
      totalBill += parseFloat(calculateTotalPrice(item));
    });
    setTotalBillAmount(totalBill?.toFixed(2))
    // Assuming you want to display the total bill with 2 decimal places
  };

  const handleDeleteCharity = async (cart_id, charity_id) => {
    try {
      const response = await axios.get(`https://devkirpa.com/admin/api/remove-cart-charity-item?cart_id=${cart_id}&charity_id=${charity_id}`)
      const data = await response?.data
      successMessage(data?.message)
      fetchCartItems()
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    }
  }


  useEffect(() => {
    fetchCartItems()

  }, [])

  useEffect(() => {
    calculateTotalBill()
  }, [cartList])
  return (
    <div className='font-poppins'>
      <Header />
      <Navbar />
      <div className='p-2 flex flex-col gap-2 font-semibold w-[87%] mx-24 max-sm:mx-5'>
        <div className='text-2xl ml-2 mt-2'>
          <h1>{lang === "hi" ? cartJson?.title?.en : cartJson?.title?.hi}</h1>
        </div>
        {
          loading ? <Loader /> : <>
            <div className='w-full flex justify-center items-center flex-col gap-2 mt-10'>
              {
                cartList?.length === 0 && <div className='text-center text-2xl animate-pulse text-red-500'> <h1 className='p-2'>{lang === "hi" ? cartJson?.notFound?.en : cartJson?.notFound?.hi}</h1> </div>
              }
              {
                cartList?.map((item, index) => {
                  const totalItemPrice = calculateTotalPrice(item);
                  return (
                    <div className=' shadow p-3 flex flex-col gap-2 rounded-lg w-full '>
                      {
                        item?.pujaName && <div className='text-2xl flex justify-between items-center '>
                          <h1 className='p-2 text-orange-400'>{
                            <h1 className='p-2'>{lang === "hi" ? item?.pujaName?.en : item?.pujaName?.hi}</h1>
                          }</h1>
                          <p className='text-green-600 text-lg'>{item?.status?.toLowerCase()}</p>
                        </div>
                      }
                      <div className='w-full flex items-center justify-start'>
                        <img src="/Logo.jpg" alt="logo" className='w-14 h-14 object-cover' />
                        <p className='text-orange-700 font-bold'>{lang === "hi" ? cartJson?.devkirpa?.en : cartJson?.devkirpa?.hi}:</p>
                      </div>
                      <div className='flex justify-between items-center gap-2'>
                        <div className='flex gap-3'>
                          {item?.packageName && <h1 className='px-3 py-2 text-sm rounded-full border-2 border-orange-400 bg-[#FFF8F1] max-sm:p-0'>{lang === "hi" ? item?.packageName?.en : item?.packageName?.hi}</h1>}
                          {item?.pujaDuration && <h1 className='p-2'>{lang === "hi" ? item?.pujaDuration?.en : item?.pujaDuration?.hi}</h1>}
                        </div>
                        <div className='flex gap-3'>

                          {item?.packagePrice && <h1 className=' text-2xl'>&#x20B9; {item?.packagePrice}</h1>}
                        </div>

                      </div>
                      <div className='flex justify-between items-center gap-2'>
                        <div>
                          {item?.charityNames?.map((el, i) => {
                            return (
                              <div key={i}>
                                <p className='capitalize mx-2 text-orange-500'>{lang === "hi" ? el?.en : el?.hi}</p>
                              </div>
                            )
                          })}
                        </div>
                        <div className='flex justify-center items-center gap-3'>
                          <div>
                            {item?.charityPrices?.map((el, i) => {
                              return (
                                <div key={i}>
                                  <p className='capitalize'> &#x20B9; {el}</p>
                                </div>
                              )
                            })}
                          </div>
                          <div>
                            {item?.charity_ids?.map((el, i) => {
                              return (
                                <div key={i}>
                                  <button className='capitalize px-2 py-2 bg-red-600 rounded-full text-white' onClick={() => handleDeleteCharity(item?.id, el)}> <MdDelete /></button>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className='flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-2 w-full ml-3'>
                          <p className='font-semibold'>{lang === "hi" ? cartJson?.total_price?.en : cartJson?.total_price?.hi}:</p>
                          <p className='font-semibold'>&#x20B9; {totalItemPrice}</p>
                        </div>
                        <div className='w-full '>
                          <p className='float-end p-2 bg-red-500 rounded-full text-white text-center shadow-sm cursor-pointer' onClick={() => deleteCartItem(item?.id)}>{lang === "hi" ? cartJson?.buttons?.delete?.en : cartJson?.buttons?.delete?.hi}</p>
                        </div>
                      </div>


                    </div>
                  )
                })
              }


            </div>
            <div className='w-full flex justify-start items-end flex-col gap-2'>
              <p className='w-48'><b>{lang === "hi" ? cartJson?.total_price?.en : cartJson?.total_price?.hi}: </b> {totalBillAmount} </p>
              <button className="bg-[#F15A24] font-bold  py-1 px-10 text-light float-end" onClick={handleRedirect}>{lang === "hi" ? cartJson?.buttons?.['make-payament']?.en : cartJson?.buttons?.['make-payament']?.hi}</button>
            </div>
          </>
        }
      </div>
      <Footer />
    </div>
  )
}

export default Cart