import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../Include/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Include/Header";
import YourImage from "../Images/IMAGE (1).jpg";
import Footer from "../Include/Footer";
import AdminLogo from "../Images/user.png";
import { Link } from "react-router-dom";
import photo_icon from "../Images/Date.png";
import Loader from "../Images/Dev Kirpa Loader.gif";
import { useAuth } from "../Context/AuthContext";
import ProfileJson from "./Profile.json"
import { isObject } from "formik";
import { errorMessage, successMessage } from "../../Utils/notificationManger";
import { convertDateToNumberFormat } from "../Constant/Function";
import Metadata from "../../Meta/Metadata";

function ProfileDetails() {
  const { dispatch } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [profile, setProfile] = useState();
  const [formSubmitted, setFormSubmitted] = useState();
  const id = localStorage.getItem("user_id");
  const [details, setDetails] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    profile_pic: "",
    user_id: id,
    gotra: ""
  });
  const user_id = JSON.parse(localStorage.getItem("user_id"))
  const navigate = useNavigate();
  const location = useLocation()
  const lang = JSON.parse(localStorage.getItem("lang"))

  console.log(location, "-------location from pooja detail");

  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem("user_id"))

    axios
      .get(
        "https://devkirpa.com/admin/api/web-edit-profile?user_id=" +
        user_id
      )
      .then((res) => {
        const updatedUserData = {
          ...details,
          name: res.data.user.name,
          email: res.data.user.email,
          address: res.data.user.address,
          city: res.data.user.city,
          phone: res.data.user.phone,
          profile_pic: res.data.user.profile_pic,
          gotra: res?.data?.user?.gotra ?? ""
        };
        setDetails(updatedUserData);
        if (res.data.user) {
          setProfile(res.data.user.profile_pic);
          localStorage.setItem("user_profile", JSON.stringify(res.data.user)); // Save profile pic URL to localStorage

        }
      });
  }, []);

  console.log(details);

  const handleSubmitForm = async (details) => {
    try {

      if (!details?.name) {
        errorMessage("Name Required")
        return
      }

      if (!details?.email) {
        errorMessage("Email Required")
        return
      }
      if (!details?.gotra) {
        errorMessage("Gotra Required")
        return
      }
      if (!details?.phone) {
        errorMessage("Phone Required")
        return
      }
      if (!details?.city) {
        errorMessage("City Required")
        return
      }
      if (!details?.address) {
        errorMessage("Address Required")
        return
      }


      const formData = new FormData();

      formData.append("name", isObject(details?.name) ? details?.name?.en : details?.name)
      formData.append("phone", details?.phone)
      formData.append("email", details?.email)
      formData.append("address", isObject(details?.address) ? details?.address?.en : details?.address)
      formData.append("city", isObject(details?.city) ? details?.city?.en : details?.city)
      formData.append("gotra", isObject(details?.gotra) ? details?.gotra?.en : details?.gotra)
      formData.append("user_id", id)



      // Append all form fields
      // Object.keys(details).forEach((key) => {
      //   formData.append(key, details[key]);
      // });

      // Append the profile picture
      if (fileInputRef.current.files[0]) {
        formData.append("profile_pic", fileInputRef.current.files[0]);
      }

      const res = await axios.post(
        "https://devkirpa.com/admin/api/update-user-profile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage("Form submitted successfully");


      if (location?.state?.payloadi) {
        try {

          // location?.state?.payloadi?.gotra.forEach(member => {
          //   member.gotra = res?.data?.gotra?.en;
          // });


          // const formData = new FormData()
          // formData.append("cart_id", location?.state?.payload?.booking_id)
          // formData.append("memberDetails", JSON.stringify(formFields))

          const response = await axios.post("https://devkirpa.com/admin/api/couple-details", location?.state?.payloadi, { headers: { 'Content-Type': 'application/json' } })
          const data = await response?.data
          if (response?.status === 200) {
            successMessage("Members created Successfully" || data?.message)
            return navigate("/order-confirmation", { state: { booking_id: location?.state?.payload?.booking_id } })
          }
        } catch (error) {
          return errorMessage(error?.response?.data?.message)
        }
      }

      // window.alert("Profile Form submitted successfully");
      // console.log("Profile Details submitted successfully:", details);
      if (location?.state) {
        // navigate(`${location?.state?.path}`, { state: { profilePath: location?.pathname } })
        const newPayload = {
          user_id: location?.state?.payloadSecure?.user_id ?? user_id,
          package_id: location?.state?.payloadSecure?.packageId ?? location?.state?.payloadSecure?.package_id,
          puja_id: location?.state?.payloadSecure?.puja_id,
          date: location?.state?.payloadSecure?.date
        }
        console.log(newPayload, "newpayload");

        let booking_ids = 0

        await axios.post('https://devkirpa.com/admin/api/book-now', newPayload)
          .then((res) => {
            console.log(res.data)
            booking_ids += res?.data?.cartid

            // navigate(`/order-confirmation`, { state: { booking_id: booking_ids, path: location.pathname } })
          }).catch((error) => {
            console.error('Error adding booking:', error);
            errorMessage("An error occurred while booking. Error from backend.")

          })

        await axios.post("https://devkirpa.com/admin/api/booking", newPayload)
          .then((res) => {
            // booking_ids += res?.data?.booking_id
          })

        const navigationPayload = {
          packageId: location?.state?.payloadSecure?.packageId,
          pujaPlans: location?.state?.payloadSecure?.pujaPlans,
          pujaDetails: location?.state?.payloadSecure?.pujaDetails,
          booking_id: booking_ids
        }



        details.all_details_fillup = "1"
        localStorage.setItem("user", JSON.stringify(details))
        localStorage.setItem("profile_status", JSON.stringify("Completed"))
        return navigate("/Checkout", { state: { payload: navigationPayload } })


      } else {
        details.all_details_fillup = "1"
        localStorage.setItem("user", JSON.stringify(details))
        localStorage.setItem("profile_status", JSON.stringify("Completed"))
        navigate("/")
      }
    } catch (error) {
      // window.alert("Please fill the reqiured data");
      console.error("Error submitting form:", error);
      errorMessage("Error submitting form, Your entered email already exist!");

    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fileInputRef = React.createRef();

  const handleProfileChange = (e) => {
    const profile = e.target.files[0];

    console.log(profile);

    if (profile) {
      setProfile(URL.createObjectURL(profile));
    }
  };

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    handleSubmitForm(details);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("auth");
    localStorage.clear()
    navigate("/login");
  };

  // console.log(details);

  if (details === 'null') {

    return (
      <div className="loader">
        <div>
          <img src={Loader} alt="" />
        </div>
      </div>
    )
  } else {
    return (
      <>
        <Metadata
          title={`DEVKIRPA | Profile `}
          description="Profile Page"
          url={`https://devkirpa.com/profileDetails}`}
        />
        <section className="my-5">
          <Header />
          <Navbar />
          <form onSubmit={onSubmit}>
            <div className="relative">
              <div
                style={{ height: "auto" }}
                className="relative  w-[100%] flex flex-col justify-center items-center font-bold text-4xl  bg-cover bg-center"
              >
                <div className="absolute text-light">{lang === "hi" ? ProfileJson?.header?.en : ProfileJson?.header?.hi}</div>
                <img src={YourImage} alt="Your Image" />
              </div>
              <div
                className="w-[100%]  bg-light-100 profile_hide-div"
              ></div>
              <div
                className="absolute w-[100%] z-10 flex items-center justify-center profileSec_res"
              >
                <div
                  className="w-[85%]  flex max-sm:flex-col rounded-px bg-light p-6 border-2  "
                  style={{ borderRadius: "10px" }}
                >
                  <div className="w-[35%] border-r-2 max-sm:border-none max-sm:w-[100%]">
                    <div className="w-45 flex flex-col justify-center items-center font-semibold text-3xl gap-3">
                      {profile ? (
                        <>
                          <div className="userProfile">
                            <img
                              className="w-full h-full rounded-full object-center object-cover"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              src={profile}
                              alt=""
                            />
                          </div>
                          <button
                            className="btn btn-info"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => setProfile(null)}
                          >
                            <i className="fa-solid fa-pen-to-square"></i> {lang === "hi" ? ProfileJson?.sidebar?.edit?.en : ProfileJson?.sidebar?.edit?.hi}
                          </button>
                        </>
                      ) : (
                        <div className="relative">
                          <img
                            className="w-50"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            src={AdminLogo}
                            alt=""
                          />
                          <button
                            className="btn border-2 border-blue-400 rounded-full flex justify-center items-center w-5 h-7 absolute bottom-0 left-20 text-blue-400"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => setProfile(null)}
                            type="button"
                          >
                            <i className="fa-solid fa-pen-to-square rounded-full"></i>
                          </button>
                        </div>
                      )}
                      <p>{lang === "hi" ? details?.name?.en : details?.name?.hi}</p>
                    </div>
                    <div className="flex flex-col gap-4 pt-5 px-1 justify-start">
                      <Link to="/ProfileDetails">
                        {" "}
                        <p className="font-semibold max-sm:hidden">{lang === "hi" ? ProfileJson?.sidebar?.profile_details?.en : ProfileJson?.sidebar?.profile_details?.hi}</p>
                      </Link>
                      <Link to="/MyBooking">
                        <p className="font-semibold max-sm:hidden">{lang === "hi" ? ProfileJson?.sidebar?.my_bookings?.en : ProfileJson?.sidebar?.my_bookings?.hi}</p>
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-fit font-semibold text-orange-600 max-sm:hidden"
                      >
                        {lang === "hi" ? ProfileJson?.sidebar?.logout?.en : ProfileJson?.sidebar?.logout?.hi}
                      </button>
                    </div>
                  </div>
                  <div className="w-[65%] max-sm:w-[100%] max-sm:pt-5 flex flex-col justify-center items-center gap-2">
                    <div className="  text-2xl flex flex-col justify-center font-bold ">
                      <p className="max-sm:hidden">{lang === "hi" ? ProfileJson?.sidebar?.profile_details?.en : ProfileJson?.sidebar?.profile_details?.hi}</p>
                      <div className="text-blue-600">{message}</div>

                    </div>
                    <div className="w-[50%] max-sm:w-full">
                      <div className="grid grid-cols-1 gap-4 pt-5">
                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.fullname?.en : ProfileJson?.main?.fullname?.hi}
                          </label>
                          <input
                            value={lang === "hi" ? details?.name?.en : details?.name?.hi}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2  max-sm:w-[100%]"
                            type="text"
                            name="name"
                            id="name"
                          />
                        </div>

                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.email?.en : ProfileJson?.main?.email?.hi}
                          </label>
                          <input
                            value={details.email}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2  max-sm:w-[100%]"
                            type="email"
                            name="email"
                            id="email"
                          />
                        </div>

                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.gotra?.en : ProfileJson?.main?.gotra?.hi}
                          </label>
                          <input
                            value={lang === "hi" ? details.gotra?.en : details?.gotra?.hi}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2  max-sm:w-[100%]"
                            type="text"
                            name="gotra"
                            id="phone"
                          // readOnly
                          />
                        </div>

                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.phone?.en : ProfileJson?.main?.phone?.hi}
                          </label>
                          <input
                            value={details.phone}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2  max-sm:w-[100%]"
                            type="text"
                            name="phone"
                            id="phone"
                            readOnly
                          />
                        </div>

                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.city?.en : ProfileJson?.main?.city?.hi}
                          </label>
                          <input
                            value={lang === "hi" ? details.city?.en : details?.city?.hi}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2  max-sm:w-[100%]"
                            type="text"
                            name="city"
                            id="city"
                          />
                        </div>

                        <div className="flex flex-col w-[100%] gap-1 profile_input">
                          <label className="w-full text-xs text-black mx-1 ">
                            {lang === "hi" ? ProfileJson?.main?.address?.en : ProfileJson?.main?.address?.hi}
                          </label>
                          <input
                            value={lang === "hi" ? details.address?.en : details?.address?.hi}
                            onChange={handleChange}
                            className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-3  max-sm:w-[100%]"
                            type="text"
                            name="address"
                            id="address"
                          />
                        </div>
                        <div className="profile_input max-sm:w-full bg-orange-600 flex flex-col p-3 text-light font-semibold text-xs rounded w-[100%] gap-1">
                          <button className="max-sm:w-full">{lang === "hi" ? ProfileJson?.button?.en : ProfileJson?.button?.hi}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  {profile ? (
                    <div> </div>
                  ) : (
                    <h5
                      className="modal-title ml-auto font-bold text-xl"
                      id="exampleModalLabel"
                    >
                      {lang === "hi" ? ProfileJson?.modal?.title?.en : ProfileJson?.modal?.title?.hi}
                    </h5>
                  )}

                  {profile ? (
                    <button
                      type="button"
                      className="relative btn btn-primary bg-blue-600 post_button"
                      onClick={onSubmit}
                      accept="image/*"
                      disabled={!profile}
                    >
                      <i className="fa-solid fa-check"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="relative bg-red-600 btn-close text-center flex justify-center items-center"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  )}
                </div>

                <div className="modal-body" style={{ height: "400px" }}>
                  <div className="d-flex gap-3 flex-column align-items-center">
                    <img className="w-25 h-25" src={photo_icon} alt="" />
                    <p className="font-bold">  {lang === "hi" ? ProfileJson?.modal?.["sub-title"]?.en : ProfileJson?.modal?.["sub-title"]?.hi}</p>

                    {profile && (
                      <img
                        className="w-full h-full selected_file"
                        src={profile}
                        alt="Selected Preview"
                      />
                    )}
                    <button
                      type="button"
                      onClick={handleButtonClick}
                      className="bg-blue-600 btn btn-primary mt-2"
                    >
                      {lang === "hi" ? ProfileJson?.modal?.button?.en : ProfileJson?.modal?.button?.hi}
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleProfileChange}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="bg-red-600 btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    {lang === "hi" ? ProfileJson?.modal?.close?.en : ProfileJson?.modal?.close?.hi}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    );
  }
}
export default ProfileDetails;