import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import { errorMessage } from "../../Utils/notificationManger";
import RemediesJSon from "../Remedies/Remedies.json"
import Slider from "react-slick";
import PujaJson from "../AllPujas/Puja.json"

const PujaCard = () => {
  const lang = JSON.parse(localStorage.getItem("lang"))
//   const constLang = JSON.parse(localStorage.getItem("constLang"))
//   const [button, setButton] = useState("")
  //  -----------{  Api for the remediesPuja in the Services section   }-----------//
  const [slidesToShow, setSlidesToShow] = useState();
  const [remediesPuja, setRemediesPuja] = useState([]);
//   const [mainTitle, setMainTitle] = useState("")
const navigate = useNavigate()
  const GetRemediesData = async () => {
    try {
      const res = await axios.get(
        "https://devkirpa.com/admin/api/pooja-all-listing"
      );
      if (Array.isArray(res.data.list)) {
        setRemediesPuja(res.data.list);
        // setSlidesToShow(res?.data?.list?.length)
      } else {
        console.log("service data is not an array:", res.data.list);
      }

      setRemediesPuja(res.data.list);
    } catch (error) {
      console.log("Error:", error);
      setRemediesPuja([])
      errorMessage(error?.response?.data?.error)
    }
  };

//   const getTranslatedText = async (text) => {
//     if (lang !== constLang) {
//       try {
//         const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)
//         // setButton(response?.data?.matches);
//       } catch (error) {
//         errorMessage(error?.response?.data?.message)
//       }
//     }
//   }
//   const getTranslatedMainText = async (text) => {
//     if (lang !== constLang) {
//       try {
//         const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)
//         // setMainTitle(response?.data?.matches);
//       } catch (error) {
//         errorMessage(error?.response?.data?.message)
//       }
//     }
//   }

  useEffect(() => {
    // getTranslatedText("View Detail")
    // getTranslatedMainText("REMEDY PUJAS / DOSHA PUJAS")
  }, [lang])

  useEffect(() => {
    GetRemediesData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        // Extra small (xs)
        setSlidesToShow(1);
      } else if (screenWidth >= 640 && screenWidth < 768) {
        // Small (sm)
        setSlidesToShow(1);
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        // Medium (md)
        setSlidesToShow(2);
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        // Large (lg)
        setSlidesToShow(3);
      } else {
        // Extra large (xl) and above
        setSlidesToShow(4);
      }
    };
  
    // window.addEventListener("resize", handleResize);
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);
  

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  return (
    <section className="flex wfull justify-center items-center flex-col max-sm:mx-0">
      <div className="flex mt-5 w-full flex-col max-md:max-w-full">
        <h1 className="flex justify-center items-center text-orange-500 text-4xl font-semibold uppercase max-md:max-w-full max-md:px-10 max-md:text-2xl">
          {lang === "hi" ? "All Puja" : RemediesJSon?.remedies?.hi}
        </h1>
      </div>

      <div className="w-full px-10 flex justify-center flex-col gap-5 my-5">
        <div className="flex max-sm:flex-col max-lg:flex-col  justify-center items-center px-20  gap-5 font-semibold w-full max-sm:px-2">
          {/* <Slider {...settings}> */}
            {remediesPuja?.slice(0, 4).map((item, index) => {
              return (
                <div key={index} className="w-full border-1 max-sm:w-full shadow-xl h-full rounded-lg" data-aos="flip-right">
                  <div
                    className="flex justify-center items-center"
                    style={{ height: "220px" }}
                  >
                    <img
                      className="object-cover object-center w-full h-full"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      src={item?.image}
                      alt={item?.title}
                    />
                  </div>
                  <div className="flex justify-start flex-col gap-2 m-2 px-3 py-2  max-sm:justify-center">
                    <h1 className={`text-md line-height-2 line-clamp-1 font-mono uppercase text-black pt-2 ${lang==="en" && "pt-2"}`}>{lang === "hi" ? item?.name?.en : item?.name?.hi}</h1>
                    {/* <p className="text-sm description">{item.name}</p> */}
                    <div className="flex items-center font-normal gap-2 w-[100%]">
                      <del
                        className="text-normal text-red-600"
                        style={{ fontWeight: "500" }}
                      >
                        ₹{item.actual_price}
                      </del>
                      <span
                        className="text-green-600 text-xl"
                        style={{ fontWeight: "700" }}
                      >
                        {" "}
                        ₹{item.actual_price}
                      </span>
                    </div>
                    <Link to={"PujaDetails/" + item.id}>
                      <button className="w-[60%] bg-orange-500 p-2 text-white rounded">
                      {lang === "hi" ? "Book Now" : RemediesJSon?.view_detail?.hi}
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}

            
          {/* </Slider> */}
        </div>

        <div className="w-full flex justify-center items-center">
              <button type="button" className={`border-2 px-5 py-2 text-orange-500  border-orange-500 rounded ${slidesToShow == 5 && "hidden" }`} onClick={()=>navigate("/AllPujas")}>{lang === "hi" ? PujaJson?.buttons?.button_three?.en : PujaJson?.buttons?.button_three?.hi}</button> 

              {/* <button type="button"  className={`border-2 px-5 py-2 text-orange-500  border-orange-500 rounded ${slidesToShow !== 5 && "hidden" }`} onClick={()=>setSlidesToShow(remediesPuja?.length)}>{lang === "hi" ? PujaJson?.buttons?.button_four?.en : PujaJson?.buttons?.button_four?.hi}</button> */}
            </div>
      
      </div>
    </section>
  );
};

export default PujaCard;
