import React from 'react'
import Header from '../Include/Header'
import Navbar from '../Include/Navbar'
import Footer from '../Include/Footer'

const NotFound = () => {
  const lang = JSON.parse(localStorage.getItem("lang"))
  return (
    <div>
      <Header />
      <Navbar />
      <div className="h-96 w-full flex justify-center items-center text-red-500 font-bold text-4xl animate-pulse">
        <h1>{lang === "hi" ? "No Data Found" : "कोई  डेटा नहीं मिला"}</h1>
      </div>
      <Footer />
    </div>
  )
}

export default NotFound