import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../Include/Header";
import Navbar from "../../Include/Navbar";
import Footer from "../../Include/Footer";
import PujaInnerSlider from "./PujaInner/PujaInnerSlider";
import PujaDescription from "./PujaDescription";
import FAQ from "../FAQ/FAQ's";
import { useParams } from "react-router-dom";
// import Loader from "../../Images/Dev Kirpa Loader.gif";
import SimilarPujas from "./SimilarPujas";
// import { AuthGuard } from "../../Context/AuthContext";
import Loader from "../../../Utils/Loader";
import Metadata from "../../../Meta/Metadata";



function PujaDetails() {
  const [pujaDetails, setPujaDetails] = useState(null);
  const { pooja_id } = useParams();
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  const GetPujaDetails = async (id) => {
    // setLoader(true)


    try {
      setLoader(true)
      const response = await axios.get("https://devkirpa.com/admin/api/pooja-details-web?pooja_id=" + id)
      if (response?.status == 200) {
        const data = await response?.data
        setPujaDetails(data?.list[0])
        // Send the event to Facebook Pixel once puja details are fetched successfully
        window.fbq('track', 'ViewPujaDetails', {
          pooja_id: id,
          name: data?.list[0]?.name?.en, // You can send more information if needed
        });
         // Send page view event to Google Analytics
         window.gtag("event", "page_view", {
          page_path: window.location.pathname,
          page_title: `${data?.list[0]?.name?.en}`,
          page_location: window.location.href,
        });
      } else {
        throw response
      }
      setLoader(false)
    } catch (error) {
      console.log(error?.response);
      setPujaDetails(null)
      setLoader(false)
    }
    // await axios
    //   .get(
    //     "https://devkirpa.com/admin/api/pooja-details-web?pooja_id=" +
    //     id
    //   )
    //   .then((res) => {
    //     setPujaDetails(res.data.list[0]);
    //   });
    // setLoader(false)
  };

  // useEffect(() => {
  //   console.log("clicked");
  // }, [])



  useEffect(() => {
    // console.log("working");
    GetPujaDetails(pooja_id);
  }, [pooja_id]);

  console.log(pujaDetails);

  return (
    <section className="font-semibold max-sm:text-sm font-poppins">
      <Metadata
        title={`DEVKIRPA | ${pujaDetails?.name?.en}`}
        description="Alleviate the effects of Kaal Sarp Dosh with specialized pujas by DEV KIRPA. Achieve peace and prosperity."
        url={`https://devkirpa.com/AllPujas/PujaDetails/${pooja_id}`}
      />
      {/* <AuthGuard> */}
      <Header />
      <Navbar />
      <div>

        {
          loader ? <Loader /> : <div>
            {
              pujaDetails == null ? <div className="w-full h-96 text-3xl text-red-600 font-semibold flex justify-center items-center animate-pulse">
                <h1>No Data Found</h1>
              </div> : <div>
                <PujaInnerSlider pujaDetails={pujaDetails} />
                <PujaDescription pujaDetails={pujaDetails} />
                <FAQ pujaDetails={pujaDetails} />
                <SimilarPujas />
              </div>
            }

          </div>}
      </div>
      <Footer />
      {/* </AuthGuard> */}
    </section>
  );


  // if (pujaDetails) {
  //   return (
  //     <section className="font-semibold max-sm:text-sm font-poppins">
  //       {/* <AuthGuard> */}
  //       <Header />
  //       <Navbar />
  //       {loader ? <Loader /> : <div>
  //         <PujaInnerSlider pujaDetails={pujaDetails} />
  //         <PujaDescription pujaDetails={pujaDetails} />
  //         <FAQ pujaDetails={pujaDetails} />
  //         <SimilarPujas />
  //       </div>}
  //       <Footer />
  //       {/* </AuthGuard> */}
  //     </section>
  //   );
  // } else {
  //   return (
  //     <div className="loader">
  //       <Loader />
  //     </div>
  //   );
  // }
}

export default PujaDetails

// export default () => (
//   <AuthGuard>
//     <PujaDetails />
//   </AuthGuard>
// );
