import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Include/Navbar";
import Header from "../Include/Header";
import YourImage from "../Images/IMAGE (1).jpg";
import Footer from "../Include/Footer";
import AdminSection from "./AdminSection";
import KalashImg from "../Images/Kalash.png";
import DateImg from "../Images/Date.png";
import GroupImg from "../Images/Group.png";
import TableImg from "../Images/Table.png";
import { errorMessage } from "../../Utils/notificationManger";
import AdminJson from "./Admin.json"
import Loader from "../../Utils/Loader";
import { Pagination, Stack, TablePagination } from "@mui/material";
import Metadata from "../../Meta/Metadata";


function MyBooking() {
  const [showMoreResults, setShowMoreResults] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const user_id = JSON.parse(localStorage.getItem("user_id"))
  const lang = JSON.parse(localStorage.getItem("lang"))
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const location = useLocation()
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  const verifyPayment = async (order_id, payment_id, signature, status, invoice_id) => {
    try {
      const response = await axios.post(
        'https://devkirpa.com/admin/api/payment/razorpay/callback',
        {
          order_id: order_id,
          razorpay_invoice_id: invoice_id,
          razorpay_payment_id: payment_id,
          razorpay_signature: signature,
          razorpay_invoice_status: status
        }
      );
      console.log('Payment verification response:', response.data);
    } catch (error) {
      console.error('Payment verification error:', error);
    }
  };


  // const [currentPage, setCurrentPage] = useState(1)
  // const itemsPerPage = 6


  // // pagination filter count data 

  // const indexOfLastItem = currentPage * itemsPerPage
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage
  // const currentItems = bookingList?.slice(indexOfFirstItem, indexOfLastItem)

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const GetPujaListing = async () => {
    setLoader(true)

    try {
      setLoader(true)
      const response = await axios.get(
        "https://devkirpa.com/admin/api/booking-listing?user_id=" +
        user_id
      )
      if (response.status === 200) {
        setBookingList(response?.data)
      } else {
        setBookingList([])
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      errorMessage(error?.response?.data?.message)
    }

    // await axios
    //   .get(
    //     "https://devkirpa.com/admin/api/booking-listing?user_id=" +
    //     user_id
    //   )
    //   .then((res) => {
    //     // console.log(res?.data);
    //     // console.log([res?.data?.list]);
    //     setBookingList(res?.data);
    //   });
    setLoader(false)
  };

  useEffect(() => {
    GetPujaListing();
    // getUserData()
  }, []);

  const handleNavigateDetailPage = (id, image) => {


    navigate(`/Booking/${id}`, { state: { poojaImage: image } })

  }

  // Show more button functionality

  const handleShowMore = () => {
    setShowMoreResults(!showMoreResults);
  };



  function getStatusColor(status) {
    switch (status) {
      case "Confirmed":
        return "green";
      case "Rejected":
        return "red";
      default:
        return "orange";
    }
  }

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    console.log(queryParams);

    const order_id = queryParams.get('order_id');
    const invoice_id = queryParams.get('razorpay_invoice_id');
    const razorpay_payment_id = queryParams.get('razorpay_payment_id');
    const razorpay_signature = queryParams.get('razorpay_signature');
    const razorpay_invoice_status = queryParams.get('razorpay_invoice_status')

    // console.log(order_id , razorpay_payment_id , razorpay_signature);


    if (order_id && razorpay_payment_id && razorpay_signature) {
      verifyPayment(order_id, razorpay_payment_id, razorpay_signature, razorpay_invoice_status, invoice_id);
    }
  }, [location.search]);

  // useEffect(() => {
  //   const queryParam = new URLSearchParams(location.search);

  //   // Check if all necessary query parameters are present
  //   const hasQueryParam = queryParam.has('order_id') &&
  //     queryParam.has('razorpay_payment_id') &&
  //     queryParam.has('razorpay_invoice_id') &&
  //     queryParam.has('razorpay_invoice_status');

  //   if (hasQueryParam) {
  //     const orderId = queryParam.get('order_id');
  //     const paymentId = queryParam.get('razorpay_payment_id');
  //     const invoiceId = queryParam.get('razorpay_invoice_id');
  //     const signature = queryParam.get('razorpay_signature');
  //     const paymentStatus = queryParam.get('razorpay_invoice_status');

  //     if (orderId && paymentId && invoiceId && signature) {
  //       const payload = {
  //         razorpay_invoice_id: invoiceId,
  //         razorpay_payment_id: paymentId,
  //         razorpay_invoice_status: paymentStatus,
  //         razorpay_signature: signature,
  //       };

  //       const verifyPayment = async () => {
  //         try {
  //           const response = await axios.post(
  //             'https://devkirpa.com/api/payment/razorpay/callback',
  //             payload
  //           );
  //           const data = response.data;
  //           console.log(data?.message || 'Verified payment');
  //         } catch (error) {
  //           console.error('Payment verification failed:', error.message);
  //         }
  //       };

  //       verifyPayment();
  //     }
  //   } else {
  //     console.log('No valid query parameters found.');
  //   }
  // }, [location]);
  console.log(location.search);


  if (bookingList) {
    return (
      <>
        <Metadata
          title={`DEVKIRPA | My Booking `}
          description="Profile Page"
          url={`https://devkirpa.com/MyBooking}`}
        />
        <section
          className="my-5 "
          style={{
            position: "relative",
          }}
        >
          <Header />
          <Navbar />
          <div className="relative flex flex-col flex-grow">
            <div
              style={{ height: "auto" }}
              className="relative  w-[100%]  flex flex-col justify-center items-center font-bold text-4xl  max-sm:flex-col max-sm:gap-5 bg-cover bg-center"
            >
              <div className="absolute text-light">{lang === "hi" ? AdminJson?.header?.en : AdminJson?.header?.hi}</div>
              <img src={YourImage} alt="Your Image" />
            </div>
            <div
              className="relative w-[100%] bg-light-100"
              style={{ height: "auto" }}
            >
              <div
                className="absolute w-[100%] z-10 flex justify-center mybooking_RES"

              >
                <div
                  className="w-[85%] mx-10 flex items-center rounded-px bg-light p-10 shadow max-sm:flex-col max-md:p-0 max-md:width-auto mybooking_res"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="w-[20%] max-sm:w-[100%] max-md:w-[100%] border-r-2 max-sm:border-none">
                    <AdminSection />
                  </div>
                  <div className="w-[80%] flex flex-col justify-center items-center gap-2 mybooking_table_res">
                    <div className="  text-2xl flex justify-center font-semibold ">
                      <p>{lang === "hi" ? AdminJson?.title?.en : AdminJson?.title?.hi}</p>
                    </div>

                    {
                      loader ? <Loader /> : <div className="shadow w-[90%] bg-light-100 mt-4 max-sm:w-full" >
                        <div className="px-2 font-semibold py-2 max-sm:text-center">
                          {lang === "hi" ? AdminJson?.["sub-title"]?.en : AdminJson?.["sub-title"]?.hi}
                        </div>

                        {
                          !bookingList?.length ? <div className="w-full text-center">{lang === "hi" ? AdminJson?.notFound?.en : AdminJson?.notFound?.hi}</div> : <div>
                            <div className="bg-[#FDE9D7] py-2 px-3 max-sm:px-0">
                              {/* {
                          !bookingList?.length && <div>No Booking Found</div>
                        } */}
                              <div className="bg-light rounded shadow px-3 py-2">
                                {[...bookingList]?.reverse()?.slice(0, 1)?.map((items, index) => (
                                  <div key={items.booking_id} className="row remove">
                                    <div className="row_sec col-lg-2 border-r text-center max-md:w-[50%] max-sm:w-[100%]">
                                      <div className="px-2 p-2 flex  items-center justify-center">
                                        <img
                                          src={KalashImg}
                                          className="mr-2"
                                          alt=""
                                        />
                                        <p className="text-xs font-semibold">
                                          {lang === "hi" ? AdminJson?.["table-one"]?.["option-one"]?.en : AdminJson?.["table-one"]?.["option-one"]?.hi}
                                        </p>
                                      </div>
                                      <p
                                        className="pb-3 text-xs text-orange-600 font-semibold"
                                        style={{ fontSize: "8px" }}
                                      >
                                        {lang === "hi" ? items.pooja_name?.en : items.pooja_name?.hi}
                                      </p>
                                    </div>
                                    <div className="row_sec col-lg-2 border-r text-center max-md:w-[50%] max-sm:w-[100%]">
                                      <div className="px-2 p-2 flex  items-center justify-center ">
                                        <img src={DateImg} className="mr-2" alt="" />
                                        <p className="text-xs font-semibold">
                                          {lang === "hi" ? AdminJson?.["table-one"]?.["option-two"]?.en : AdminJson?.["table-one"]?.["option-two"]?.hi}
                                        </p>
                                      </div>
                                      <p
                                        className="pb-3 px-3 text-xs  font-bold"
                                        style={{ fontSize: "8px" }}
                                      >
                                        {items?.date}
                                      </p>
                                    </div>
                                    <div className="col-lg-2 border-r text-center max-md:w-[50%] max-sm:w-[100%]">
                                      <div className="px-2 p-2 flex  items-center justify-center">
                                        <img src={GroupImg} className="mr-2" alt="" />
                                        <p className="text-xs font-semibold">{lang === "hi" ? AdminJson?.["table-one"]?.["option-three"]?.en : AdminJson?.["table-one"]?.["option-three"]?.hi}</p>
                                      </div>
                                      <p
                                        className="pb-3 px-3 text-xs  uppercase font-bold"
                                        style={{ fontSize: "8px" }}
                                      >
                                        {lang === "hi" ? items.package_name?.en : items.package_name?.hi}
                                      </p>
                                    </div>
                                    <div className="col-lg-3 border-r text-center max-md:w-[50%] max-sm:w-[100%]">
                                      <div className="px-3 p-2 flex justify-center items-center">
                                        <img src={TableImg} className="mr-2" alt="" />
                                        <p className="text-xs font-semibold">
                                          {lang === "hi" ? AdminJson?.["table-one"]?.["option-four"]?.en : AdminJson?.["table-one"]?.["option-four"]?.hi}
                                        </p>
                                      </div>
                                      <p
                                        className="pb-3 px-4 pt-1 text-xs  uppercase font-bold"
                                        style={{
                                          fontSize: "8px",
                                          color: getStatusColor(items.status),
                                        }}
                                      >
                                        {items.status}
                                      </p>
                                    </div>
                                    <div className="col-lg-3 flex justify-center items-center">

                                      <button className="bg-orange-600 text-light  px-3 py-2 rounded font-semibold text-sm" onClick={() => handleNavigateDetailPage(items?.id, items?.pooja_image)}>
                                        {lang === "hi" ? AdminJson?.["table-one"]?.button?.en : AdminJson?.["table-one"]?.button?.hi}
                                      </button>



                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className=" py-4 shadow">
                              <table className="min-w-full">
                                <thead>
                                  <tr>
                                    <th className="styling-table pl-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-one"]?.en : AdminJson?.["table-two"]?.["option-one"]?.hi}
                                    </th>
                                    <th className="styling-table px-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-five"]?.en : AdminJson?.["table-two"]?.["option-five"]?.hi}
                                    </th>
                                    <th className="styling-table px-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-seven"]?.en : AdminJson?.["table-two"]?.["option-seven"]?.hi}
                                    </th>
                                    <th className="styling-table px-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-three"]?.en : AdminJson?.["table-two"]?.["option-three"]?.hi}
                                    </th>
                                    <th className="styling-table px-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-four"]?.en : AdminJson?.["table-two"]?.["option-four"]?.hi}
                                    </th>
                                    <th className="view-heading  px-3 py-2">
                                      {lang === "hi" ? AdminJson?.["table-two"]?.["option-six"]?.en : AdminJson?.["table-two"]?.["option-six"]?.hi}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {
                              !bookingList?.length && <div>No Bookings Found</div>
                            } */}
                                  {
                                    [...bookingList]?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((items) => (
                                      <>
                                        <tr key={items.id}>
                                          <td className="table-content px-3 py-2">
                                            DEV-<span>{items.id}</span>
                                          </td>
                                          <td className="table-content px-3 py-2">
                                            {lang === "hi" ? items.pooja_name?.en : items.pooja_name?.hi}
                                          </td>
                                          <td className="table-content px-3 py-2">
                                            {items.time}
                                          </td>
                                          <td className="table-content px-3 py-2">
                                            {items.date}
                                          </td>
                                          <td className="table-content px-3 py-2 text-uppercase">
                                            {items.status}
                                          </td>
                                          <td className="table-content px-3 py-2 flex flex-col gap-1">
                                            {/* <Link
                                        to={"/Booking/" + items.id}
                                      > */}
                                            <button className="bg-orange-600 px-4 py-1 text-light rounded" onClick={() => handleNavigateDetailPage(items?.id, items?.pooja_image)}>
                                              {lang === "hi" ? AdminJson?.["table-two"]?.button?.en : AdminJson?.["table-two"]?.button?.hi}
                                            </button>
                                            {/* </Link> */}
                                            <button onClick={() => navigate("/videos", { state: { videos: items?.videos } })} className="bg-orange-600 px-4 py-1 text-light rounded" type="button">{lang === "hi" ? AdminJson?.["table-two"]?.buttons_two?.en : AdminJson?.["table-two"]?.buttons_two?.hi}</button>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </table>
                              {/* <div className="w-full"> */}

                              {/* </div> */}
                              {/* {bookingList.length > 4 && (
                                <div className="text-orange-600 font-bold absolute bottom-0 right-30">
                                  <button
                                    onClick={handleShowMore}
                                    className="border-orange-600 rounded  border-2 py-1 px-4"
                                  >
                                    {showMoreResults
                                      ? (lang === "hi" ? AdminJson?.button_four?.en : AdminJson?.button_four?.hi)
                                      : (lang === "hi" ? AdminJson?.button_three?.en : AdminJson?.button_three?.hi)
                                    }
                                  </button>
                                </div>
                              )} */}
                            </div>

                          </div>
                        }

                      </div>
                    }
                    <div className="w-full">
                      {/* <Stack spacing={2}
                        padding="10px 0px"
                        borderRadius="10px"
                        // width="100%"
                        sx={{ width: 'auto' }}
                        direction={"row"}
                      >
                        <Pagination
                          count={Math.ceil(bookingList.length / itemsPerPage)}
                          variant="outlined"
                          shape="rounded"
                          page={currentPage}
                          onChange={handlePageChange}
                        />
                      </Stack> */}
                      <TablePagination
                        component="div"
                        count={bookingList?.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-10" style={{ position: "absolute", top: '100%', left: 0, right: 0 }}>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <div className="loader">
        <div className="flex justify-center items-center flex-col animate-pulse">
          <img src="/Logo.jpg" alt="Loader" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }
}

export default MyBooking;
