import React from "react";
import { useNavigate } from "react-router-dom";
import Foot from "./Foot.json"

const Footer = () => {
  const navigate = useNavigate()
  const lang = JSON.parse(localStorage.getItem("lang"))
  return (
    <section className="bg-[#FFEAE3] w-full  flex-col items-center  justify-center p-10 max-sm:p-0  max-md:max-w-full">
      <footer className="flex flex-col items-center justify-center w-full max-lg:m-0 max-sm:w-full max-lg:w-full max-sm:mx-0">
        <div data-aos="zoom-in">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="1"
            viewBox="0 0 1595 1"
            fill="none"
          >
            <path d="M0.5 0.5L1594.5 0.499861" stroke="black" />
          </svg>
        </div>
        <div className="flex w-full p-2 gap-5 items-center max-lg:flex-col  max-md:max-w-full max-md:flex-wrap max-md:mt-10 max-md:pr-5 max-sm:flex-col max-lg:gap-2 max-lg:w-full max-lg:m-0 max-lg:p-0">


          <div className="flex w-[25%]   justify-center items-center gap-10  max-md:max-w-full max-md:flex-wrap max-md:mt-10 max-md:w-[100%] max-md:pr-5 max-sm:flex-col max-sm:w-[100%] p-2 max-lg:w-full max-lg:m-0 max-lg:p-0">
            <div className="flex  justify-center  flex-col">
              <div className="flex  gap-3  max-lg:flex-col  " data-aos="zoom-in">
                <img
                  loading="lazy"
                  src="/Logo.jpg"
                  className="object-cover w-28 h-28 rounded-full mix-blend-multiply"
                  alt="Logo"
                />
                <p className="font-akshar text-black text-1xl font-bold my-auto">
                  {lang === "hi" ? Foot?.title?.en : Foot?.title?.hi}
                </p>
              </div>
              <div className="flex justify-center items-center flex-col" data-aos="zoom-in">
                <p className="text-black text-sm mt-3 font-normal w-full">
                  {lang === "hi" ? Foot?.description?.en : Foot?.description?.hi}
                </p>
              </div>
            </div>
          </div>



          <div className="flex w-[65%] p-3  justify-around items-start gap-[13vh] max-md:max-w-full max-md:flex-wrap max-md:mt-10 max-md:pr-5 max-sm:flex-col max-lg:w-full max-lg:flex-col max-lg:gap-5 max-lg:p-0 ">
            <div className="flex pr-8 flex-col justify-center w-full ml-10 max-sm:p-0 max-sm:m-0 max-lg:p-0 max-lg:m-0">
              <p className="font-akshar text-black text-2xl font-bold uppercase whitespace-nowrap" data-aos="fade-down">
                {lang === "hi" ? Foot?.footer[0]?.title?.en : Foot?.footer[0]?.title?.hi}
              </p>
              <p className="text-black text-sm whitespace-nowrap font-normal mt-3 cursor-pointer" data-aos="fade-down" onClick={() => navigate("/occasionpuja")}>
                {lang === "hi" ? Foot?.footer[0]?.["option-1"]?.en : Foot?.footer[0]?.["option-1"]?.hi}
              </p>
              <p className="text-black text-sm whitespace-nowrap font-normal mt-3 cursor-pointer" data-aos="fade-down" onClick={() => navigate("/specialpooja")}>
                {lang === "hi" ? Foot?.footer[0]?.["option-2"]?.en : Foot?.footer[0]?.["option-2"]?.hi}
              </p>
              <p className="text-black text-sm whitespace-nowrap font-normal mt-3 cursor-pointer" data-aos="fade-down" onClick={() => navigate("/nityapooja")}>
                {lang === "hi" ? Foot?.footer[0]?.["option-3"]?.en : Foot?.footer[0]?.["option-3"]?.hi}
              </p>
              <p className="text-black text-sm whitespace-nowrap font-normal mt-3 cursor-pointer" data-aos="fade-down" onClick={() => navigate("/gopooja")}>
                {lang === "hi" ? Foot?.footer[0]?.["option-4"]?.en : Foot?.footer[0]?.["option-4"]?.hi}
              </p>
            </div>
            <div className="flex flex-col  w-full justify-center ">
              <h1 className=" font-akshar text-black text-2xl font-bold uppercase whitespace-nowrap" data-aos="fade-down">
                {lang === "hi" ? Foot?.footer[1]?.title?.en : Foot?.footer[1]?.title?.hi}
              </h1>
              <p className="text-black font-normal w-[80%] max-sm:w-[100%] text-sm mt-3 cursor-pointer" onClick={() => navigate("/termsandconditions")} data-aos="fade-down"> {lang === "hi" ? Foot?.footer[1]?.["option-1"]?.en : Foot?.footer[1]?.["option-1"]?.hi}</p>
              <p className="text-black font-normal w-[80%]  max-sm:w-[100%] text-sm mt-3 cursor-pointer" onClick={() => navigate("/privacypolicy")} data-aos="fade-down"> {lang === "hi" ? Foot?.footer[1]?.["option-2"]?.en : Foot?.footer[1]?.["option-2"]?.hi}</p>
            </div>

            {/* <div className="flex flex-col w-full justify-center ">
              <h1 className=" font-akshar text-black text-2xl font-bold uppercase whitespace-nowrap" data-aos="fade-down">
              {lang === "hi" ? Foot?.footer[2]?.title?.en : Foot?.footer[2]?.title?.hi}
              </h1>
              <div className="flex  gap-3 mt-3 items-start" data-aos="fade-down">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c38c5e6beeb4cc6e9f04611d19e69f5df1b19a8335c1ff4002ffc98a62c2bdbb?apiKey=8478572201cf4b3f8647adfe69018175&"
                  className="aspect-square object-contain object-center w-5 fill-black fill-opacity-0 overflow-hidden shrink-0 max-w-full"
                  alt="#"
                />
                <p className="text-black font-normal text-sm uppercase">+12 2629 7828</p>
              </div>
              <div className="flex justify-between gap-3 mt-3 items-start" data-aos="fade-down" >
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/637cb60f609b661ecb45c8fface7ee1d6941ba5da6ea8c5adae09b2ed6e1f01e?apiKey=8478572201cf4b3f8647adfe69018175&"
                  className="aspect-square object-contain object-center w-5 fill-black fill-opacity-0 overflow-hidden shrink-0 max-w-full"
                  alt="#"
                />
                <p className="text-black font-normal text-sm self-stretch grow whitespace-nowrap">
                  devkirpa@domain.com
                </p>
              </div>
              <div className="flex justify-between gap-3 mt-3 items-start" data-aos="fade-down">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/bcdd36f4324d95e984a7a954f74e266f2e3f8edcdca8f2b800ee4b4d51e676bd?apiKey=8478572201cf4b3f8647adfe69018175&"
                  className="aspect-square object-contain object-center w-5 fill-black fill-opacity-0 overflow-hidden shrink-0 max-w-full"
                  alt="#"
                />
                <p className="text-black font-normal text-sm self-stretch grow whitespace-nowrap">
                  Hyderabad, Telangana
                </p>
              </div>
            </div> */}



          </div>
          {/* <div className="w-[25%] mt-8 p-2 flex flex-col gap-3 max-sm:w-full max-md:w-full max-lg:w-full">
            <div className="">
              <h1 className=" font-akshar text-black text-start text-2xl font-bold uppercase max-sm:text-center" data-aos="fade-up">
              {lang === "hi" ? Foot?.footer[3]?.title?.en : Foot?.footer[3]?.title?.hi}
              </h1>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15223.702964510601!2d78.34618194801115!3d17.463267984987798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93c92849a36b%3A0xde0dc034905512fa!2sKondapur%2C%20Telangana!5e0!3m2!1sen!2sin!4v1711975778804!5m2!1sen!2sin" width="250" height="200" style={{ border: "0px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" data-aos="flip-right" ></iframe>


            </div>
          </div> */}
        </div>
        <div className="flex w-[100%] mb-5 justify-center max-sm:px-5 ">
          <p className="text-zinc-950 font-normal text-md  mt-14 max-md:max-w-full max-md:mt-10 max-sm:text-sm" data-aos="ease-in-out">
          {lang === "hi" ? Foot?.copyright?.en : Foot?.copyright?.hi}
            <b> {lang === "hi" ? Foot?.company?.en : Foot?.company?.hi}</b>
          </p>
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="1"
            viewBox="0 0 1595 1"
            fill="none"
          >
            <path d="M0.5 0.5L1594.5 0.499861" stroke="black" />
          </svg>
        </div>
      </footer>
    </section>
  );
};

export default Footer;