import React from 'react' 
import YourImage from '../Images/IMAGE (1).jpg'
import Header from '../Include/Header'
import Navbar from '../Include/Navbar'
import AdminSection from './AdminSection'
import Footer from '../Include/Footer'

function ChangePassword() {
  return (
    <>
    <section className="my-5">
      <Header />
      <Navbar />
      <div className="relative">
        <div
          style={{ height: "auto" }}
          className="relative  w-[100%]   flex flex-col justify-center items-center font-bold text-4xl  max-sm:mx-5 max-sm:flex-col max-sm:gap-5 bg-cover bg-center"
        >
          <div className="absolute text-light" >My Account</div>
          <img src={YourImage} alt="Your Image" />
        </div>
        <div className="w-[100%] bg-light-100" style={{height:"550px"}} ></div>
        <div className="absolute w-[100%] z-10 flex justify-center" style={{top:"225px"}}>
          <div className="w-[85%] mx-10 shadow flex rounded-px bg-light p-6" style={{borderRadius:"10px"}} >
            <div className="w-[35%] border-r-2">
              <AdminSection/>
            </div>
            <div className="w-[65%] flex flex-col justify-center items-center gap-2">
              <div className="  text-2xl flex justify-center font-bold ">
                <p>Change Password</p>
              </div>
              <div className="w-[50%]">
                <form className="grid grid-cols-1 gap-4 pt-5">
                  <div className="relative flex flex-col w-[100%] gap-1">
                    <label className="w-full font-semibold text-xs text-black mx-1 ">
                      Old Password:{" "}
                    </label>
                    <input
                      className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2 max-md:w-[75%] max-sm:w-[100%]"
                      type="password"
                      name="old_password"
                      id="old_password"
                    />
                    <i className="absolute text-xs right-4 top-9 fa-solid fa-eye-slash"></i>
                  </div>

                  <div className="relative flex flex-col w-[100%] gap-1">
                    <label className="w-full text-xs font-semibold text-black mx-1 ">
                      New Password:{" "}
                    </label>
                    <input
                      className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2 max-md:w-[75%] max-sm:w-[100%]"
                      type="password"
                      name="password"
                      id="password"
                    />
                    <i className="absolute text-xs right-4 top-9 fa-solid fa-eye-slash"></i>
                  </div>

                  <div className="relative flex flex-col w-[100%] gap-1">
                    <label className="w-full text-xs font-semibold text-black mx-1 ">
                      Confirm Password:{" "}
                    </label>
                    <input
                      className="border w-[100%] rounded border-[#910033] focus:outline-[#910033] border-2 p-2 max-md:w-[75%] max-sm:w-[100%]"
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                    />
                    <i className="absolute text-xs right-4 top-9 fa-solid fa-eye-slash"></i>
                  </div>

                  <div className="bg-orange-600 flex flex-col p-3 text-light font-semibold text-xs rounded w-[100%] gap-1">
                    <button>Change Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
    
  </>
  )
}

export default ChangePassword