import React from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import NityaPoojaa from "./Nitya.json"
import Metadata from '../../../Meta/Metadata'

const NityaPooja = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Nitya Puja`}
                description="Engage in daily pujas with DEV KIRPA. Maintain spiritual discipline and receive daily blessings."
                url={`https://devkirpa.com/nityapooja}`}
            />
            <Header />
            <Navbar />
            <div className='flex justify-center items-center flex-col w-full p-3 font-semibold'>
                <div className='w-[70%] p-2 flex flex-col gap-5'>
                    <div className='text-2xl'>
                        <h1>
                            {lang === "hi" ? NityaPoojaa?.title?.en : NityaPoojaa?.title?.hi}
                        </h1>
                    </div>
                    <div>
                        <p>
                            {lang === "hi" ? NityaPoojaa?.description?.en : NityaPoojaa?.description?.hi}
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NityaPooja