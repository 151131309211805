import React, { useEffect, useState } from "react";
import adminLogo from "../Images/user.png";
import { Link,useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { errorMessage } from "../../Utils/notificationManger";
import axios from "axios";

function AdminSection() {
  const [userList, setUserList] = useState([])
  const {dispatch}= useAuth();
  const navigate = useNavigate();
  const user_id = JSON.parse(localStorage?.getItem("user_id"))
  const lang = JSON.parse(localStorage?.getItem("lang"))
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("auth"); // Remove user_id from local storage
    navigate("/login"); // Redirect to login page
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`https://devkirpa.com/admin/api/web-edit-profile?user_id=${user_id}`)
      const data = await response?.data
      setUserList(data?.user)
    } catch(error){
      errorMessage(error?.response?.data?.error)
    }
  }

  // const filterUser = userList && userList?.filter(item => item?.id === user_id)

  useEffect(()=>{
    fetchUser()
  },[])

  return (
    <section>
      <div className="w-45 flex flex-col justify-center items-center font-semibold text-3xl gap-3">
        <img className="w-50" src={adminLogo} alt="" />
        <p>{lang === "hi" ? userList?.name?.en : userList?.name?.hi}</p>
      </div>
      {/* <div className="flex flex-col gap-4 pt-5 px-1 justify-start">
        <Link to="/ProfileDetails">
          <p className="font-semibold">Profile Details</p>
        </Link>
        <Link to="/MyBooking">
          <p className="font-semibold">My Bookings</p>
        </Link>
        <p className="font-semibold text-orange-600 pointer" onClick={handleLogout}>Log Out</p>
      </div> */}
    </section>
  );
}

export default AdminSection;
