import React from 'react'
import Footer from '../../Include/Footer'
import Navbar from '../../Include/Navbar'
import Header from '../../Include/Header'
import CardThree from "./CardThree.json"

const Card3 = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Header />
            <Navbar />
            <div className='font-semibold flex flex-col py-10 gap-3'>


                <div className='flex flex-col px-24 gap-3 w-full max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    {
                        CardThree?.points?.map((point, i) => {
                            return (
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-2xl'>{lang === "hi" ? point?.title?.en : point?.title?.hi}</h1>
                                    <p className='flex flex-col gap-2'>{lang === "hi" ? point?.description?.en?.map((i) => <p>{i}</p>) : point?.description?.hi?.map((i) => <p>{i}</p>)}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Card3


// {/* <div className='mt-10 mx-24'>
//                     <h1 className='text-xl font-bold'> Elevate Your Spiritual Journey: Discover the Essence of Puja with Dev Kirpa
//                     </h1>
//                 </div>
//                 <div className='flex flex-col gap-3 mx-24'>
//                     <p>In the fast-paced modern world, finding moments of peace and spiritual connection can
//                         feel like a challenge. However, at Dev Kirpa, we believe that nurturing your soul and
//                         fostering a deeper connection with the divine is not only possible but essential for overall
//                         well-being. Join us on a journey of spiritual discovery as we delve into the essence of puja
//                         and the transformative offerings of Dev Kirpa.</p>

//                     {/* <p className='font-bold'>At Dev Kirpa, we offer a range of puja services designed to cater to your spiritual needs and
//                         intentions:</p> */}

//                         <div className='flex flex-col gap-3'>
//                         <h1 className='text-xl font-bold'>Embrace Tradition, Embrace Puja
//                         </h1>
//                         <p>In a world that often seems disconnected from its roots, puja serves as a powerful
//                             reminder of tradition and spirituality. At Dev Kirpa, we are committed to ”reserving and
//                             honoring this ancient practice, offering a range of puja services designed to meet the
//                             diverse needs of our global community. Whether you’re celebrating a joyous occasion,
//                             seeking guidance and blessings, or simply craving a deeper connection with the divine,
//                             puja offers a pathway to spiritual fulfillment.
//                         </p>
//                     </div>

//                     <div className='flex flex-col gap-3'>
//                         <h1 className='text-xl font-bold'>Personalized Blessings</h1>
//                         <p>No two spiritual journeys are the same, which is why we offer personalized puja services
//                             tailored to your unique needs and intentions. Our experienced priests perform sacred
//                             rituals and prayers with utmost sincerity, invoking the blessings of the divine to manifest
//                             your heartfelt desires. Whether you’re facing challenges, seeking healing, or simply
//                             yearning for inner peace, our Special Puja services provide a sacred space for reflection,
//                             renewal, and spiritual growth</p>
//                     </div>

//                     <div className='flex flex-col gap-3'>
//                         <h1 className='text-xl font-bold'>Daily Devotion: Nithya Puja
//                         </h1>
//                         <p>In the midst of life’s demands and distractions, cultivating a daily practice of devotion can
//                             bring immense peace and clarity. Our Nithya Puja service offers a sanctuary of spiritual
//                             nourishment, allowing you to connect with the divine on a regular basis. Our dedicated
//                             priests perform daily rituals and prayers on your behalf, infusing your life with a sense of
//                             purpose, gratitude, and serenity. With Nithya Puja, you can embark on a journey of self discovery and spiritual awakening, one day at a time</p>
//                     </div>

//                     <div className='flex flex-col gap-3'>
//                         <h1 className='text-xl font-bold'>Honoring Sacred Traditions: Gopuja</h1>
//                         <p>In Hindu culture, the cow is revered as a symbol of divine grace and abundance. Our
//                             Gopuja service provides an opportunity to honor and reverence the sacred cow through
//                             elaborate rituals and prayers. By expressing gratitude for the cow’s role in nourishing and
//                             sustaining life, you can deepen your connection with the divine and contribute to the
//                             preservation of this ancient tradition. Gopuja is not just a ritual; it’s a sacred act of
//                             reverence and gratitude that transcends boundaries and unites us in our shared humanity.
//                         </p>
//                     </div>

//                     <p>At Dev Kirpa, we believe that spirituality is a journey, not a destination. With our range of
//                         puja services, you can elevate your spiritual journey and experience the transformative
//                         power of puja in your own life. Join us as we embark on this sacred journey together, and let
//                         the blessings of the divine guide you every step of the way.</p>

//                 </div> */}