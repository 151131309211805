import React from 'react'
import { IoSearch } from "react-icons/io5";
import PlaceHolder from "./Component.json"

const SearchComponent = ({ setSearchValue }) => {

  const lang = JSON.parse(localStorage?.getItem("lang"))

  return (
    <div className='flex p-2 gap-2 justify-center items-center max-sm:py-0 border-1 border-black rounded-md bg-inherit max-sm:w-72  max-lg:w-80 max-lg:ml-64'>
      <IoSearch className='text-2xl max-sm:hidden' />
      <input type="search" name='search' id='search' onChange={(e) => setSearchValue(e.target.value)} className='p-2 w-96 bg-inherit focus:outline-0 font-semibold max-sm:py-0' placeholder={lang === "hi" ? PlaceHolder?.placeHolder?.en : PlaceHolder?.placeHolder?.hi} />
    </div>
  )
}

export default SearchComponent