import React from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Gopooja from "./Gopuja.json"
import Metadata from '../../../Meta/Metadata'

const GoPooja = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Go Puja`}
                description="Honor and worship the sacred cow with Gopuja by DEV KIRPA. Invoke divine blessings and prosperity."
                url={`https://devkirpa.com/gopooja}`}
            />
            <Header />
            <Navbar />
            <div className='flex justify-center items-center flex-col w-full p-3 font-semibold'>
                <div className='w-[70%] p-2 flex flex-col gap-5'>
                    <div className='text-2xl'>
                        <h1>  {lang === "hi" ? Gopooja?.title?.en : Gopooja?.title?.hi}
                        </h1>
                    </div>
                    <div>
                        <p>{lang === "hi" ? Gopooja?.description?.en : Gopooja?.description?.hi}
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GoPooja