import React from 'react'
import LoaderImage from "../Components/Images/CircleLogo.png"

const Loader = () => {
    return (
        <div className='flex flex-col justify-center items-center w-full h-auto'>
            <img src="/Logo.jpg" alt={LoaderImage} className='w-40 h-40 object-contain animate-pulse' />
            <p className='animate-pulse font-semibold'>Loading...</p>
        </div>
    )
}

export default Loader