import React from 'react'
import Header from '../../Include/Header'
import Navbar from '../../Include/Navbar'
import Footer from '../../Include/Footer'
import CardOne from "./CardOne.json"

const Card1 = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
            <Header />
            <Navbar />
            <div className='font-semibold flex flex-col py-10 gap-3'>
                {/* <div className='mt-10 mx-24'>
                    <h1 className='text-xl font-bold'> Embrace Divine Blessings: Experience the Essence of Puja with Dev Kirpa
                    </h1>
                </div>
                <div className='flex flex-col gap-3 mx-24'>
                    <p>In the hustle and bustle of modern life, it’s easy to lose touch with our spiritual roots and
                        the profound sense of connection that comes from engaging in sacred rituals. However, at
                        Dev Kirpa, we believe that spirituality should be accessible and meaningful for everyone,
                        regardless of their location or schedule. That’s why we’re dedicated to bringing the essence
                        of puja – the ancient practice of worship – into the digital age, making it easier than ever to
                        experience divine blessings and inner peace.</p>

                    <p className='font-bold'>At Dev Kirpa, we offer a range of puja services designed to cater to your spiritual needs and
                        intentions:</p>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>1. Occasion Puja: Celebrating Life’s Milestones</h1>
                        <p>Life is full of special moments – weddings, housewarmings, birthdays – that deserve to be
                            celebrated with reverence and gratitude. Our Occasion Puja service allows you to infuse
                            these significant events with divine blessings and sanctity. Our experienced priests will
                            perform traditional rituals and prayers to bless the occasion and bring prosperity, harmony,
                            and joy into your life.</p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>2. Special Puja: Seeking Divine Guidance and Blessings</h1>
                        <p>Sometimes, we need a little extra support and guidance on our spiritual journey. Our
                            Special Puja service is tailored to meet your specific needs and intentions, whether you’re
                            seeking healing, success, protection, or inner peace. Our priests will perform sacred rituals
                            and offer prayers on your behalf, invoking the blessings of the divine to manifest your
                            heartfelt desires.</p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>3. Nithya Puja: Cultivating Daily Devotion</h1>
                        <p>In the chaos of daily life, it’s important to carve out moments of stillness and reflection.
                            Our Nithya Puja service offers a daily dose of spiritual nourishment, allowing you to
                            connect with the divine on a regular basis. Our dedicated priests will perform daily rituals
                            and prayers on your behalf, fostering a deeper sense of gratitude, devotion, and inner
                            peace in your life</p>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <h1 className='text-xl font-bold'>4. Gopuja: Honoring the Sacred Cow</h1>
                        <p>In Hindu culture, the cow is revered as a symbol of divine grace and abundance. Our
                            Gopuja service allows you to honor and reverence the sacred cow through elaborate rituals
                            and prayers. By expressing gratitude for the cow's nourishment, sustenance, and spiritual
                            significance, you can connect with the divine presence in all living beings and contribute to
                            the preservation of this ancient tradition.</p>
                    </div>

                    <p>At Dev Kirpa, we understand the importance of spirituality in today’s fast-paced world, and
                        we’re committed to making it accessible and meaningful for everyone. With our range of
                        puja services, you can experience the essence of divine blessings, inner peace, and
                        spiritual fulfillment – all from the comfort of your own home. Embrace the power of puja
                        with Dev Kirpa, and let the divine grace flow into every aspect of your life.</p>

                </div> */}
                <div className='mt-10 px-24 w-full max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    <h1 className='text-2xl'>{lang === "hi" ? CardOne?.header?.en : CardOne?.header?.hi}</h1>
                </div>

                <div className='w-full px-24 max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    <p>{lang === "hi" ? CardOne?.['sub-header']?.en : CardOne?.['sub-header']?.hi}</p>
                </div>

                <div className='w-full px-24 max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    <p>{lang === "hi" ? CardOne?.slug?.en : CardOne?.slug?.hi} </p>
                </div>

                <div className='flex flex-col px-24 gap-3 w-full max-sm:px-5 max-md:px-5 max-lg:px-5'>
                    {
                        CardOne?.points?.map((point, i) => {
                            return (
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-2xl'>{lang === "hi" ? point?.title?.en : point?.title?.hi}</h1>
                                    <p className='flex flex-col gap-2'>{lang === "hi" ? point?.description?.en?.map((i) => <p>{i}</p>) : point?.description?.hi?.map((i) => <p>{i}</p>)}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Card1