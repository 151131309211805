
import { Navigate, useLocation } from "react-router-dom";


// eslint-disable-next-line react/prop-types
function ProtectedWrapper({ children }) {
  const user_id = localStorage.getItem("user_id");
  const location = useLocation();
console.log(user_id)
  return user_id ? (
    <div>
        {children}
    </div>  
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export default ProtectedWrapper;
