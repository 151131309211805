import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Include/Header";
import Navbar from "../Include/Navbar";
import ContactImg from "../Images/ContactFormImg.png";
import Footer from "../Include/Footer";
import { errorMessage, successMessage } from "../../Utils/notificationManger";
import dataJson from "./Contact.json"
import Metadata from "../../Meta/Metadata";


function ContactUs() {

  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    msg: "",
  });

  // const [title, setTitle] = useState()
  // const [subTitle, setSubTitle] = useState()
  // const [firstName, setFirstName] = useState()
  // const [lastName, setLastName] = useState()
  // const [phone, setPhone] = useState()
  // const [email, setEmail] = useState()
  // const [service, setService] = useState()
  // const [date, setDate] = useState()
  // const [cta, setCta] = useState()
  // const [address, setAddress] = useState()
  const lang = JSON.parse(localStorage.getItem("lang"))
  // const constLang = JSON.parse(localStorage.getItem("constLang"))

  const handleSubmitForm = async (values) => {
    try {
      const res = await axios.post(
        "https://devkirpa.com/admin/api/save-contact-us",
        values
      );
      // setMessage('Form submitted successfully')
      successMessage(res?.data?.message)
      console.log("Form submitted successfully:", values);


    } catch (error) {
      errorMessage(error?.response?.data?.errors?.name || error?.response?.data?.errors?.msg || error?.response?.data?.errors?.phone || error?.response?.data?.errors?.email)
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!values?.name) {
      errorMessage("Name is Required")
      return
    }
    if (!values?.email) {
      errorMessage("Email is Required")
      return
    }
    if (!values?.msg) {
      errorMessage("Message is Required")
      return
    }

    if (!values?.phone) {
      errorMessage("Phone is Required")
      return
    }
    handleSubmitForm(values);
    // window.alert('Form submitted successfully')
    setValues({
      name: "",
      phone: "",
      email: "",
      msg: "",
    })
  };

  // const getTranslatedText = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setTitle(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }

  // const getTranslatedSubTitle = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setSubTitle(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedFirstName = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setFirstName(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }


  // const getTranslatedLastName = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setLastName(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedPhoneNumber = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setPhone(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedDate = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setDate(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedService = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setService(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedEmail = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setEmail(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedCta = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setCta(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }
  // const getTranslatedAddress = async (text) => {
  //   if (lang !== constLang) {
  //     try {
  //       const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

  //       setAddress(response?.data?.matches);
  //     } catch (error) {
  //       errorMessage(error?.response?.data?.message)
  //     }
  //   }
  // }


  // useEffect(() => {
  //   getTranslatedText("Contact Us")
  //   getTranslatedSubTitle("Lets Connected")
  //   getTranslatedDate("Date")
  //   getTranslatedFirstName("first name")
  //   getTranslatedLastName("last name")
  //   getTranslatedPhoneNumber("phone")
  //   getTranslatedService("message")
  //   getTranslatedEmail("email")
  //   getTranslatedCta("contact us")
  //   getTranslatedAddress("Address")
  // }, [lang])

  return (
    <section className="font-poppins">
      <Metadata
        title={`DEV KIRPA | Contact`}
        description="Get in touch with DEV KIRPA for personalized puja services. Reach out for all your spiritual needs and queries."
        url={`https://devkirpa.com/Contact`}
      />
      <div className="w-[100%]">
        <Header />
      </div>
      <div className="nav pb-3">
        <Navbar />
      </div>
      <div className="w-[100%] py-10 mb-5 flex justify-center items-center max-sm:w-full">
        <div className="w-[97%] contactUsRes gap-5 p-2 max-sm:w-full">
          <div className="w-[50%] flex flex-col gap-4 justify-center items-center max-sm:w-full max-sm:hidden">
            <div className="w-[80%]  shadow-lg rounded p-2 flex flex-col gap-4" data-aos="fade-up">
              <div className="w-full h-96  max-sm:w-[100%]"  >
                <img src={ContactImg} alt="" className="object-cover w-full h-96" />
              </div>
              {/* <div className="flex gap-5 justify-center items-center">
                <div>
                  <h3 className="font-bold text-orange-600">{lang==="hi" ? dataJson?.title?.en : dataJson?.title?.hi}</h3>
                  <p>devkirpa@gmail.com</p>
                </div>
                <div>
                  <h3 className="font-bold text-orange-600">{lang==="hi" ? dataJson?.Address?.en : dataJson?.Address?.hi}</h3>
                  <p>Dev KirpaBanjara Hills, Hyderabad, India</p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="w-[50%] flex flex-column justify-center items-center max-sm:w-full max-sm:px-10">
            <form action="post" onSubmit={onSubmit} className="w-full">
              <div>
                <h1
                  className="text-3xl font-bold max-sm:text-6xl font-akshar"
                  data-aos="zoom-in"
                >
                  {lang === "hi" ? dataJson?.title?.en : dataJson?.title?.hi}
                </h1>
              </div>
              <div className="flex flex-col pt-5 gap-5 ">
                <div className="w-full grid grid-cols-2 gap-5 max-sm:grid-cols-1">
                  <div className="flex flex-column" data-aos="fade-left">
                    <label htmlFor="" className="text-[#383E49]"> {lang === "hi" ? dataJson?.Fullname?.en : dataJson?.Fullname?.hi} </label>
                    <input
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      type="text"
                      className="border-b-2 border-[#898798] outline-2 focus:outline-orange-500 p-2 "
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="flex flex-column" data-aos="fade-right">
                    <label htmlFor="" className="text-[#383E49]" > {lang === "hi" ? dataJson?.email?.en : dataJson?.email?.hi}</label>
                    <input
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      type="text"
                      className="border-b-2 border-[#898798]  outline-2 focus:outline-orange-500 p-2"
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="flex flex-column" data-aos="fade-left">
                    <label htmlFor="" className="text-[#383E49]"> {lang === "hi" ? dataJson?.phone?.en : dataJson?.phone?.hi}</label>
                    <input
                      value={values.phone}
                      name="phone"
                      onChange={handleChange}
                      type="number"
                      className="border-b-2 border-[#898798]  outline-2 focus:outline-orange-500 p-2"
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="flex flex-column" data-aos="fade-right">
                    <label htmlFor="" className="text-[#383E49] "> {lang === "hi" ? dataJson?.service?.en : dataJson?.service?.hi}</label>
                    <input
                      value={values.msg}
                      onChange={handleChange}
                      name="msg"
                      type="text"
                      className="border-b-2 border-[#898798]  outline-2 focus:outline-orange-500 p-2"
                      placeholder=""
                      autoComplete="off"
                      required
                    />
                  </div>

                </div>
                <div className="w-full flex justify-center items-center" >
                  <button className="rounded font-semibold text-sm text-light bg-orange-600  py-2 contactBtnRes" data-aos="zoom-in">
                    {lang === "hi" ? dataJson?.button?.en : dataJson?.button?.hi}
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default ContactUs;
