// import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { errorMessage } from '../../Utils/notificationManger'
// import axios from 'axios'
import Baner from "./Baner.json"

const Banner = () => {
  const user = JSON.parse(localStorage.getItem("user_id"))
  const navigate = useNavigate()



  const handleNavigate = () => {
    if (user) {
      navigate("/AllPujas")
    } else {
      navigate("/login")
    }
  }

  const lang = JSON.parse(localStorage.getItem("lang"))





  return (
    <section className='w-[95%] max-sm:w-full max-sm:m-0 mx-10 flex justify-center items-center flex-col max-lg:mx-7 max-md:mx-5 font-semibold text-black '>
      <div className="text-center flex-col text-8xl max-sm:p-0 max-sm:m-0 p-10 gap-2  w-[75%] mt-5 max-md:max-w-full max-md:text-4xl  max-md:mt-10 max-sm:w-[100%]  max-lg:text-6xl max-lg:w-[95%]  max-md:w-[95%] font-josefin">


        <p style={{ lineHeight: 1, letterSpacing: 2 }} className='font-bold'>  {
          lang === "hi" ? Baner?.title?.en : <p className={`max-sm:text-xl font-bold text-[#f15a24] ${lang === "en" && "tracking-normal leading-tight"}`} data-aos="zoom-in">{Baner?.subtitle?.hi}</p>
        }</p>
        <p className='font-bold'>{
          lang === "hi" ? <p className={`w-full text-[#f15a24] max-sm:px-10 font-bold leading-snug tracking-wide ${lang === "hi" && "leading-none tracking-normal"}`} data-aos="zoom-in">{Baner?.subtitle?.en}</p> : <p className='mt-10'>{Baner?.title?.hi}</p>
        }</p>
      </div>

      <div className="text-black px-10 text-center max-sm:px-10 max-sm:m-0 max-md:w-full max-lg:w-[90%] max-lg:mt-3  w-[64%] mt-6 max-md:max-w-full max-md:mt-10 bannerTitle_res font-poppins" data-aos="zoom-in">
        {lang === "hi" ? Baner?.desc?.en : Baner?.desc?.hi}

      </div>

      <button className="text-white text-xl font-bold  justify-center items-center  hover:bg-[#f15a24] bg-[#f15a24] w-[300px] max-w-full mt-14 mb-16 px-4 py-2 max-md:my-10 max-md:px-5 font-akshar " data-aos="zoom-in" onClick={handleNavigate}>
        {lang === "hi" ? Baner?.button?.en : Baner?.button?.hi}
      </button>
    </section>
  )
}

export default Banner