import * as React from "react";
import '../src/App.css'
import { Routes, Route } from "react-router-dom";
import ProfileDetails from "./Components/MyAccount/ProfileDetails";
import MyBooking from "./Components/MyAccount/MyBooking";
import ChangePassword from "./Components/MyAccount/ChangePassword";
import Home from "./Components/Home/Home";
import ContactUs from "./Components/ContactUs/ContactUs";
import SignUp from "./Components/Auth/SignUp";
import CheckOut from "./Components/BookingDetails/CheckOut";
import CheckOutPayment from "./Components/BookingDetails/CheckOutPayment";
import BookingConfirmed from "./Components/BookingDetails/BookingConfirmed";
import Allpujas from "./Components/AllPujas/Allpujas";
import PujaDetails from "./Components/AllPujas/PujaDetailes/PujaDetails";
import Login from "./Components/Auth/Login";
import About from "./Components/About/About";
import { Toaster } from "react-hot-toast";
import Payment from "./Components/Payment/Payment";
import Dhan from "./Components/Dhan/Dhan";
import Cart from "./Components/Cart/Cart"
import { AuthGuard } from "./Components/Context/AuthContext";
import ProtectedWrapper from "./Utils/ProtectedWrapper";
import AOS from 'aos';
import 'aos/dist/aos.css';
import OccasionPuja from "./Components/Include/FooterTabs/OccasionPuja";
import SpecialPooja from "./Components/Include/FooterTabs/SpecialPooja";
import NityaPooja from "./Components/Include/FooterTabs/NityaPooja";
import GoPooja from "./Components/Include/FooterTabs/GoPooja";
import Card1 from "./Components/OurBlog/Card1/Card1";
import Card2 from "./Components/OurBlog/Card1/Card2";
import Card3 from "./Components/OurBlog/Card1/Card3";
import TermsAndConditions from "./Components/Include/FooterTabs/TermsAndConditions";
import PrivacyPolicy from "./Components/Include/FooterTabs/PrivacyPolicy";
import { useState } from "react";
import Loader from "./Utils/Loader";
import OrderConfirmation from "./Components/OrderConfirmation/OrderConfirmation";
import Profile from "./Components/Auth/Profile";
import NotFound from "./Components/NotFound/NotFound";
import VedioViews from "./Components/MyAccount/VedioViews";

function MyComponent() {
  const [loader, setLoader] = useState(true)
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  React.useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  localStorage.setItem("constLang", JSON.stringify("en"))
  // localStorage.setItem("lang", JSON.stringify("hi"))
 

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLoader(false); // Set loading to false after delay
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Cleanup function
  }, [])
  return (
    <>
  
      {
        loader ? (<Loader />) : (<>
          <Routes>

            <Route path='/' element={<Home />} />
            {/* <Route path='/home' element={<Home />} /> */}
            <Route path='/About' element={<About />} />
            <Route path='/SignUp' element={<SignUp />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/complete-profile' element={<Profile />} />
            <Route path='/ProfileDetails' element={<ProtectedWrapper><ProfileDetails /></ProtectedWrapper>} />
            <Route path='/myBooking' element={<ProtectedWrapper><MyBooking /></ProtectedWrapper>} />
            <Route path='/ChangePassword' element={<ChangePassword />} />
            <Route path='/Contact' element={<ContactUs />} />
            <Route path='/Checkout' element={<CheckOut />} />
            <Route path='/CheckoutPayment' element={<ProtectedWrapper><CheckOutPayment /></ProtectedWrapper>} />
            <Route path='/Booking/:bookingId' element={<ProtectedWrapper><BookingConfirmed /></ProtectedWrapper>} />
            <Route path='/AllPujas/' element={<Allpujas />} />
            <Route path='/AllPujas/PujaDetails/:pooja_id' element={<PujaDetails />} />
            {/* <Route path='/home/PujaDetails/:pooja_id' element={<PujaDetails />} /> */}
            <Route path='/PujaDetails/:pooja_id' element={<PujaDetails />} />
            {/* <Route path='/AllPujas/:puja_Id/PujaDetails/:pooja_id' element={<ProtectedWrapper><PujaDetails /></ProtectedWrapper>} /> */}
            <Route path='/payment' element={<Payment />} />
            <Route path='/dhan' element={<Dhan />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/occasionpuja' element={<OccasionPuja />} />
            <Route path='/specialpooja' element={<SpecialPooja />} />
            <Route path='/nityapooja' element={<NityaPooja />} />
            <Route path='/gopooja' element={<GoPooja />} />
            <Route path='/ourblog/1' element={<Card1 />} />
            <Route path='/ourblog/2' element={<Card2 />} />
            <Route path='/ourblog/3' element={<Card3 />} />
            <Route path='/termsandconditions' element={<TermsAndConditions />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/order-confirmation' element={<OrderConfirmation />} />
            <Route path='/videos' element={<VedioViews />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Toaster />
        </>)
      }


    </>
  );
}

export default MyComponent
