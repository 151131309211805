import React, { useState } from 'react'
import Header from '../Include/Header'
import Footer from '../Include/Footer'
import { useLocation } from 'react-router-dom'
import DateImg from "../Images/Date.png"
import Navbar from '../Include/Navbar'


const Payment = () => {
   
    const { state } = useLocation()

    const { selectedPlan, poojaPlan } = state
    console.log(selectedPlan);
    console.log(poojaPlan);

    // console.log(poojaPlan?.filter(pooja => pooja?.id === Number(selectedPlan)));

    const priceFromPlan = poojaPlan?.filter(pooja=> pooja?.id === Number(selectedPlan))[0]?.price ?? 0
    const [finalAmount, setFinalAmount] = useState(Number(priceFromPlan))
    console.log(priceFromPlan);

    const addPriceToFinalAmount = (amount) => {
        setFinalAmount(finalAmount + amount)
    }

    return (
        <div>
            <Header />
            <Navbar />
            <div className='flex w-full p-5 gap-5'>
                {
                    selectedPlan !== null && <div className='w-[50%] font-semibold'>
                        <div className='my-2'>
                            <h1 className='text-xl ml-1 font-bold'>Selected Plan</h1>
                        </div>
                        <div>
                            {
                                poojaPlan?.filter(pooja => pooja?.id === Number(selectedPlan))?.map((items, index) => {
                                    return (
                                        <div
                                            key={items.id}
                                            className="border-2 rounded-lg p-3 mt-3 flex gap-2 shadow-md"
                                        >
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="font-bold text-xl">{items.name}</h3>
                                                </div>
                                                <div className=" pt-2">
                                                    <p className="text-sm">{items.description}</p>
                                                </div>
                                                <div className="pt-2">
                                                    <h3 className="text-sm font-semibold">
                                                        Total{" "}
                                                        <span className="font-bold text-xl text-orange-600">
                                                            {items.price}
                                                        </span>
                                                    </h3>
                                                    <p className="text-xs">
                                                        (Including all taxes and booking fees)
                                                    </p>
                                                </div>
                                                <div className="pt-2 flex justify-between">
                                                    <div className="flex items-center gap-1">
                                                        <img src={DateImg} alt="" />
                                                        <h3 className="text-sm font-semibold">Puja Date : </h3>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm font-bold">{items.date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex justify-start flex-col">
                                                {/* <input type="radio" onChange={handlePlanSelection} value={items.id} name='package_id' /> */}

                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                <div className='w-[50%] font-semibold'>
                    <div className='flex flex-col p-2'>
                        <div className='my-2'>
                            <h1 className='text-xl ml-1 font-bold'>Add On</h1>
                        </div>
                        <div className='grid grid-cols-1 gap-2'>
                            <div className='flex justify-around items-center gap-5 shadow-md p-3 border-2 rounded-lg'>
                                <div className='w-[25%] flex justify-center items-center p-2'>
                                    <img src={DateImg} alt="api" />
                                </div>
                                <div className='flex gap-5 w-[50%] justify-between p-2'>
                                    <h1>Kubera Nidi Prapthi</h1>
                                    <p>551</p>
                                </div>
                                <div className='w-[25%] text-center p-2'>
                                    <button className='w-full p-2 rounded bg-orange-500 text-white' onClick={()=>addPriceToFinalAmount(500)}>Add</button>
                                </div>
                            </div>

                            <div className='flex justify-around items-center gap-5 shadow-md p-3 border-2 rounded-lg'>
                                <div className='w-[25%] flex justify-center items-center p-2'>
                                    <img src={DateImg} alt="api" />
                                </div>
                                <div className='flex gap-5 w-[50%] justify-between p-2'>
                                    <h1>Vashikaran</h1>
                                    <p>1100</p>
                                </div>
                                <div className='w-[25%] text-center p-2'>
                                    <button className='w-full p-2 rounded bg-orange-500 text-white' onClick={()=>addPriceToFinalAmount(500)}>Add</button>
                                </div>
                            </div>


                            <div className='flex justify-around items-center gap-5 shadow-md p-3 border-2 rounded-lg'>
                                <div className='w-[25%] flex justify-center items-center p-2'>
                                    <img src={DateImg} alt="api" />
                                </div>
                                <div className='flex gap-5 w-[50%] justify-between p-2'>
                                    <h1>Prem Samband</h1>
                                    <p>551</p>
                                </div>
                                <div className='w-[25%] text-center p-2'>
                                    <button className='w-full p-2 rounded bg-orange-500 text-white' onClick={()=>addPriceToFinalAmount(500)}>Add</button>
                                </div>
                            </div>

                            <div className='flex justify-around items-center gap-5 shadow-md p-3 border-2 rounded-lg'>
                                <div className='w-[25%] flex justify-center items-center p-2'>
                                    <img src={DateImg} alt="api" />
                                </div>
                                <div className='flex gap-5 w-[50%] justify-between p-2'>
                                    <h1>Dakshina to poojari</h1>
                                    <p>551</p>
                                </div>
                                <div className='w-[25%] text-center p-2'>
                                    <button className='w-full p-2 rounded bg-orange-500 text-white' onClick={()=>addPriceToFinalAmount(500)}>Add</button>
                                </div>
                            </div>

                            <div className='flex justify-around items-center gap-5 shadow-md p-3 border-2 rounded-lg'>
                                <div className='w-[25%] flex justify-center items-center p-2'>
                                    <img src={DateImg} alt="api" />
                                </div>
                                <div className='flex gap-5 w-[50%] justify-between p-2'>
                                    <h1>Prasad Delivery</h1>
                                    <p>551</p>
                                </div>
                                <div className='w-[25%] text-center p-2'>
                                    <button className='w-full p-2 rounded bg-orange-500 text-white' onClick={()=>addPriceToFinalAmount(500)}>Add</button>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className='w-full my-3'>
                        <h1 className='p-2'>Total Amount: {finalAmount}</h1>
                        <button className='float-end  p-2 rounded bg-orange-500 text-white mr-3' >Make Payment</button>
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <Footer />
            </div>
        </div>
    )
}

export default Payment