import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../Images/Dev Kirpa Loader.gif";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { errorMessage } from "../../Utils/notificationManger";
import pujaJson from "./Puja.json"

const LikePujas = () => {
  const [likePujas, setLikePujas] = useState();
  const [slidesToShow, setSlidesToShow] = useState(4); // State for controlling slidesToShow
  const [cta1, setCta1] = useState("")
  const constLang = JSON.parse(localStorage.getItem("constLang"))

  const lang = JSON.parse(localStorage.getItem("lang"))


  const GetlikePujas = async () => {
    await axios
      .get("https://devkirpa.com/admin/api/pooja-like-list")
      .then((res) => {
        if (res?.status === 200) {
          setLikePujas(res.data);
        } else {
          setLikePujas([])
        }
      });
  };

  useEffect(() => {
    GetlikePujas();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(4);
      }
    };


    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };

  const getTranslatedCta1 = async (text) => {
    if (constLang !== lang) {
      try {
        const response = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${constLang}|${lang}`)

        setCta1(response?.data?.matches);
      } catch (error) {
        errorMessage(error?.response?.data?.message)
      }
    }
  }

  useEffect(() => {
    getTranslatedCta1("View detail")
  }, [lang])
  return (
    <section className="">
      <div className="flex  w-full max-w-[100%] flex-col max-md:max-w-full max-md:my-10">
        <h1 className=" px-3 py-2 flex justify-start items-center text-4xl font-bold  max-md:max-w-full max-md:text-3xl font-akshar">
          {lang === "hi" ? pujaJson?.["heading-two"]?.en : pujaJson?.["heading-two"]?.hi}
        </h1>
      </div>
      <div className="pb-5 w-[100%] bg-white likePujaSlider">
        {
          !likePujas?.list?.length && <div className="flex justify-center items-center text-orange-600 text-2xl font-semibold w-auto animate-pulse h-60" data-aos="fade-up">{lang === "hi" ? pujaJson?.not_found?.en : pujaJson?.not_found?.hi}</div>
        }
        {likePujas ? (
          <Slider {...settings}>
            {likePujas && likePujas?.list?.map((item, index) => {
              return (
                <div key={index} className="w-[21%] max-sm:w-full shadow rounded bg-white ">
                  <div
                    className="flex justify-center items-center"
                    style={{ height: "200px" }}
                  >
                    <img
                      className="object-cover object-center w-[100%] h-full"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      src={item?.image}
                      alt={item?.title}
                    />
                  </div>
                  <div className="flex justify-start flex-col gap-3 m-2 max-sm:justify-center">
                    <h1 className={`text-md line-height-2 line-clamp-1 font-mono uppercase text-black ${lang === "en" && "pt-2"}`}>{lang === "hi" ? item?.name?.en : item?.name?.hi}</h1>
                    {/* <p className="text-sm description">{item.name}</p> */}
                    <div className="flex items-center gap-2 w-[100%]">
                      <del
                        className="text-normal text-red-600"
                        style={{ fontWeight: "500" }}
                      >
                        ₹{item.actual_price}
                      </del>
                      <span
                        className="text-green-600 text-xl"
                        style={{ fontWeight: "700" }}
                      >
                        {" "}
                        ₹{item.actual_price}
                      </span>
                    </div>
                    <Link to={"PujaDetails/" + item.id}>
                      <button className="w-[60%] bg-orange-500 p-2 text-white rounded">
                        {lang==="hi" ? "View Detail" : "विस्तार से देखें"}
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : (
          <div className="loader">
            <div>
              <img src={Loader} alt="" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default LikePujas;