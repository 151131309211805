import React, { useEffect, useState } from "react";
import axios from "axios";
import { errorMessage, successMessage } from "../../Utils/notificationManger";
import AppointmentJson from "./Appointment.json"

const Appointment = () => {
  //  --------{  Api for the services in the contact form   }-----------//

  const [services, setServices] = useState([]);
  
  const lang = JSON.parse(localStorage.getItem("lang"))
 

  const GetServicesData = async () => {
    try {
      const res = await axios.get(
        "https://devkirpa.com/admin/api/web-service"
      );
      if (Array.isArray(res.data.list)) {
        setServices(res.data.list);
      } else {
        console.log("service data is not an array:", res.data.list);
      }

      setServices(res.data.list);
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("Error submitting form:", error.response.data);
      } else {
        console.error("Error submitting form:", error.message);
      }
    }
  };






  useEffect(() => {
    GetServicesData();
  }, []);


  //  --------------{  Api for Sending data through Appointment form   }------------------//

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    service_id: "",
    date: "",
  });

  const handleSubmitForm = async (values) => {
    try {
      const res = await axios.post(
        "https://devkirpa.com/admin/api/user-Booking/services",
        values
      );
      console.log("Form submitted successfully:", values);
      successMessage(res?.data?.message)
      // window.alert("Contact Form submitted successfully");

    } catch (error) {
      // console.error("Error submitting form:", error.error);
      errorMessage(error?.response?.data?.errors[0]?.message || error?.response?.data?.errors[1]?.message || error?.response?.data?.errors[2]?.message || error?.response?.data?.errors[3]?.message || error?.response?.data?.errors[4]?.message || error?.response?.data?.errors[5]?.message || error?.response?.data?.error)
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!values?.first_name) {
      errorMessage("first name is required")
      return
    }
    if (!values?.last_name) {
      errorMessage("first name is required")
      return
    }
    if (!values?.phone) {
      errorMessage("phone is required")
      return
    }
    if (!values?.email) {
      errorMessage("email is required")
      return
    }
    if (!values?.service_id) {
      errorMessage("Service is required")
      return
    }
    if (!values?.date) {
      errorMessage("Date is required")
      return
    }
    handleSubmitForm(values);
  };

  return (
    <section className="bg-orange-100 flex justify-center items-center  w-full  font-akshar text-black p-2 ">
      <div className="max-sm:mx-0 w-[80%] flex  justify-center items-center max-sm:flex-col max-lg:flex-col max-lg:w-full max-lg:mx-0">
        <div className="items-start gap-2 flex flex-col  p-5 w-[30%] max-md:max-w-full max-md:mt-10 Appointment_res2 max-lg:w-full max-lg:items-center">
          <h1 className="flex max-lg:text-center max-lg:w-full max-lg:hidden text-orange-600 text-lg font-bold uppercase max-md:max-w-full" data-aos="fade-left">
            {lang === "hi" ? AppointmentJson?.title?.en : AppointmentJson?.title?.hi}
          </h1>
          <p className="flex justify-center items-center text-black text-6xl max-lg:text-4xl font-bold  uppercase max-md:max-w-full max-md:text-4xl max-md:leading-[63px] max-lg:w-full" data-aos="fade-left">
            {lang === "hi" ? AppointmentJson?.subtitle?.en : AppointmentJson?.subtitle?.hi}
          </p>
        </div>
        <div className="w-[70%] max-sm:w-full p-2 flex justify-center items-center flex-col max-sm:p-0 max-md:p-2">
          <form
            onSubmit={onSubmit}
            className="grid grid-cols-2 justify-center items-center w-full gap-5 p-5 max-sm:w-[100%]  max-sm:grid-cols-1 max-sm:p-0"
          >
            <div className="flex flex-col border-orange-700 justify-center items-start w-[100%] gap-3" data-aos="fade-right">
              <label className=" text-black mx-1 font-bold max-md:m-0 uppercase">     {lang === "hi" ? AppointmentJson?.firstname?.en : AppointmentJson?.firstname?.hi}</label>
              <input
                value={values.first_name}
                onChange={handleChange}
                className="outline-2 focus:outline-orange-500 p-2 w-[100%] border-2  max-md:w-full max-sm:w-[100%] "
                type="text"
                name="first_name"
                id="firstname"
                placeholder={lang === "hi" ? AppointmentJson?.placeholders?.firstName?.en : AppointmentJson?.placeholders?.firstName?.hi}
              />
            </div>
            <div className="flex flex-col w-[100%] gap-3" data-aos="fade-right">
              <label className="w-full  text-black mx-1 font-bold uppercase">
                {lang === "hi" ? AppointmentJson?.lastname?.en : AppointmentJson?.lastname?.hi}
              </label>
              <input
                value={values.last_name}
                onChange={handleChange}
                className=" w-[100%] outline-2 focus:outline-orange-500 p-2 border-2    max-md:w-full max-sm:w-[100%]"
                type="text"
                name="last_name"
                id="lastname"
                placeholder={lang === "hi" ? AppointmentJson?.placeholders?.lastName?.en : AppointmentJson?.placeholders?.lastName?.hi}
              />
            </div>
            <div className="flex flex-col w-[100%] gap-3" data-aos="fade-right">
              <label className="w-full  text-black mx-1 font-bold uppercase">
                {lang === "hi" ? AppointmentJson?.phone?.en : AppointmentJson?.phone?.hi}
              </label>
              <input
                value={values.phone}
                onChange={handleChange}
                className=" w-[100%] outline-2 focus:outline-orange-500 p-2 border-2   max-md:w-full max-sm:w-[100%]"
                type="text"
                name="phone"
                id="number"
                placeholder={lang === "hi" ? AppointmentJson?.placeholders?.phone?.en : AppointmentJson?.placeholders?.phone?.hi}
              />
            </div>
            <div className="flex flex-col w-[100%] gap-3" data-aos="fade-right">
              <label className="w-full  text-black mx-1 font-bold uppercase">
                {lang === "hi" ? AppointmentJson?.email?.en : AppointmentJson?.email?.hi}
              </label>
              <input
                value={values.email}
                onChange={handleChange}
                className=" w-[100%] outline-2 focus:outline-orange-500  border-2 p-2  max-md:w-full max-sm:w-[100%]"
                type="email"
                name="email"
                id="email"
                placeholder={lang === "hi" ? AppointmentJson?.placeholders?.email?.en : AppointmentJson?.placeholders?.email?.hi}
              />
            </div>
            <div className="flex flex-col w-[100%] gap-3" data-aos="fade-right">
              <label className="w-full  text-black mx-1 font-bold uppercase">
                {lang === "hi" ? AppointmentJson?.service?.en : AppointmentJson?.service?.hi}
              </label>
              <select
                onChange={handleChange}
                // onChange={(e) => handleChange(e)}
                className="  w-[100%] outline-2 focus:outline-orange-500 p-2 border-2  max-md:w-full max-sm:w-[100%]"
                value={values.service_id}
                name="service_id"
                id="service_id"
                placeholder="choose service"
              >
                <option value="">{lang === "hi" ? AppointmentJson?.select_services?.en : AppointmentJson?.select_services?.hi}
                </option>
                {services.map((items) => {
                  return (

                    <option key={items.id} value={items.id}>
                      {lang === "hi" ? items?.title?.en : items?.title?.hi}
                    </option>

                  )
                })}
              </select>
            </div>
            <div className="flex flex-col w-[100%] gap-3" data-aos="fade-right">
              <label className="w-full  text-black mx-1 font-bold uppercase">{lang === "hi" ? AppointmentJson?.date?.en : AppointmentJson?.date?.hi}</label>
              <input
                value={values.date}
                onChange={handleChange}
                className=" w-[100%] outline-2 focus:outline-orange-500 p-2 border-2  max-md:w-full max-sm:w-[100%]"
                type="date"
                id="date"
                name="date"
              />
            </div>
            <div className="flex flex-col w-[100%] gap-3" >
              <button
                className="bg-orange-600 max-lg:py-1 py-3 w-full text-white font-bold mr-10 mb-6 hover:scale-95 duration-300 hover:shadow-xl max-md:w-full max-sm:w-full"
                type="submit"
                data-aos="zoom-in"
              >
                {lang === "hi" ? AppointmentJson?.button?.en : AppointmentJson?.button?.hi}
              </button>
            </div>

          </form>

        </div>
      </div>
    </section>
  );
};

export default Appointment;
