import React, { useState } from "react";
import userlogo from "../SVG/user.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import HeadJson from "./Head.json"
import { AiOutlineLogin } from 'react-icons/ai'
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCartCheckFill } from "react-icons/bs";


const Header = () => {
  const { state } = useAuth();
  const [menu, setMenu] = useState(false)
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)


  const lang = JSON.parse(localStorage.getItem("lang"))
  const initialLang = lang ? lang : "en"; // Default to "en" if no lang is set

  const [language, setLanguage] = useState(initialLang);


  const handleChangeLanguage = (e) => {
    e.preventDefault()
    // console.log(e);
    const selectedLang = e.target.textContent;
    console.log(selectedLang);
    if (selectedLang === "English") {
      localStorage.setItem("lang", JSON.stringify("hi"))
      window.location.reload()
      setMenuOpen(false)
    }
    else {
      localStorage.setItem("lang", JSON.stringify("en"))
      window.location.reload()
      setMenuOpen(false)
    }
    setLanguage(selectedLang);


    // console.log(selectedLang);




  }




  return (
    <section className="w-full flex justify-center items-center px-2">
      <div className="w-full flex justify-end items-center max-sm:w-full max-sm:justify-between max-sm:gap-5 max-lg:w-full mr-10 max-sm:mr-1">
        <div className="flex justify-center items-center w-[18%]  py-3 focus:outline-none max-md:max-w-full max-sm:w-full">
          {/* <select className="w-[70%] max-sm:w-full border-2  bg-white focus:border-[#f15a24] py-1 max-sm:py-0 font-poppins rounded bg-inherit text-[#f15a24] border-[#f15a24]" onChange={handleChangeLanguage} value={language} >
            <option value="en">English</option>
            <option value="hi">Hindi</option>

          </select> */}
        </div>
        <div className="mr-3 relative py-3">
          <div className="flex gap-2 w-32 text-xl font-semibold border-2 px-3 rounded text-black py-1 justify-between border-[#f15a24]">
            <h1 className="font-normal cursor-pointer" onClick={() => setMenuOpen(!menuOpen)}>{language === "hi" ? "English" : "हिंदी"}</h1>
            {
              menuOpen ? <MdKeyboardArrowUp className="text-xl" /> : <MdKeyboardArrowDown className="text-xl" />
            }
          </div>
          <div className={`absolute top-16 z-10 w-full flex flex-col gap-2 bg-white rounded shadow-lg px-2 py-2 text-center ${menuOpen ? "flex" : "hidden"}`} data-aos="">
            <p className="cursor-pointer border-b-2 pb-2  hover:text-[#f15a24] " onClick={handleChangeLanguage}>हिंदी</p>
            <p className="cursor-pointer px-0 hover:text-[#f15a24]" onClick={handleChangeLanguage}>English</p>

          </div>
        </div>
        {/* <div>
          <button type="button" className="flex justify-center gap-2 mr-2 max-sm:m-0 items-center font-poppins bg-orange-600 text-light px-3 py-1 rounded max-md:max-w-full max-sm:w-32 max-sm:hidden" onClick={() => navigate("/cart")}><BsCartCheckFill />{lang === "hi" ? HeadJson?.["drop-down-one"]?.["option-one"]?.en : HeadJson?.["drop-down-one"]?.["option-one"]?.hi}</button>
        </div> */}
        <div>
          {state.isAuthenticated ? (

            <div className="font-poppins w-full">
              <button className="flex justify-center gap-2 items-center bg-[#f15a24] text-light px-3 py-1 rounded max-sm:text-xs  max-md:max-w-full max-sm:w-40" onClick={() => setMenu(!menu)}>
                <img src={userlogo} alt="" />
                {lang === "hi" ? HeadJson?.["drop-down-one"]?.title?.en : HeadJson?.["drop-down-one"]?.title?.hi}
              </button>

              <div className={`absolute w-36 bg-[#fff4f1] z-10 rounded-lg  shadow-lg font-bold my-10 ${menu ? "flex" : "hidden"} hover:text-black flex-col gap-3 border-2 p-2 justify-center items-start text-start`}>
                {/* <p className="cursor-pointer hidden max-sm:block" onClick={() => navigate("/cart")}>{lang === "hi" ? HeadJson?.["drop-down-one"]?.["option-one"]?.en : HeadJson?.["drop-down-one"]?.["option-one"]?.hi}</p> */}
                <p onClick={() => navigate("/profileDetails")} className="cursor-pointer">{lang === "hi" ? HeadJson?.["drop-down-one"]?.["option-two"]?.en : HeadJson?.["drop-down-one"]?.["option-two"]?.hi}</p>
                <p onClick={() => navigate("/MyBooking")} className="cursor-pointer hidden max-sm:block">{lang === "hi" ? HeadJson?.["drop-down-one"]?.["option-four"]?.en : HeadJson?.["drop-down-one"]?.["option-four"]?.hi}</p>
                <p className="cursor-pointer" onClick={() => {
                  localStorage.clear()
                  navigate("/")
                  window.location.reload()
                }}>{lang === "hi" ? HeadJson?.["drop-down-one"]?.["option-three"]?.en : HeadJson?.["drop-down-one"]?.["option-three"]?.hi}</p>
              </div>
            </div>

          ) : (
            <Link to="/login" >
              {" "}
              <button className="flex justify-center gap-2 mr-28 max-sm:m-0 items-center font-poppins bg-orange-600 text-light px-3 py-1 rounded max-md:max-w-full">
                <AiOutlineLogin />
                Login
              </button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;