import React, { useState } from 'react'
import LoginImg from "../Images/Rectangle 1089.png";
import Input from './Constant/Inputs';
import { errorMessage } from '../../Utils/notificationManger';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Profile = () => {

    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        city: ""
    })

    const [file, setFile] = useState(null)
    const navigate = useNavigate()

    const onChange = (e) => {
        e.preventDefault()

        setInputs({ ...inputs, [e.target.name]: e.target.value })

        if (e.target.name === "file") {
            setFile(e.target.files[0])
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append("name", inputs?.name)
        formData.append("email", inputs?.email)
        formData.append("phone", inputs?.phone)
        formData.append("city", inputs?.city)
        formData.append("address", inputs?.address)
        formData.append("profile_pic", file)
        formData.append("user_id", JSON.parse(localStorage.getItem("user_id")))

        try {
            const response = await axios.post("https://devkirpa.com/admin/api/update-user-profile", formData)
            const data = await response?.data
            if(response?.status === 200){
                navigate("/login")
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        }

    }

    return (
        <div class="flex justify-center items-center w-[70%] mt-10 mx-48">
            <div class="flex w-full flex-row justify-between items-center border-2">
                <div className="w-[48%] h-full">
                    <img src={LoginImg} alt="" className='h-full' />
                </div>
                <div className="w-[58%]">
                    <div className="flex justify-end pr-2 pt-3">
                        <h5>English (UK)</h5>
                    </div>
                    <div className="flex-col flex justify-center items-center">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b66bc416af0876b82bdf381484c40f3137e3317d2c4d1fe9e32da3d312e10c54?apiKey=8478572201cf4b3f8647adfe69018175&"
                            className="aspect-[0.77] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full"
                            alt=""
                        />
                    </div>
                    <div class="modal-header">
                        <div
                            id="exampleModalToggleLabel"
                            className="modal-title  flex flex-col justify-center items-center py-3 w-[100%] max-md:max-w-full max-md:mt-10"
                        >
                            <h1 className="flex justify-center items-center text-orange-600 text-2xl  font-bold uppercase max-md:max-w-full">
                                Complete Profile
                            </h1>
                        </div>
                    </div>
                    <form class="" onSubmit={onSubmit}>
                        <div className="grid grid-cols-2 gap-3 w-full place-items-center">
                            <Input labelName="Name" type="text" onChange={onChange} name="name" id="name" title="Name" placeholder="Enter  Name" value={inputs?.name} />
                            <Input labelName="Email" type="email" onChange={onChange} name="email" id="email" title="Email" placeholder={"Enter  Email"} value={inputs?.email} />
                            <Input labelName="phone" type="text" onChange={onChange} name="phone" id="phone" title="Phone" placeholder={"Enter Phone Number"} value={inputs?.phone} />
                            <Input labelName="City" type="text" onChange={onChange} name="city" id="city" title="City" placeholder={"Enter City"} value={inputs?.city} />
                            <Input labelName="Address" type="text" onChange={onChange} name="address" id="address" title="Address" placeholder={"Enter Address"} value={inputs?.address} />

                            <div className='w-48'>
                                <Input labelName="Upload Profile" type="file" onChange={onChange} name="file" id="file" title="Address" placeholder={"Upload File"} />

                            </div>


                        </div>
                        <button
                            type="submit"
                            className="border-2 mx-11 mt-3 border-orange-600 top-8 right-0 rounded-lg px-4 py-1"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Profile