import  Navigation  from '../Navigation/Navigation'
import React from 'react'

function Home() {
  return (
    <div className='w-full'>
      <Navigation/>
    </div>
  )
}

export default Home;