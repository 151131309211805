import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
// import { IoCloseSharp } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { errorMessage } from "../../Utils/notificationManger";
// import axios from "axios";
import Nav from "./Nav.json"
import { IoMdClose } from "react-icons/io";
import SearchComponent from "../Search/SearchComponent";



const Navbar = ({ setSearchValue }) => {
  // const [open, setOpen] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(true)
  const navigate = useNavigate()
  const [activeLink, setActiveLink] = useState("")
  const location = useLocation()
  // console.log(hamburgerOpen);
  // localStorage.setItem("lang", JSON.stringify("en"))


  const lang = JSON.parse(localStorage.getItem("lang"))

  useEffect(() => {
    if (location.pathname === "/AllPujas") {
      setActiveLink("puja");
    } else {
      setActiveLink("");
    }
  }, [location.pathname]);

  // console.log(lang);

  // console.log(home[0]?.translation);
  return (
    <section className=" w-full flex justify-center items-center max-sm:flex-col max-md:flex-col max-lg:flex-col max-sm:gap-5 shadow-md bg-inherit " data-aos="fade-down">
      <div className="w-full  flex justify-between items-center gap-5 max-sm:flex-col max-sm:w-full max-lg:flex-col max-md:flex-col">
        <div className="max-sm:w-full max-md:w-full max-lg:w-full w-full">
          <div className="flex px-20 max-sm:px-5 justify-start items-center max-sm:justify-between max-lg:justify-between max-md:justify-between max-sm:w-full ">
            <img
              loading="lazy"
              src="/Logo.jpg"
              className="object-cover w-28 h-28 rounded-full mix-blend-multiply cursor-pointer"
              alt="Logo"

              onClick={() => navigate("/")}
            />

            <p className="max-sm:flex  max-md:flex  max-lg:flex hidden text-4xl max-sm:mr-5 focus:text-orange-500 hover:text-orange-500" onClick={() => setHamburgerOpen(!hamburgerOpen)}>{hamburgerOpen ? <GiHamburgerMenu /> : <IoMdClose />}</p>
          </div>
        </div>
        <div className="w-full max-sm:hidden  max-sm:justify-center max-sm:items-center">
          {activeLink === "puja" && <SearchComponent setSearchValue={setSearchValue} />}

        </div>
        <div className={`flex justify-end items-end font-akshar w-full mr-10 text-black 
  max-md:justify-center max-lg:justify-center 
  max-md:m-0 max-lg:m-0 
  max-md:p-0 max-lg:p-0 
  max-lg:w-full 
  ${hamburgerOpen ? "max-sm:hidden max-lg:hidden max-md:hidden" : ""}`}
        >
          <nav >
            <ul className="flex w-full  gap-5 text-center justify-center items-center font-bold text-2xl max-md:flex-col max-lg:flex-col   max-sm:flex-col max-md:gap-2 max-lg:gap-2">

              <NavLink to="/" className={({ isActive, isPending }) =>
                isPending ? "text-2xl hover:text-[#f15a24] w-full" : isActive ? "py-1 w-full border-b-2 hover:text-[#f15a24] border-[#f15a24]  border-opacity-100 text-[#f15a24] duration-200 cursor-pointer text-2xl  max-sm:text-xs" : "w-full text-2xl hover:text-[#f15a24]"}>
                <li className="cursor-pointer w-full text-2xl" onClick={() => setActiveLink("")}>
                  {lang === "hi" ? Nav.home.en : Nav.home.hi}
                </li>
              </NavLink>

              <NavLink to='/AllPujas' className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "py-1 border-b-2 w-full border-[#f15a24] hover:text-[#f15a24] border-opacity-100 text-[#f15a24] duration-200 cursor-pointer max-sm:text-xs" : "hover:text-[#f15a24] w-full"}>
                <li className="w-full" onClick={() => setActiveLink("puja")}>
                  {lang === "hi" ? Nav.puja.en : Nav.puja.hi}
                </li>
              </NavLink>
              {/* <li>
                <a  href="#services">Services </a>
              </li> */}
              {/* <NavLink to='/dhan' className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "py-1 border-b-2 border-[#f15a24] hover:text-[#f15a24] border-opacity-100 text-[#f15a24] duration-200 cursor-pointer max-sm:text-xs w-full" : "hover:text-[#f15a24] w-full"}>
                <li onClick={() => setActiveLink("")}>
                  {lang === "hi" ? Nav.charity.en : Nav.charity.hi}
                </li>
              </NavLink> */}
              <NavLink to='/Contact' className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "py-1 border-b-2 border-[#f15a24] hover:text-[#f15a24] border-opacity-100 text-[#f15a24] duration-200 cursor-pointer max-sm:text-xs w-full mr-16" : "w-full hover:text-[#f15a24] mr-16"}>
                <li className="w-32 max-lg:w-full" onClick={() => setActiveLink("")}>
                  {lang === "hi" ? Nav.contact_us.en : Nav.contact_us.hi}
                </li>
              </NavLink>

            </ul>
          </nav>
        </div>
       
      </div>
    </section >
  );
};

export default Navbar;