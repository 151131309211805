import React, { useEffect, useState } from "react";
import Images from "../Images/Rectangle 30.png";
import { Link } from "react-router-dom";
// import axios from "axios";
// import { errorMessage } from "../../Utils/notificationManger";
import data from "./data.json"


function AboutUS() {
  const lang = JSON.parse(localStorage.getItem("lang"))
  
  const [loader, setLoader] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [])




  return (
    <section className="flex w-full py-5 flex-col text-black font-akshar">
      <div className="flex justify-center items-center p-2 max-md:w-full">
        <div className="w-[80%] flex justify-between items-center max-sm:w-full max-md:w-full max-sm:justify-center max-md:justify-center max-sm:flex-col max-md:flex-col px-20 max-sm:p-0 max-lg:w-full" data-aos="fade-down">
          <img
            loading="lazy"
            src={Images}
            className={`object-cover  ${loader ? "hidden" : "block"} max-sm:w-full max-sm:h-72 max-sm:px-4 max-lg:w-80 max-md:w-full overflow-hidden w-96 max-md:p-10`}
           
            alt="about-us"  
            onLoad={()=>setLoader(false)}
          />
          <div className="flex w-[30%] justify-start  max-sm:text-center  flex-col max-sm:w-full max-md:w-full text-start  max-sm:justify-center max-sm:items-center max-md:text-center gap-3 max-md:justify-center max-md:items-center">
            <h1 className="text-[#f15a24] max-sm:text-center max-md:text-center  max-md:w-full text-lg mb-2 font-bold max-sm:mt-7  uppercase max-md:max-w-full max-sm:text-l ">
              {lang === "hi" ? data?.title?.en : data?.title?.hi}
            </h1>
            <div className="text-6xl text-start max-md:w-full max-lg:text-3xl max-sm:px-10 max-lg:w-full flex justify-center items-center max-sm:text-2xl max-sm:mx-10 uppercase text-black" >

              {lang === "hi" ? data?.subtitle?.en : data?.subtitle?.hi}
            </div>
            <Link to={"/About"}>
              <button className="px-5 py-2 bg-[#f15a24] text-white w-48 max-sm:w-full" >
                {lang === "hi" ? data?.button?.en : data?.button?.hi}
              </button>
            </Link>
          </div>
        </div>
       
      </div>
    </section>
  );
}

export default AboutUS;
