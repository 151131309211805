import React from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Special from "./SpecialPuja.json"
import Metadata from '../../../Meta/Metadata'

const SpecialPooja = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    return (
        <div>
             <Metadata
                title={`DEVKIRPA | Special Puja`}
                description="Book special pujas with DEV KIRPA for unique and significant life events. Bring divine blessings to your life."
                url={`https://devkirpa.com/specialpooja}`}
            />
            <Header />
            <Navbar />
            <div className='flex justify-center items-center flex-col w-full p-3 font-semibold'>
                <div className='w-[70%] p-2 flex flex-col gap-5'>
                    <div className='text-2xl'>
                        <h1>{lang === "hi" ? Special?.title?.en : Special?.title?.hi}</h1>
                    </div>
                    <div>
                        <p>{lang === "hi" ? Special?.description?.en : Special?.description?.hi}</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SpecialPooja