import React, { useEffect } from 'react'
import Header from '../Header'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./TermsAndConditions.css"
import Privacy from "./Privacy.json"
import Metadata from '../../../Meta/Metadata'

const PrivacyPolicy = () => {
    const lang = JSON.parse(localStorage.getItem("lang"))
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Metadata
                title={`DEVKIRPA | Privacy & Policy `}
                description="Devkirpa Privacy & Policy Page"
                url={`https://devkirpa.com/privacypolicy}`}
            />
            <Header />
            <Navbar />
            <div className='flex flex-col gap-3 mt-10 font-semibold my-10'>

                {/* <div className='mx-44'>
                    <h1>Privacy & Policy</h1>
                </div> */}

                {/* <div className='flex flex-col gap-2 mx-44'>
                    <h1>1. Introduction
                    </h1>
                    <p>The mobile application called ‘devkirpa is owned and managed by Devkirpa</p>
                    <p>It is committed to respecting your online privacy and recognizes your need for appropriate
                        protection and management of any information you share with it on the App. This privacy
                        policy (“Policy”) explains how it will collect, use, share and process information about the
                        services provided on the App.
                    </p>
                    <h1>2. Scope</h1>
                    <p>This Policy is an electronic record in the form of an electronic contract formed under the
                        Information Technology Act, 2000 and the rules made thereunder. This Policy does not
                        require any physical, electronic, or digital signature.</p>

                    <p>By accessing or using the App or giving us your information or otherwise clicking to accept
                        this Policy, if and when prompted on the App, you undertake that you can enter into a
                        legally binding contract video. This Policy, constitutes a legally binding document between
                        you and it under applicable law, in particular, the Indian Contract Act, 1872. It will collect
                        and process your personal and third-party data carefully, only for the purposes described in
                        this Policy and only to the extent necessary as defined therein and within the scope of the
                        applicable legal regulations. This Policy seeks to ensure that any personal information or
                        third-party information handled by it is managed in a way that is ethical, compliant, and
                        adheres to best industry practices.</p>

                    <h1>3. Consent</h1>

                    <p>By mere use of the App, you expressly consent to it ’s use and disclosure of your Personal
                        Information (as defined below) and third-party information by this Policy. If you do not agree
                        with the terms of this Policy, please do not use the App.</p>

                    <p>In case you wish to avail of any or all of the services provided by it on the App, you may be
                        required to register on the App and thereafter access the App using the login credentials
                        provided by you at the time of registration as set out in the EULA (as applicable to you)
                        (“Login Credentials”). You hereby explicitly agree that your use of the Login Credentials
                        shall be governed by the EULA applicable to you read with the terms of this Policy.
                    </p>

                    <h1>4. Types of Information</h1>

                    <p>Personal Information is defined as any information that relates to a natural person, which,
                        either directly or indirectly, in combination with other information available or likely to be
                        available with a body corporate, is capable of identifying such person.

                    </p>

                    <p>Personal Information: Personal Information means any information that may be used to
                        identify an individual, including, but not limited to, the first and last names, telephone
                        number, e-mail address, or any other contact information, date of birth, age, gender, or any
                        other information, an image of yourself, date, time and place of birth (applicable in case of
                        User as defined in the EULA), official identifiers like PAN and Aadhar Card (applicable in
                        case of Astrologers as defined in the EULA), financial information including bank account
                        details, credit/debit card details or such other payment information (hereinafter referred to
                        as “Personal Information”). Further, Sensitive Personal Data or Information (“SPDI”) of a
                        person includes Personal Information about that person relating to passwords, financial
                        information (bank accounts, credit and debit cards, or other payment instruments), and
                        physical information. It limits the collection of Personal Information (including, SPDI) to
                        that which is necessary for its intended purpose.</p>

                    <p>Non-Personal Information: Non-personal information means information that does not
                        specifically identify an individual, but includes information from you, such as your browser
                        type, your Internet Service Provider (ISP), operating system, and your Internet Protocol (IP)
                        address. It may gather any non-personal information regarding how many people visit the
                        App, their IP address, browser types and versions, time zone settings and locations,
                        operating systems, applications installed on your device, device ID, device manufacturer
                        and type, device, connection information, screen resolution, usage statistics, default
                        communication applications and other technology on the devices you use to access the
                        App, and shall include all such Personal Information collected and stored by that
                        undergoes the process of de-identification and is no more identifiable to you or any natural
                        person (hereinafter referred to as “Non-Personal Information”). It may also collect NonPersonal Information that you voluntarily provide, such as information included in response
                        to a questionnaire or a survey conducted by .</p>

                    <p>Usage Information: Usage Information includes without limitation all data and information
                        collected automatically through the App (or through the third-party analytics service
                        providers), by use and access of the App like system administrative data, statistical and
                        demographical data, and operational information and data generated by or characterizing
                        use of the App including without limitation Non-Personal Information, cookies, App traffic,
                        time spent on the App, number of visits to the App and other similar information and
                        behavior indicating the mode and manner of use of the App (hereinafter referred to as the
                        “Usage Information”).
                    </p>

                    <p>Personal Information, SPDI, Non-Personal Information, and Usage Information hereinafter
                        shall be referred to as “Information”</p>

                    <h1>5. Collection of Information
                    </h1>

                    <p>It may collect information from you when you (a) register on the App; (b) update or change
                        personal details in your account; (c) use the App for providing/ availing any Services (as
                        applicable and as defined in the EULA applicable to you) being provided; (d) voluntarily
                        participate in campaigns conducted by it on the App or respond to questionnaires
                        published by it on the App (if any); € voluntarily complete a survey or provide feedback
                        about the services provided on the App; and (f) carry out any other transactions on the App.</p>

                    <p>You hereby acknowledge and agree that all information is provided by you to it voluntarily
                        and the Information provided by you is not subject to any undue influence.</p>

                    <h1>6. Use of Information</h1>

                    <p>It uses the Information you provide to (a) manage your account; (b) fulfill your requests for
                        the services offered on the App; (c) respond to your inquiries about its offerings and the
                        transactions carried out on the App; (d) provide you with information about services
                        available on the App and offer you other products and services that it believes may be of
                        interest to you; € resolve any glitches on the App including addressing any technical
                        problems; (f) improve the services and content on the App and your experience of
                        navigating through the App and carrying out transactions on the App; and (g) manage ’s
                        relationship with you.
                    </p>

                    <h1>7. Information Sharing</h1>

                    <p>It maintains your Information in electronic form on its devices and on the equipment of its
                        employees. The Information is made accessible to employees, agents or partners, and
                        third-parties only on a need-to-know basis.
                    </p>

                    <p>It does not rent, sell, or share Information with other people or with other non-affiliated
                        entities, except with your consent or to provide services you have requested for or under
                        the following circumstances:</p>

                    <p>It may share Information with it partners, and affiliates to contact you via. Email, phone, or
                        otherwise for the provision of services being availed by you on the App. Where you are a
                        User, may share Information with Astrologers (as defined in the Users EULA) for facilitating
                        the provision of services by the Astrologers to you.
                    </p>

                    <p>It may engage third-party vendors and/or contractors to perform certain support services,
                        who may have limited access to Information.</p>

                    <p>To process your transactions, it may direct you to a payment gateway service provider of its
                        own choice, which may have access to the Information provided by you while making such
                        payment.
                    </p>

                    <p>It may rent, sell or share Non-Personal Information or Personal Information in an aggregate
                        form after it undergoes the process of de-identifications and is no more identifiable to you,
                        with any third party.</p>

                    <p>It may share Information with government authorities in response to subpoenas, court
                        orders, or other legal process; to establish or exercise legal rights; to defend against legal
                        claims; or as otherwise required by law. This may be done in response to a law
                        enforcement agency’s request.
                    </p>

                    <h1>8. Third Party Service Providers</h1>

                    <p>It may engage other third-party vendors and/or contractors to perform certain support
                        services, including, without limitation, fulfillment of your service requests, software
                        maintenance services, advertising, and marketing services, web hosting services, and such
                        other related services which are required by it to provide its services efficiently. These third
                        parties may have limited access to Information. If they do, this limited access is provided
                        so that they may perform these tasks for it and they are not authorized by it to otherwise
                        use or disclose Information, except to the extent required by law. It does not make any
                        representations concerning the privacy practices or policies or terms of use of such
                        websites, nor does it control or guarantee the accuracy, integrity, or quality of the
                        information, data, text, software, music, sound, photographs, graphics, videos, messages
                        or other materials available on such websites. The inclusion or exclusion does not imply
                        any endorsement by it of the website, the website’s provider, or the information on such
                        website.
                    </p>

                    <p>The App may contain links and Inte”acti’e functionality interacting with the websites of third
                        parties. It is not responsible for and has no liability for the functionality, actions, inactions,
                        privacy settings, privacy policies, terms, or content of any such websites. Before enabling
                        any sharing functions to communicate with any such websites or otherwise visiting any
                        such websites, it strongly recommends that you review and understand the terms and
                        conditions, privacy policies, settings, and information-sharing functions of each such thirdparty website</p>

                    <h1>9. Disclosure to Acquirers</h1>

                    <p>It may disclose and/or transfer information to an investor, acquirer, assignee, or other
                        successor entity in connection with a sale, merger, or reorganization of all or substantially
                        all of its equity, business, or assets.</p>

                    <h1>10.Control Over Your Personal Information
                    </h1>

                    <p>You have the right to withdraw your consent at any point, provided such withdrawal of the
                        consent is intimated to it. </p>

                    <p>Once you withdraw your consent to share the Personal Information, collected by it and/or
                        delete/deactivate your Account (as applicable), it shall have the option not to fulfill the
                        purposes for which the said Personal Information was sought and it may restrict you from
                        using the services on the App and/or the App itself.
                    </p>

                    <h1>11.Rectification/Correction of Personal Information</h1>

                    <p>You shall have the right to review your Personal Information, including SPDI (as applicable)
                        submitted by you on the App, and to modify or delete any Personal Information or SPDI
                        provided by you directly on the App. You hereby understand that any such modification or
                        deletion may affect your ability to use the App. Further, it may affect it’s ability to provide its
                        services to you.</p>

                    <p>It reserves the right to verify and authenticate your identity and your account information
                        and/or payment information to ensure accurate delivery of services through the App.
                        Access to or correction, updating, or deletion of your Personal Information and/or SPDI (as
                        the case may be) may be denied or limited by it. if it would violate another person’s rights
                        and/or is not otherwise permitted by applicable law.</p>

                    <p>If you need to update or correct your Personal Information that it may have collected to
                        offer you personalized services and offers, you may send updates and corrections to it at
                        citing the reason for such rectification of Personal Information. It will take all reasonable
                        efforts to incorporate the changes within a reasonable period.</p>

                    <h1>12. Term of Storage of Personal Information</h1>

                    <p>VAMA shall store your Personal Information at least for such period as may be required and
                        permitted by law or for a period necessary to satisfy the purpose for which the Personal
                        Information has been collected. These periods vary depending on the nature of the
                        information and your interactions with VAMA.</p>

                    <p>It may store Non-Personal Information and Usage Information received from you till such
                        time it requires provided such storage and retention is in accordance with applicable law.
                    </p>

                    <p>You agree that you will not submit any false information or any illegal or damaging content
                        to the App.</p>

                    <p>It reserves the right to terminate access to or the ability to interact with the App in response
                        to any concerns it may have about false, illegal, or damaging content, or for any other
                        reason, in its sole discretion</p>

                    <h1>13.Cookies</h1>

                    <p>It uses cookies and/or other tracking technologies to distinguish you from other users of
                        the services and to remember your preferences on the App. This helps to provide you with
                        a good experience when you use the services on the App and also allows to improve such
                        services. Cookies are text files places in your mobile phone, tablet, or other devices to
                        store your preferences. Cookies, by themselves, do not tell your e-mail address or other
                        personally identifiable information unless you choose to provide this information to it by,
                        for example, registering on the App. They are designed to hold a marginal amount of data
                        specific to a particular user. However, once you choose to furnish the App with personally
                        identifiable information, this information may be linked to the data stored in the cookie. It
                        uses cookies to understand App usage and to improve the content and offerings on the
                        App. For example, it may use cookies to personalize your experience on the App (e.g., to
                        recognize you by name when you return to the App) and save your password in passwordprotected areas. It may also use cookies to offer you products, programs, or services.
                        Cookies may be placed on the App by third parties as well, the use of which does not
                        control.</p>

                    <p>Session cookies are automatically deleted from your hard drive once a session ends, and
                        most cookies are session cookies. You may decline the cookies, however, if you decline the
                        cookies, you may be unable to use certain features on the App and you may be required to
                        re-enter your password frequently. You may opt to leave the cookie turned on.</p>

                    <h1>14.Protection of Information</h1>

                    <p>It has taken adequate measures to protect the security of Information and to ensure that
                        your choices for its intended use are honored. takes robust precautions to protect your
                        data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.
                    </p>

                    <p>It considers the confidentiality and security of your information to be of utmost importance.
                        It, therefore, uses industry standards, and physical, technical, and administrative security
                        measures to keep information confidential and secure, and will not share your information
                        with third parties, except as otherwise provided in this Policy. Please be advised that,
                        however, while strives to protect Information and privacy, cannot guarantee or warrant its
                        absolute security when Information is transmitted over the internet into the App. It will
                        periodically evaluate this necessity considering your privacy and our relationship while
                        keeping the applicable legislation in mind.</p>

                    <p>Access to your account on the App is via. Your Login Credentials, which are password
                        protected and this helps to secure your account information. You are solely responsible for
                        maintaining the confidentiality of your Login Credentials. To ensure the safety of your
                        Information, you are advised against sharing your Login Credentials with anyone. If you
                        suspect any unauthorized use of your account, you must immediately notify VAMA. You
                        shall be liable to indemnify for any loss suffered by due to such unauthorized use of your
                        account.</p>

                    <p>For any loss or theft of Information, due to unauthorized access to your device through
                        which you use the App or other reasons solely attributable to you, shall not be held liable
                        or responsible under any circumstance whatsoever. Further, shall not be responsible for
                        any breach of security or for any actions of any third parties or events that are beyond
                        reasonable control including but not limited to acts of government, computer hacking,
                        unauthorized access to computer data and storage devices, computer crashes, breach of
                        security and encryption, poor quality of Internet service or telephone service of the user,
                        etc.</p>

                    <h1>15.Minor & Usage On Behalf Of Another Person
                    </h1>

                    <p>It does not intend to attract anyone under the relevant age of consent to enter into binding
                        legal contracts under the laws of their respective jurisdictions. VAMA does not intentionally
                        or knowingly collect Personal Information through the App from anyone under that age. It
                        encourages parents and guardians to be involved in the online activities of minors to
                        ensure that no Personal Information is collected from a minor without their prior consent. If
                        you are using the App on behalf of someone else, including but not limited to, on behalf of
                        your minor child/children, you represent and warrant that you are authorized by such
                        person to accept this Policy on their behalf and to consent on behalf of such person to
                        VAMA’s use of such person’s Personal Information as described in this Policy.</p>

                    <h1>16. Limitation of Liability
                    </h1>

                    <p>Devkirpa shall not be liable to you for any loss of profit, production, anticipated savings,
                        goodwill or business opportunities or any type of direct or indirect, incidental, economic,
                        compensatory, punitive, exemplary, or consequential losses arising out of performance or
                        non-performance of its obligations under this Policy.</p>

                    <p>It is not responsible for any actions or inactions of any third parties that receive your
                        Information.</p>

                    <p>Notwithstanding anything contained in this Policy or elsewhere, shall not be held
                        responsible for any loss, damage, or misuse of your Information, if such loss, damage, or
                        misuse is attributable to a Force Majeure Event. The term “Force Majeure Event” shall
                        mean any event that is beyond reasonable control and shall include, without limitation,
                        sabotage, fire, flood, explosion, acts of God, civil commotion, strikes, lockouts or industrial
                        action of any kind, riots, insurrection, war, acts of government, computer hacking, civil
                        disturbances, unauthorized access to computer data and storage device, computer
                        crashes, breach of security and encryption, pandemic or national/state lockdown due to
                        any reason and any other similar events not within control and which is not able to
                        overcome.</p>

                    <h1>17.Opt-Out</h1>

                    <p>Once you register as a user on the App, you may receive communication, including but not
                        limited to messages on the App, Short Message Service (SMS), and phone calls from VAMA
                        on the registered mobile number and e-mails on your registered e-mail address (as
                        applicable). These messages, e-mails, and calls (as applicable) could relate to your
                        registration, transactions that you carry out through the App and promotions that are
                        undertaken by. You have the option to ‘opt-out’ of all newsletters and other general email
                        marketing communications from by way of links provided at the bottom of each mailer (as
                        applicable). respects your privacy and if you choose to not receive such mailers, shall take
                        all adequate steps to remove you from such lists. However, you will not be able to opt-out
                        of receiving administrative messages, customer service responses, or other transactional
                        communications.
                    </p>

                    <h1>18.Changes to this Policy
                    </h1>

                    <p>VAMA reserves the right to update, change or modify this Policy at any time, subject to the
                        terms contained herein. The Policy shall come to effect from the date of such update,
                        change, or modification.</p>

                    <h1>19.Governing Law and Dispute Resolution</h1>

                    <p>This Policy shall be governed by and interpreted and construed in accordance with the laws
                        of India. The place of jurisdiction shall exclusively be in New Delhi, India. In the event of any
                        dispute arising out of this Policy, the same shall be settled by binding arbitration conducted
                        by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and
                        Conciliation Act, 1996. The venue and seat of arbitration shall be New Delhi, India.</p>

                    <h1>20.Grievance Redressal Mechanism</h1>

                    <p>By the Information Technology Act, 2000 and Rules made thereunder, the name and
                        contact details of the Grievance Officer are provided below:</p>
                </div> */}

                {/* <div className='mx-44 flex justify-center items-center gap-4 flex-col'>
                    {Privacy?.data?.map((tit) => {
                        return (
                            <div className='flex flex-col justify-center items-start gap-3'>
                                <h1>{lang === "hi" ? tit?.title?.en : tit?.title?.hi}</h1>
                                {lang === "hi"
                                    ? tit?.description?.en.map((desc, index) => (
                                        <p key={index}>{desc}</p>
                                    ))
                                    : tit?.description?.hi.map((desc, index) => (
                                        <p key={index}>{desc}</p>
                                    ))}
                            </div>
                        )
                    })}
                </div> */}

                <div className='px-40 flex justify-start items-start gap-2 flex-col max-sm:px-10 max-md:px-20 max-lg:px-32 w-full'>
                    <div className='flex flex-col gap-3'>
                        <h1>{lang === "hi" ? Privacy?.policy?.title?.en : Privacy?.policy?.title?.hi}</h1>
                        <p>{lang === "hi" ? Privacy?.policy?.['sub-title']?.en : Privacy?.policy?.['sub-title']?.hi}</p>
                        <p>{lang === "hi" ? Privacy?.policy?.description?.en : Privacy?.policy?.description?.hi}</p>
                    </div>
                    {
                        Privacy?.privacy?.map((titlee) => {
                            return (
                                <div className='flex flex-col justify-start items-start gap-2'>
                                    <h1>{lang === "hi" ? titlee?.title?.en : titlee?.title?.hi}</h1>

                                    <div>
                                        <p>{lang === "hi" ? titlee?.description?.title?.en : titlee?.description?.title?.hi}</p>
                                    </div>



                                    <div className='w-full'>
                                        <ul className='w-full'>
                                            {
                                                lang === "hi" ? titlee?.lists?.en?.map((list, i) => (
                                                    <li key={i} className='list-disc'>{list}</li>
                                                )) : titlee?.lists?.hi?.map((list, i) => (
                                                    <li key={i} className='list-disc'>{list}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>

                                    <div>
                                        {
                                            titlee?.['description-two'] && <div className=''>
                                                <strong><i>Withdrawal of your Consent-</i></strong>
                                                <p>{lang === "hi" ? titlee?.['description-two']?.en : titlee?.['description-two']?.hi}</p>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        {
                                            titlee?.Note && <div>
                                                <b>Note :</b>
                                                <p>{lang === "hi" ? titlee?.Note?.en : titlee?.Note?.hi}</p>
                                            </div>
                                        }
                                    </div>


                                </div>
                            )
                        })
                    }
                </div>


            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy