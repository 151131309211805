import React, { useState, useEffect } from "react";
import axios from "axios";
import { errorMessage, successMessage } from "../../../../Utils/notificationManger";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from '../../../../Utils/Loader'
import pujaJson from "../../Puja.json"
import { convertDateToNumberFormat } from "../../../Constant/Function";


function PujaPlans(props) {

  // const PujaDetails = props.PujaDetails;
  const [pujaPlans, setPujaPlans] = useState([]);
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const location = useLocation()
  const [returnedFromProfile, setReturnedFromProfile] = useState(false)
  const getSelectedPlan = JSON.parse(localStorage.getItem("selectedPlan"))
  const [selectedPlan, setSelectedPlan] = useState(null || getSelectedPlan);
  const user_id = JSON.parse(localStorage.getItem("user_id"))
  const user_profile = JSON.parse(localStorage.getItem("profile_status"))
  const user = JSON.parse(localStorage.getItem("user"))
  const lang = JSON.parse(localStorage.getItem("lang"))
  // const [loader, setLoader] = useState(false)


  console.log(props);
  // console.log(location, "------location pujaplan");
  // console.log(user_profile);
  // console.log(pujaPlans);



  // const addPriceToFinalAmount = (charityId) => {
  //   setCharityId(prevIds => [...prevIds, charityId]);
  // }

  // useEffect(() => {
  //   console.log(charityId);
  // }, [charityId]);

  const GetPujaPlans = async () => {

    try {
      setLoader(true)
      const res = await axios.get(
        "https://devkirpa.com/admin/api/package-list"
      );
      if (Array.isArray(res.data.list)) {
        setPujaPlans(res.data.list);
      } else {
        console.log("plans data is not an array:", res.data.list);
      }

      setPujaPlans(res.data.list);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setPujaPlans([])
      console.log("Error:", error);
    }
  };

  // const getUserProfile = async () => {
  //   try {
  //     const response = await axios.get(`https://devkirpa.com/admin/api/web-edit-profile?user_id=${user_id}`)
  //     const data = await response?.data
  //     localStorage.setItem("userProfile", JSON.stringify(data?.user))
  //   } catch (error) {
  //     errorMessage(error?.response?.data?.message)
  //   }
  // }



  // const getCharities = async () => {
  //   try{
  //     const response = await axios.get("https://devkirpa.com/admin/api/charities")
  //     const data = await response?.data
  //     console.log(data);
  //   } catch(error){
  //     errorMessage(error?.response?.data?.message)
  //   }
  // }

  useEffect(() => {
    GetPujaPlans();

    localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan))

  }, []);


  const handlePlanSelection = (event) => {
    console.log(event);
    console.log(event.target.value);
    setSelectedPlan(event.target.value);
  };

  // here we will call the add booking api

  // const handleDateChange = (date) => {

  //   setSelectedDate(date)
  //   if (date) {
  //     const formattedDate = date.format('YYYY-MM-DD');
  //     const formattedTime = date.format('HH:mm:ss');
  //     setSelectedDate(formattedDate);
  //     setSelectedTime(formattedTime);
  //   } else {
  //     console.log('Date:', null);
  //     console.log('Time:', null);
  //   }
  // }

  // console.log(charityId, 111);


  const addBooking = async () => {

    const payload = {
      user_id: user_id,
      packageId: selectedPlan,
      puja_id: props.pujaDetails.id,
      date: convertDateToNumberFormat(props?.pujaDetails?.duration),
      pujaPlans: pujaPlans,
      pujaDetails: props?.pujaDetails,
    }

    if (!selectedPlan) {
      errorMessage("Package is required")
      return
    }


    if (!user_id) {
      navigate("/login", {
        state: {
          move: `/AllPujas/PujaDetails/${props?.pujaDetails?.id}`,
          payloadSecure: payload
        }
      })
      return
    } else if (user_profile === "Not Completed") {
      errorMessage(`profile must completed to book pooja`)
      navigate("/profileDetails", { state: { path: location.pathname, payloadSecure: payload } })
      setReturnedFromProfile(true)
      return
    } else {
      setLoader(true)

      // console.log(convertDateToNumberFormat(props?.pujaDetails?.duration));
      const formData = new FormData()
      formData.append("user_id", user_id)
      formData.append("package_id", selectedPlan ?? getSelectedPlan)
      formData.append("puja_id", props.pujaDetails.id)
      formData.append("date", convertDateToNumberFormat(props?.pujaDetails?.duration))


      // charityId?.forEach(charity => {
      //   formData.append("charity_id[]", charity)
      // })

      // console.log('details', formData)

      let booking_ids = 0

      await axios.post('https://devkirpa.com/admin/api/book-now', formData)
        .then((res) => {
          console.log(res.data)
          booking_ids += res?.data?.cartid

          // navigate(`/order-confirmation`, { state: { booking_id: booking_ids, path: location.pathname } })
        }).catch((error) => {
          console.error('Error adding booking:', error);
          errorMessage("An error occurred while booking. Please check if you have not select the plan and try again later.")

        })

      await axios.post("https://devkirpa.com/admin/api/booking", formData)
        .then((res) => {
          // booking_ids += res?.data?.booking_id
        })

      const navigationPayload = {
        packageId: selectedPlan,
        pujaPlans: pujaPlans,
        pujaDetails: props?.pujaDetails,
        booking_id: booking_ids
      }

      navigate("/Checkout", { state: { payload: navigationPayload } })



      setLoader(false)
    }
  }

  // const DhanList = async () => {
  //   try {
  //     setLoader(true)
  //     const response = await axios.get("https://devkirpa.com/admin/api/charities")
  //     const data = await response?.data
  //     // setCharityList(data?.list);
  //     setLoader(false)
  //   } catch (error) {
  //     setLoader(false)
  //     errorMessage(error?.response?.data?.message)
  //   }
  // }

  const addToCart = async () => {
    if (!selectedPlan) {
      errorMessage("Package is required")
      return
    }


    // if (!selectedDate && !selectedTime) {
    //   errorMessage("Date and time is required")
    //   return
    // }
    if (!user_id) {
      navigate("/login")
    }
    const formData = new FormData()
    formData.append("user_id", user_id)
    formData.append("package_id", selectedPlan)
    formData.append("puja_id", props.pujaDetails.id)
    formData.append("date", convertDateToNumberFormat(props?.pujaDetails?.duration))
    // formData.append("time", convertTimeTo24HourFormat(props?.pujaDetails?.time))




    // charityId?.forEach((charity) => {
    //   return formData.append("charity_id[]", charity);
    // })
    // fD.append("puja_id")

    try {
      const response = await axios.post("https://devkirpa.com/admin/api/add-to-cart", formData)
      const data = await response?.data
      successMessage(data?.message)
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    }
  }





  // const removeCharity = (id) => {
  //   // setFinalAmount(finalAmount - dhanList.find(item => item.id === id)?.price);
  //   setCharityId(prevIds => prevIds.filter(itemId => itemId !== id));
  //   // setAddedCharities(prev => prev.filter(itemId => itemId !== id));
  // };

  // console.log(selectedDate?.split("T")[0]);



  // useEffect(() => {
  //   DhanList()
  //   // getUserProfile()

  // }, [])

  console.log(location, "Location");

  useEffect(() => {
    if (user?.all_details_fillup === "1" && returnedFromProfile) {
      addBooking()
    }

  }, [user?.all_details_fillup])

  console.log(selectedPlan, "plan");
  return (
    <section className="font-poppins">
      {
        !pujaPlans?.length && <div className="w-full text-red-500 font-semibold flex justify-center items-center py-5 rounded-lg shadow-lg ">
          <h1 className="animate-pulse"> No Pooja Plans Found</h1>
        </div>
      }
      {
        pujaPlans?.length > 0 && <>
          {
            loader ? <Loader /> : <div>
              <div className="shadow border-1 p-3" style={{ borderRadius: "15px" }} data-aos="zoom-in">
                <h2 className="font-semibold text-xl">{lang === "hi" ? pujaJson?.plan?.en : pujaJson?.plan?.hi}</h2>

                {
                  pujaPlans.map((items, index) => {
                    console.log(selectedPlan, items?.id);
                    return (
                      <div
                        key={items.id}
                        className={`border-1 rounded-lg p-3 mt-3 flex gap-2 max-lg:flex max-lg:justify-between max-lg:items-start ${selectedPlan == items?.id ? "border-orange-500 bg-orange-200" : "border-[#757575]"}`}

                      // data-aos="fade-down"
                      >
                        <div>
                          <div className="flex justify-between">
                            <h3 className="font-semibold capitalize">  {lang === "hi" ? items?.name?.en : items?.name?.hi}</h3>
                          </div>
                          <div className=" pt-2">
                            <p className="text-sm font-normal">{lang === "hi" ? items?.description?.en : items?.description?.hi}</p>
                          </div>
                          <div className="pt-2">
                            <h3 className="text-sm font-semibold">
                              {lang === "hi" ? pujaJson?.puja_slugs?.Total?.en : pujaJson?.puja_slugs?.Total?.hi}{" "}
                              <span className="font-semibold text-xl text-orange-600 ml-2">
                                ₹{items.price}
                              </span>
                            </h3>
                            <p className="text-xs font-normal">
                              {
                                lang === 'hi' ? "(Including all taxes and booking fees)" : "(सभी करों और बुकिंग शुल्क सहित)"
                              }

                            </p>
                          </div>
                          <div className="pt-2 flex justify-between">
                            {/* <div className="flex items-center gap-1">
                          <img src={DateImg} alt="" />
                          <h3 className="text-sm font-semibold">{lang === "hi" ? pujaJson?.puja_date?.en : pujaJson?.puja_date?.hi}</h3>
                        </div> */}
                            {/* <div>
                          <p className="text-sm font-semibold">{items.date}</p>
                        </div> */}
                          </div>
                        </div>
                        <div className="flex justify-start flex-col">
                          <input type="radio" onChange={handlePlanSelection} value={items.id ?? getSelectedPlan} name='package_id' className="checked:bg-orange-500" />

                        </div>
                      </div>
                    );
                  })
                }





                {/* <div className="flex w-full gap-5">
              <input type="text" name="date" id="date" onChange={(e) => setDate(e.target.value)} value={props?.pujaDetails?.duration} className="w-full border-1 border-black p-2 rounded-lg mt-3" readOnly />
              <input type="text" name="time" id="time" onChange={(e) => setTime(e.target.value)} value={props?.pujaDetails?.time} className="w-full border-1 border-black p-2 rounded-lg mt-3" readOnly />
            </div> */}


                <div className="flex justify-evenly items-center gap-2 max-sm:flex-col max-sm:w-full">
                  <div className="bg-orange-600 text-light max-lg:w-60 font-semibold rounded-lg p-3 mt-3 flex gap-2 cursor-pointer max-sm:w-full" data-aos="zoom-in" onClick={() => {
                    addBooking()
                    // navigate("/payment", { state: { selectedPlan: selectedPlan, poojaPlan: pujaPlans } })
                  }} >
                    <button className="m-auto " >{lang === "hi" ? pujaJson?.buttons?.button_one?.en : pujaJson?.buttons?.button_one?.hi}</button>
                  </div>
                  {/* <div className="bg-orange-600 max-sm:w-full max-lg:w-60 text-light font-semibold rounded-lg p-3 mt-3 max-sm:mt-1 flex gap-2 cursor-pointer w-32" data-aos="zoom-in" onClick={addToCart}>
                    <button className="m-auto ">{lang === "hi" ? pujaJson?.buttons?.button_two?.en : pujaJson?.buttons?.button_two?.hi}</button>
                  </div> */}
                </div>
              </div>
            </div>
          }</>
      }
    </section>
  );
}

export default PujaPlans;