import React from 'react'

const Input = ({labelName, type, name, id, onChange, title, value, placeholder}) => {
  return (
    <div className='flex flex-col gap-2'>
        <label htmlFor={labelName}>{title}</label>
        <input type={type} name={name} id={id} onChange={onChange} value={value} placeholder={placeholder}  className="no-spinner border-[#910033] focus:outline-[#910033] border-2 rounded-lg p-1 max-md:w-[75%] max-sm:w-[100%]"/>

    </div>
  )
}

export default Input