import React from "react";
import Loader from "../../Images/Dev Kirpa Loader.gif";
import pujaJson from "../Puja.json"


function PujaDescription(props) {
  const lang = JSON.parse(localStorage.getItem("lang"))
  const pujaDetails = props.pujaDetails;
  console.log('ss', pujaDetails)
  if (pujaDetails) {
    return (
      <section className="font-poppins">
        <div key={pujaDetails?.id} className="w-[90%] m-auto text-sm">
          <div>
            <h2 className="font-semibold text-xl mb-2">{lang === "hi" ? pujaJson?.puja_slugs?.heading_one?.en : pujaJson?.puja_slugs?.heading_one?.hi}</h2>
            <div className="border-b-2 pb-3 flex flex-col gap-3 font-normal text-justify" data-aos="fade-down">
              {lang === "hi" ? pujaDetails?.description?.en : pujaDetails?.description?.hi}
            </div>
          </div>

          <div>
            <h2 className="font-semibold text-xl mb-2 mt-4">
              {lang === "hi" ? pujaJson?.puja_slugs?.heading_two?.en : pujaJson?.puja_slugs?.heading_two?.hi}
            </h2>
            <ul className="w-full list-disc border-b-2 pb-3" >
              {pujaDetails.specifications.map((items, index) => (
                <>
                  <p key={index} data-aos="fade-down">
                    <p className="font-normal text-justify">{lang === "hi" ? items.benefits : items.benefits_hi}</p>
                  </p>
                </>
              ))}
            </ul>
          </div>
         
          <div>
            <h2 className="font-semibold text-xl mb-2 mt-4">
              {lang === "hi" ? pujaJson?.puja_slugs?.heading_three?.en : pujaJson?.puja_slugs?.heading_three?.hi}
            </h2>
            <ul className="w-full list-disc border-b-2 pb-3">
              {pujaDetails.specifications.map((items, index) => (
                <>
                  <p key={index} data-aos="fade-down">
                    <p className="font-normal">{lang === "hi" ? items.schedule : items.schedule_hi}</p>
                  </p>
                </>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className="loader">
        <div>
          <img src={Loader} alt="" />
        </div>
      </div>
    );
  }
}

export default PujaDescription;
